@import "Assets/styles/Global/_variables.scss";

.app-configuration-modal {
  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    color: $vg-black;
  }
  h3 {
    color: $vg-gray-1;
    margin-bottom: 0.5rem;
    font-weight: $vg-font-weight-regular;
  }
  .description {
    flex: 1;
    > p {
      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }
  .app-folder-box {
    @include full-content-width;
    @include flex($direction: row, $align: center, $justify: space-between);
    border: $border-gray-5;
    border-radius: 2px;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    min-height: 2rem;
    .name-container {
      @include flex($direction: row, $align: center);
      color: $vg-gray-3;
      .vg-icon {
        margin-right: 0.5rem;
      }
    }
    &.vg-dropdown {
      .content {
        top: 1.2rem;
        margin-left: -0.5rem;
      }
    }
  }

  .box-input-wrapper {
    .vg-input {
      .input-wrapper {
        input {
          border: $border-gray-5;
          box-sizing: border-box;
          border-radius: 2px;
          &::placeholder {
            opacity: 0.3;
          }
        }
      }
    }
  }
  .row {
    display: grid;
    grid-auto-rows: auto;
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    @include full-content-width;
  }
  .grid-2-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .key-mapping-options {
    @include full-content-width;
    @include flex($direction: column, $align: center, $justify: center);
    height: calc(100% - 2rem);
    .row {
      margin-bottom: 2rem;

      @include for-not-mobile {
        padding: 0 1rem;
        grid-column-gap: 3rem;
      }

      box-sizing: border-box;
    }
    .vg-radio-input .check {
      top: 5px;
    }
  }

  .key-mapping-option {
    border: $border-gray-6;
    border-radius: 6px;
    padding: 2rem 1rem 1rem;
    opacity: 0.8;

    &.selected {
      border-color: rgba($vg-aqua-dark, 0.4);
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
    h5 {
      color: $vg-black;
      font-weight: $vg-font-weight-regular;
    }
    p {
      color: $vg-gray-2;
      font-size: 0.7rem;
    }
  }

  .machine-type-cards-container {
    @include full-content-width;
    box-sizing: border-box;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    height: 14rem;
  }
  .pricing-info {
    color: $vg-gray-2;
    font-size: 0.65rem;
    width: 7rem;
    margin: 0.5rem 0.5rem 0;
    text-align: center;
    span {
      font-weight: $vg-font-weight-bold;
    }
  }
  .pricing-annotation {
    color: $vg-gray-3;
    font-size: 0.65rem;
    text-align: center;
    white-space: break-spaces;
    margin: 1rem 4rem 0;
    @include for-mobile {
      margin: 2rem auto;
    }
  }
  .completion-step {
    @include flex($direction: column, $align: center, $justify: center);
    height: 100%;
    text-align: center;
    gap: 1rem;

    img {
      height: 8rem;
    }

    p {
      &:last-of-type {
        color: $vg-gray-3;
      }
    }

    .optimization-info-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 0;
      width: 65%;
      border: solid 1px $vg-aqua-main;
      border-radius: 5px;
      margin-top: auto;
      padding: 1rem;

      .vg-icon {
        height: 1.2rem;
        margin-right: 0.3rem;
      }

      h1 {
        color: $vg-aqua-main;
        font-weight: $vg-font-weight-bold;
        font-size: 0.7rem;
        text-align: left;
        margin-bottom: 0.2rem;
      }

      p {
        text-align: left;
        color: $vg-gray-1;
        font-size: 0.7rem;
        line-height: 1.6;
      }
    }
  }

  .image-upload-container {
    height: 8rem;
    max-height: 8rem;
  }

  .vg-switch {
    gap: 1rem;
    flex-direction: row-reverse;

    &.checked {
      .switch {
        background-color: $vg-baby-powder;
      }
    }
  }
}
