@import "Assets/styles/Global/_variables.scss";

.wizard-container {
  @include flex($direction: row, $justify: space-between, $align: center);
  width: 100%;
  margin-top: 2rem;
  .step-container {
    box-sizing: border-box;
    text-align: center;
    width: 4rem;
    height: 8rem;
    margin: 0 1rem;
    @media (max-width: 1300px) {
      margin: 0 0.5rem;
    }
    @include flex($direction: column, $justify: space-between, $align: center);
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;

    .step-count {
      background-color: $vg-gray-6;
      height: 2.5rem;
      line-height: 2.5rem;
      width: 2.5rem;
      border-radius: 100px;
      text-align: center;
      margin-bottom: 1rem;
      color: $vg-white;
      @include flex($direction: column, $justify: center, $align: center);
      svg {
        * {
          stroke-width: 1;
        }
      }
    }
    color: $vg-gray-3;
    font-size: $vg-font-size-small;
    &.inactive {
      .step-count {
        background-color: $vg-baby-powder;
      }

      color: $vg-black;
    }
    &.done,
    &.active {
      .step-count {
        background-color: $vg-aqua-light;
      }
    }
    &.active {
      font-size: $vg-font-size-medium;
      color: $vg-black;
    }
    h6 {
      font-size: $vg-font-size-tiny;
      color: $vg-gray-4;
    }
  }
  .secondary-text-container {
    height: 3.5rem;
  }
  .progress {
    border: 3px solid;
    height: 3px;
    border-radius: 100px;
    &.background {
      border-color: $vg-gray-6;
      background-color: $vg-gray-6;
      width: 100%;
      max-width: 6rem;
      @media (max-width: 1300px) {
        max-width: 5rem;
      }
    }
    &.done {
      width: 100%;
    }
    &.half {
      width: 50%;
    }
    &.half,
    &.done {
      border-color: $vg-aqua-light;
      background-color: $vg-aqua-light;
      margin-top: -3px;
      margin-left: -3px;
    }
  }
}
