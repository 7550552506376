@import "Assets/styles/Global/_variables";

.vg-file-drag-drop {
  @include flex;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: $vg-font-size-tiny;
  color: $vg-blue-baby;
  background-color: rgba($vg-blue-baby, 0.02);
  border: 2px dashed $medium-blue-baby;
  border-radius: 10px;
  cursor: pointer;

  .vg-icon {
    opacity: 0.7;
    height: 3rem;
    width: auto;
    margin-bottom: 0.7rem;
  }

  .file-upload-input-label {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  p {
    color: $vg-gray-4;
    padding: 0rem 5rem;
    text-align: center;
    @include for-mobile {
      padding: 0rem 2rem;
    }
  }

  input[type="file"] {
    display: none;
    height: 100%;
    width: auto;
    opacity: 0;
    -moz-opacity: 0;
  }

  .file-drag-drop-desc {
    @include flex($direction: row, $justify: center);
    white-space: break-spaces;
    flex-wrap: wrap;
    text-align: center;
    .file-upload-input-text {
      color: $vg-purple-main;
    }
  }

  &.aqua-main {
    background-color: $vg-white;
    border: 2px dashed $vg-aqua-main;
    &.file-dragged {
      background-color: rgba(71, 194, 181, 0.05);
    }
    .file-upload-input-text {
      color: $vg-aqua-main;
    }

    &.small {
      background: rgba(71, 194, 181, 0.02);
    }
  }

  &.gray-border {
    border: 1px solid $vg-gray-4;
  }

  &.small {
    p {
      text-align: center;
      font-size: 0.7rem;
      width: 60%;
    }
    .vg-icon {
      margin: 0;
    }
  }

  &.file-dragged {
    background-color: rgba($vg-blue-baby, 0.05);
  }

  &.error {
    background-color: rgba(194, 24, 24, 0.02);
    border-color: $vg-silk-red;
  }

  .top-right-content {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
