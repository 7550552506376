@import "Assets/styles/Global/_variables.scss";

.application-versions-modal {
  height: 25rem;

  .vg-table-container {
    max-height: 15rem;
    width: 100%;
    margin-top: 1rem;
    @include for-mobile {
      width: 17rem;
      overflow-x: auto;
    }
    .vg-table th {
      font-size: 0.75rem;
    }
    .vg-table td {
      font-size: 0.7rem;
    }
    .vg-text-button {
      font-size: 0.7rem;
    }
  }

  .vg-button {
    align-self: flex-end;
    width: 9rem;
    margin-top: 1rem;
    .vg-icon {
      height: 0.8rem;
    }
  }

  .vg-loader {
    width: 100%;
  }

  .action-buttons {
    @include flex($direction: row, $align: center, $justify: flex-start);
    gap: 0.8rem;
  }

  .status {
    @include flex($direction: row, $align: baseline, $justify: flex-start);
    border-radius: 100px;

    > div {
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.3rem;
      border-radius: 100px;
      background-color: #6fcf97;
    }
  }
}

.confirm-version-change-modal {
  .vg-button {
    background-color: $vg-light-navy;
    font-weight: $vg-font-weight-regular;
  }
  .vg-text-button {
    color: $vg-light-navy;
  }

  .description {
    align-self: flex-start !important;
  }
}

.application-versions-error-modal {
  height: 25rem;
  box-sizing: border-box;

  .modal-content {
    align-items: center !important;
    justify-content: center !important;
  }

  h1 {
    font-size: $vg-font-size-large;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .stream-error-message-container {
    margin-top: 1rem;
    white-space: pre-wrap;
  }
}
