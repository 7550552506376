@import "Assets/styles/Global/_variables";

.teams-landing-container {
  @include flex($direction: column, $justify: center, $align: center);
  height: 100%;
  position: relative;

  .landing-content {
    @include flex($direction: column, $justify: center, $align: center);
    background: $vg-white;
    width: 100%;
    height: 90%;
    border-radius: 5px;

    img {
      max-width: 16rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: $vg-font-size-small;
      color: $vg-gray-3;
      width: 45%;
      text-align: center;
      margin-bottom: 2rem;
      line-height: 1.8;

      @include for-mobile {
        width: 90%;
      }
    }

    .team-name-input {
      width: 16rem;
      .input-line .vg-input {
        border-bottom-color: $vg-gray-4;
      }
    }

    .vg-button {
      width: 16rem;
    }
  }

  .team-invite-container {
    @include flex($direction: column, $align: center, $justify: space-between);
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    padding: 2rem;

    img {
      width: 40%;
    }

    .invite-info-container {
      @include flex($direction: column, $align: center, $justify: space-between);
      gap: 1rem;
      margin: 1rem 0 2rem;
      text-align: center;
    }

    .button-container {
      display: flex;
      gap: 1rem;
      .vg-button {
        padding: 0.7rem;
      }
    }

    p {
      color: $vg-gray-3;
      opacity: 0.8;
    }

    p:nth-of-type(2) {
      font-size: $vg-font-size-small;
    }
  }
}
