@import "Assets/styles/Global/_variables.scss";

.vr-device-detected-modal {
  max-width: 20rem;

  .modal-content {
    display: flex;
    align-items: center !important;
    gap: 1rem;

    h1 {
      font-size: 1rem;
      font-weight: 500;
    }

    p {
      font-size: 0.75rem;
      color: $vg-gray-3;
      text-align: center;
    }

    img {
      height: 9rem;
      margin-bottom: 1.5rem;
    }
  }
}
