@import "Assets/styles/Global/_variables.scss";

.organization-empty-content-container {
  @include flex($direction: column, $align: center, $justify: center);
  gap: 2rem;
  text-align: center;
  padding-top: 1rem;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: $vg-white;
  border-radius: 6px;

  img {
    height: 8rem;
    margin-bottom: 1rem;
  }
  h1 {
    color: $vg-gray-2;
    font-weight: $vg-font-weight-semi-bold;
    font-size: $vg-font-size-medium;
  }
  .empty-content-description {
    color: $vg-gray-1;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    max-width: 32rem;
    font-size: 0.85rem;
    line-height: 2.2;
    
  }
}
