@import "Assets/styles/Global/_variables.scss";

.app-cards-container {
  @include flex($direction: row, $align: stretch, $justify: flex-start);
  gap: 1.5rem;
  max-width: 100%;
  padding-bottom: 0.5rem;
  overflow-x: auto;

  .content-box {
    @include flex($align: flex-start, $justify: center);
    @include full-content-height;
  }

  .other-cases-container {
    @include flex($direction: column, $align: center, $justify: space-between);
    background-color: $vg-white;
    border-radius: 6px;
    padding: 3rem 2rem 1rem;
    box-sizing: border-box;
    min-width: 13rem;
    img {
      height: 3.5rem;
    }
    p {
      font-size: $vg-font-size-tiny;
      color: $vg-gray-2;
      text-align: center;
      white-space: break-spaces;
      margin-bottom: 1rem;
    }
    .link-to-blog {
      @include flex($direction: row, $align: center, $justify: space-around);
      gap: 1rem;
      font-size: $vg-font-size-tiny;
      a {
        color: $vg-aqua-main;
      }
      .vg-icon {
        * {
          fill: $vg-aqua-main !important;
        }
      }
    }
  }
}
