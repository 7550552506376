@import "Assets/styles/Global/_variables.scss";

.payment-info-line {
  @include flex($direction: row, $justify: space-between);
  @include full-content-width;

  top: -3rem;
  position: absolute;
  padding: 0.6rem 0;

  white-space: nowrap;
  .info-description {
    @include flex($direction: row);
    color: $vg-gray-2;
    font-size: 0.8rem;
    font-weight: $vg-font-weight-light;
    line-height: 1.4rem;

    .vg-icon {
      height: 1rem;
      margin: 0.3rem 0.2rem 0;
      width: 1rem;
    }
  }
  .credit {
    margin-left: 0.2rem;
    font-size: 0.8rem;
    color: $vg-gray-3;
  }
}
