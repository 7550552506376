@import "Assets/styles/Global/_variables.scss";

.region-change-modal {
  @include flex($direction: column, $justify: center, $align: center);
  z-index: 1;
  position: absolute;
  height: 35vh;
  bottom: 3rem;

  @include for-mobile {
    width: 100%;
    height: 70vh;
    min-height: 500px;
  }

  .vg-button {
    margin-top: -1rem;
  }

  .vg-icon.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .modal-content {
    @include flex($direction: row, $justify: center, $align: flex-start);
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 3rem 5%;
    border: 1px solid $vg-gray-6;
    width: 80vw;
    background-color: $vg-white;

    @include for-mobile {
      @include flex($direction: column, $justify: center, $align: flex-start);
      width: 100%;
    }

    h3 {
      color: $vg-gray-2;
      font-weight: $vg-font-weight-regular;
      font-size: $vg-font-size-small;
    }

    .divider {
      background-color: $vg-gray-4;
      height: inherit;
      width: 1px;
      min-width: 1px;
      margin: 0 3rem;
      @include for-mobile {
        height: 1px;
        min-height: 1px;
        margin: 2rem 0;
        width: 90%;
      }
    }

    .region-section {
      width: 58%;
      color: $vg-gray-3;
      font-size: $vg-font-size-small;
      font-weight: $vg-font-weight-light;

      @include for-mobile {
        width: 100%;
      }

      .region-change {
        @include flex($direction: row, $justify: flex-start, $align: center);
        text-transform: capitalize;
        margin: 2rem 0;
        color: $vg-black;
        font-weight: $vg-font-weight-regular;
        font-size: $vg-font-size-larger;
      }

      .vg-icon {
        width: 5rem;
        height: 2rem;
        margin: 0 3rem;
      }
    }

    .connection-section {
      width: 40%;
      font-size: $vg-font-size-huge;
      font-weight: $vg-font-weight-regular;

      @include for-mobile {
        width: 100%;
      }

      h3 {
        margin-bottom: 1rem;
      }

      p {
        margin-top: 0.5rem;
        color: $vg-gray-3;
        font-size: $vg-font-size-small;
        font-weight: $vg-font-weight-regular;
      }
    }
  }
}
