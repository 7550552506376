@import "Assets/styles/Global/_variables.scss";

.capacity-selection-container {
  width: 100%;
  height: 100%;

  > h1 {
    font-size: $vg-font-size-medium;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    color: $vg-black;
  }
  h3 {
    color: $vg-gray-2;
    font-weight: $vg-font-weight-semi-bold;
  }
  > p {
    font-size: $vg-font-size-tiny;
    color: $vg-gray-3;
  }

  .capacity-option-box {
    @include flex($direction: column, $align: center, $justify: space-around);
    font-size: 0.7rem;
    text-align: center;
    box-sizing: border-box;
    border: $border-gray-6;
    border-radius: 3px;
    padding: 0.6rem 0;
    white-space: pre-wrap;
    height: 10rem;
    width: 10rem;
    @include for-mobile {
      min-width: 10rem;
    }

    p {
      line-height: 1rem;
      font-weight: $vg-font-weight-medium;
      &.small-font {
        font-size: 0.55rem;
        line-height: 0.8rem;
      }
    }
    &.selected {
      color: $vg-aqua-main;
      border-color: $vg-aqua-light;
    }
    img {
      max-height: 50%;
    }
  }

  .customize-step-container {
    @include flex($direction: column, $align: center, $justify: center);
    width: 100%;
    height: calc(100% - 2rem);
    min-height: 17rem;
    box-sizing: border-box;
    gap: 1rem;
    text-align: center;

    p {
      color: $vg-gray-3;
    }

    .capacity-options {
      @include flex($direction: row, $align: center, $justify: center);
      gap: 0.6rem;
      @include for-mobile {
        overflow-x: auto;
        width: 17rem;
        justify-content: unset;
      }
    }

    .capacity-input {
      height: 0;
      transition: height 1s ease;
      overflow: hidden;
      &.open {
        height: 4rem;
      }
    }

    .input-box {
      animation: fade-in 1s;
      @include flex($direction: row, $align: center, $justify: space-evenly);
      margin: 0 auto 0.5rem;
      width: 14rem;
      border: $border-aqua-main;
      border-radius: 6px;
      padding: 0.7rem 1rem;
      color: $vg-gray-3;
      font-size: 0.7rem;
      .vg-input {
        width: 3rem;
      }
      .vg-input textarea,
      .vg-input input {
        border-bottom: $border-gray-4;
        text-align: center;
        min-height: 1.5rem;
        margin-bottom: 0;
      }
      .capacity-label {
        white-space: nowrap;
        text-align: left;
        line-height: 1.5;
      }
    }
    > span {
      font-size: 0.65rem;
      span {
        font-weight: $vg-font-weight-bold;
      }
    }
    .vg-text-button {
      text-decoration: underline;
    }

    .capacity-input-line {
      @include flex($direction: row, $align: center, $justify: space-between);
      animation: fade-in 5s;
      gap: 1rem;
      color: $vg-gray-3;
      margin-bottom: 0.5rem;
      &.header-line {
        color: $vg-gray-1;
      }
      .vg-input {
        max-width: 3.5rem;
        input {
          margin-bottom: 0.2rem;
          font-size: 0.8rem;
          line-height: 0.8rem;
          text-align: center;
          min-height: 1.2rem;
        }
      }
      .region-name-container {
        text-align: left;
      }
    }

    .capacity-type-footer {
      align-self: center;
      p {
        font-size: 0.7rem;
        color: $vg-gray-3;
        position: absolute;
        width: 70%;
        left: 0;
        padding: 0 15%;
      }
    }
  }

  .capacity-warning-container {
    @include flex($direction: column, $align: center, $justify: center);
    padding: 0 1rem;
    width: 100%;
    height: calc(100% - 2rem);
    min-height: 17rem;
    box-sizing: border-box;
    text-align: center;
    gap: 0.5rem;

    h2 {
      color: $vg-gray-2;
      font-weight: $vg-font-weight-semi-bold;
      font-size: 0.9rem;
    }

    p {
      color: $vg-gray-3;
      font-size: 0.8rem;
      width: 95%;
      line-height: 1.8;
    }

    .vg-checkbox {
      color: $vg-gray-3;
      opacity: 0.8;
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }

    .warning-animation-container {
      @include flex($direction: column, $align: center, $justify: space-around);
      box-sizing: border-box;
      white-space: pre-wrap;
      width: 10rem;
      padding: 0.6rem 0;
      margin-bottom: 0.5rem;
    }
  }
}
