@import "Assets/styles/Global/_variables";

.app-streaming-landing-container {
  @include flex($direction: column, $justify: center, $align: center);
  height: 100%;
  position: relative;

  .landing-content {
    @include flex($direction: column, $justify: center, $align: center);
    background: $vg-white;
    width: 100%;
    height: 90%;
    border-radius: 5px;

    img {
      max-height: 16rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: $vg-font-size-small;
      color: $vg-gray-3;
      width: 40%;
      text-align: center;
      margin-bottom: 2rem;
      line-height: 1.8;

      @include for-mobile {
        width: 90%;
      }
    }

    .team-name-input {
      width: 16rem;
      .input-line .vg-input {
        border-bottom-color: $vg-gray-4;
      }
    }

    .vg-button {
      width: 16rem;
    }
  }
}
