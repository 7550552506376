@import "Assets/styles/Global/_variables";

.organization-file-move-modal {
  .file-move-modal-content {
    width: 100%;
    height: 15rem;

    .file-manager-table {
      height: 100%;
    }
  }

  .breadcrumb .directories {
    @include flex($direction: row, $align: flex-start, $justify: flex-start);

    .vg-icon {
      height: 1rem;
      width: 1rem;
      margin: 0 0.25rem 0.1rem;
    }

    .breadcrumb-button {
      span {
        max-width: 8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
