@import "Assets/styles/Global/_variables";

.app-download-announcement {
  box-shadow: -1px 3px 7px 0px rgba(0, 0, 0, 0.13);
  margin: 2.5rem 0 0 0;
  padding: 1rem 1.5rem;
  @include for-mobile {
    display: none;
  }
  img {
    margin-right: 1rem;
  }
  .announcement-button-container {
    margin-right: -1rem;
  }
  .announcement-content {
    p {
      font-size: 0.7rem;
      line-height: 1rem;
    }
  }
}
