@import "Assets/styles/Global/_variables.scss";

.analytic-information-modal {
  .analytic-information-modal-content {
    padding-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.4rem;

    .analytic {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      img {
        height: 2.6rem;
        width: 2.6rem;
      }
    }
  }
}
