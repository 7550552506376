@import "Assets/styles/Global/_variables";

.vg-dashboard-sub-screen-layout {
  @include flex($align: stretch, $justify: flex-start);
  min-height: 100%;
  height: fit-content;
  &.no-scroll {
    height: calc(100vh - 4.5rem);
  }

  box-sizing: border-box;
  padding: 2.5rem 10% 2rem 8%;

  min-width: 300px;

  @include for-mobile {
    padding: 0 1.5rem 2rem;
    height: fit-content;
    min-width: unset;
  }

  @include for-small-computer {
    min-width: 900px;
  }

  .dashboard-sub-screen-header {
    @include flex($direction: row, $justify: space-between, $align: flex-end);

    .header-left {
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      flex: 0.8;

      @include for-mobile {
        flex: 1;
      }

      .title {
        color: $vg-black;
        font-size: 1.5rem;
        font-weight: $vg-font-weight-medium;
        line-height: 2.5rem;
        margin-bottom: 0.25rem;
      }

      .description {
        color: $vg-gray-3;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-regular;
        line-height: 1rem;
      }
    }

    .header-right {
      @include flex($direction: column, $justify: flex-start, $align: flex-end);
      flex: 0.2;
      font-size: 1.5rem;
      white-space: nowrap;

      @include for-mobile {
        display: none;
      }

      .balance-title {
        color: $vg-black;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-regular;
        line-height: 1rem;
        margin-bottom: 0.25rem;
      }

      .balance-amount {
        color: $vg-black;

        text-align: end;
        font-weight: $vg-font-weight-medium;
        line-height: 1.5rem;
      }
    }

    .close-icon {
      height: 2rem;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      width: 2rem;
      @include for-mobile {
        display: none;

        &.display-close-icon {
          display: flex;
        }
      }
    }
  }

  .dashboard-sub-screen-divider {
    @include full-content-width();
    :not(.appStreaming) {
      border: $border-gray-5;
    }

    height: 1px;
    background-color: $vg-gray-5;
    margin: 1rem 0 2rem;

    @include for-mobile {
      margin: 1rem 0;
    }
  }

  .dashboard-sub-screen-action {
    width: 15rem;
    height: 2rem;
    margin-top: auto;
    margin-right: -8%;
    align-self: flex-end;
    position: relative;
    @include for-mobile {
      align-self: center;
      display: flex;
      justify-content: center;
      height: 3.5rem;
      margin: 0;
      width: 100%;
      bottom: 0;
      left: 0;
      position: fixed;
      .action-content {
        width: 20rem;
        align-self: center;
      }
    }
    .action-content {
      height: 2rem;
      width: inherit;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .action-info {
      white-space: nowrap;
      top: -1.5rem;
      left: 0.1rem;
      position: absolute;
      @include for-mobile {
        position: unset;
      }
    }
  }

  .sub-screen-loader {
    position: absolute;
    height: initial;
    top: 50%;
    left: calc(50% + #{$width-navbar-desktop}/ 2);
    @include for-mobile {
      left: calc(50% - max(15vw, 50px) / 2);
    }
  }
}
