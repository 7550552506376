@import "Assets/styles/Global/_variables.scss";

.purpose-list {
  @include flex($direction: row, $justify: space-around, $align: flex-start);
  margin: 2rem 5% 0;
  max-width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;

  &.active .purpose:not(.selected):not(:hover) {
    opacity: 0.7;
  }
}
