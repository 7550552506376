@import "Assets/styles/Global/_variables.scss";

$header-padding: 4%;

.action-header {
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  padding: 1.5rem $header-padding;
  box-sizing: border-box;
  position: relative;
  width: 99vw;

  @include for-mobile {
    height: 6rem;
    flex-shrink: 0;
  }

  .header-left {
    @include flex($direction: row, $justify: flex-start, $align: flex-start);

    .vg-icon {
      min-width: 50px;
      width: 3rem;
      height: 3rem;
      margin-right: 3rem;
      @include for-vertical-mobile {
        margin-right: 1rem;
      }
      @include for-mobile {
        margin-top: 1rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .info {
      .title {
        position: relative;
        font-size: 1.6rem;
        margin-right: 1rem;

        width: fit-content;

        &.beta::after {
          content: "Beta";
          color: $vg-white;
          background-color: $vg-aqua-main;
          font-size: 0.6rem;
          padding: 0.2rem 0.6rem 0.1rem;
          border-radius: 2px;
          position: absolute;
          top: 0.6rem;
          right: -3rem;
        }
      }
      .description {
        color: $vg-gray-3;
      }
    }

    @include for-mobile {
      font-size: 0.9rem;
      .info {
        .title {
          font-size: 1.1rem;
          &::after {
            top: 0.2rem;
            font-size: 0.5rem;
          }
        }
        .description {
          font-size: 0.8rem;
        }
      }
    }
  }

  i {
    outline: none;
    color: $vg-gray-3;
    font-size: 2rem;
    cursor: pointer;

    &:focus {
      color: $vg-gray-2;
    }
  }

  .divider {
    width: calc(100% - #{2 * $header-padding});
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    height: 1px;
    background-color: $vg-gray-5;
  }
}
