@import "Assets/styles/Global/_variables";

.hinter-container {
  overflow: hidden;
  width: 30rem;
  height: 10rem;
  right: 0;
  top: 0;
  position: absolute;

  .hinter {
    @include flex($direction: row);
    height: 4rem;
    right: 1rem;
    top: 2rem;
    padding: 0 1rem;
    box-sizing: border-box;
    position: absolute;
    background: $vg-white;
    box-shadow: $medium-shadow;
    border-radius: 10px;
    transition: right ease-in-out 0.5s;
    cursor: pointer;

    &.closed:hover {
      right: -17rem;
      transition: right ease-in-out 0.3s;
      @include for-mobile {
        right: -14rem;
      }
    }

    &.closed {
      right: -18rem;
      @include for-mobile {
        right: -15rem;
      }
    }

    &.hidden {
      right: -22rem;
    }

    p {
      padding-right: 1rem;
    }

    &.rating {
      p {
        margin-left: 1rem;
        color: $vg-gray-1;
        font-size: 1rem;
        font-weight: 300;
      }
    }

    &.referral {
      p {
        width: 13rem;
        font-size: 0.8rem;
        color: $vg-gray-3;
        @include for-mobile {
          width: 10rem;
        }
      }

      img {
        padding-right: 2rem;
        height: 2rem;
      }

      .vg-icon {
        height: 1.8rem;
      }
    }
  }
}

.rating-container {
  @include flex($direction: row);
  margin-right: 0.5rem;
  direction: rtl;

  .vg-icon {
    height: 1.7rem;
  }

  & > .vg-icon:hover,
  .vg-icon:hover ~ .vg-icon {
    * {
      fill: $vg-gray-4;
    }
  }

  & > .vg-icon:active,
  .vg-icon:active ~ .vg-icon,
  .vg-icon.selected,
  .vg-icon.selected ~ .vg-icon {
    * {
      fill: $vg-purple-main;
    }
  }
}
