@import "Assets/styles/Global/_variables.scss";

.deposit-rule-modal {
  max-width: 32rem;
  .deposit-rule-modal-content {
    width: 100%;

    .label {
      margin: 1rem 0 0.2rem 0;
      font-size: $vg-font-size-small;
    }

    .vg-button {
      margin-top: 2rem;
    }

    .vg-dropdown {
      font-size: $vg-font-size-small;
      border: 1px solid $vg-gray-5;
      border-radius: 2px;
      span {
        font-size: 0.8rem;
      }
    }

    .check-box-text {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      span {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.6;
      }

      input[type="checkbox"] {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .75rem;
        accent-color: $vg-purple-main;
      }
    }
  }

  .delete-deposit-rule-box {
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    border: 1px solid $vg-gray-5;
    border-radius: $vg-border-radius-small;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $vg-aqua-main;

    > div {
      h1 {
        color: $vg-aqua-main;
        font-weight: 700;
        font-size: 0.7rem;
      }

      p {
        color: #333;
        font-size: 0.7rem;
        padding-right: 1rem;
        line-height: 1.6;
      }
    }
  }
}
