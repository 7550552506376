@import "Assets/styles/Global/_variables.scss";

.modal-overlay,
.confirmation-modal-overlay {
  @include full-viewport-size();
  @include for-mobile {
    height: 100vh;
  }
  background-color: rgba(170, 170, 170, 0.2);
  backdrop-filter: blur(1px);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;

  .modal-container {
    border-radius: 4px;
  }
}

.invisible-overlay {
  left: $width-navbar-desktop;
  backdrop-filter: blur(2px);
  @include for-mobile {
    left: 0;
    top: 0;
  }
}

.modal-overlay {
  @include flex($direction: row, $justify: center, $align: center);

  .modal-container {
    background-color: $vg-white;
    border-radius: 4px;
    padding: 2rem 2rem;
    box-sizing: border-box;
    position: relative;
    width: 36rem;
    @include for-mobile {
      @include full-content-width;
    }
    &.small {
      width: 25rem;
      &:not(.boarding-modal):not(.centered) {
        left: -9rem;
        @include for-mobile {
          left: 0;
          transform: none;
        }
      }
      .modal-content .modal-action-wrapper {
        width: 100%;
      }
    }

    &.big {
      width: 45rem;
    }

    &.with-sidebar {
      width: 55rem;
      border-radius: 4px;
    }

    &.fit-content {
      height: fit-content;
      min-height: auto;
    }

    &.full-size {
      padding: 0;
    }

    .top-right-icon {
      height: 1.25rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.25rem;
      cursor: pointer;
    }

    .content-container {
      @include full-content-size();
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      &.with-sidebar {
        flex-direction: row;
        .vertical-divider {
          @include full-content-height;
          width: 2px;
          background-color: $vg-gray-6;
          margin-right: 2rem;
        }
      }
    }

    .modal-content {
      @include full-content-size();
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      position: relative;

      &.centered-content {
        align-items: center;
        .modal-action-wrapper {
          align-self: center;
          align-items: center;
        }
      }

      .header {
        font-size: 1.1rem;
        font-weight: $vg-font-weight-semi-bold;
        line-height: 1.25rem;
        margin-bottom: 0.5rem;
        color: $vg-black;
        &.gray-1 {
          color: $vg-gray-1;
          font-weight: 400;
        }
        &.purple-main {
          color: $vg-purple-main;
        }
      }

      .modal-success-content {
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
        color: $vg-gray-2;
        .vg-animation {
          margin: auto;
        }
      }

      .description {
        color: $vg-gray-1;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-light;
        line-height: 1.4rem;
        margin-bottom: 1rem;
      }

      .modal-action-wrapper {
        @include flex($direction: column, $justify: flex-end, $align: flex-start);
        width: $big-button-width;
        align-self: flex-end;
        margin-top: auto;
        height: 4rem;
        &.errorable {
          height: 5rem;
        }
      }
    }

    .modal-pending-loader {
      height: 0;
      width: calc(100% + 6rem);
      position: relative;
      top: -2rem;
      left: -3rem;
    }

    @include for-mobile {
      margin-left: 0;
    }
  }
}
.modal-sidebar-container {
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  height: 100%;
  max-width: 10rem;
  box-sizing: border-box;
  padding-right: 1rem;

  .header {
    margin-bottom: 1rem;
    color: $vg-purple-main;
    font-weight: $vg-font-weight-semi-bold;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .sidebar-footer {
    width: 100%;
    margin-top: auto;
  }
}

.modal-sidebar {
  @include flex($direction: row, $align: stretch, $justify: flex-start);
  gap: 1rem;
  width: 10rem;

  .bar {
    width: 2px;
    background-color: $vg-purple-main;
  }

  .steps {
    .modal-step {
      font-size: $vg-font-size-tiny;
      line-height: 2.2;
      text-transform: capitalize;
      color: $vg-gray-3;
      opacity: 0.5;

      &.previous {
        color: $vg-purple-main;
        opacity: 0.5;
      }

      &.current {
        color: $vg-purple-main;
        opacity: 1;
      }
    }
  }
}

.confirmation-modal-overlay {
  @include flex($direction: column, $justify: flex-start, $align: center);

  .modal-container {
    background-color: $vg-white;
    box-shadow: $light-shadow;
    cursor: default;
    margin-top: 30vh;
    padding: 2rem;
    position: relative;
    width: 34rem;
    @include for-mobile {
      width: 90%;
      padding: 1.5rem 4rem;
    }

    .top-right-icon {
      height: 1.25rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.25rem;
      cursor: pointer;
      @include for-mobile {
        right: 4rem;
      }
    }

    .page-loader-container {
      top: -2rem;
      left: -2rem;
      width: calc(100% + 4rem);
    }

    .modal-content {
      @include full-content-size();
      @include flex($direction: column, $justify: flex-start, $align: flex-start);

      .header {
        color: $vg-black;
        font-size: 1rem;
        font-weight: $vg-font-weight-medium;
        line-height: 1.25rem;
        margin-bottom: 1rem;
      }

      .description {
        color: $vg-gray-3;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-regular;
        line-height: 1.15rem;
        align-self: center;
        width: 100%;
        white-space: break-spaces;
        .vg-animation {
          margin: -1rem auto 0;
        }
        .success {
          @include flex($direction: column, $justify: flex-start, $align: center);
        }

        .error {
          @include flex($direction: row, $justify: flex-start, $align: center);
          height: 2rem;
          .vg-icon {
            height: 1rem;
            width: 1rem;
            margin-right: 0.2rem;
          }
          p {
            line-height: 1.2rem;
            height: 1rem;
          }
        }
      }

      .action-group {
        @include flex($direction: row, $justify: space-between, $align: flex-end);
        @include full-content-width();
        margin-top: 2rem;
        @include for-mobile {
          @include flex($direction: column, $align: center);
        }
        .secondary-action {
          font-size: 0.8rem;
          font-weight: $vg-font-weight-medium;
          @include for-mobile {
            margin-bottom: 1rem;
          }
        }

        .confirm-action-group {
          font-weight: $vg-font-weight-medium;
          @include flex($direction: row, $justify: flex-end, $align: flex-end);

          .confirm-action {
            font-size: 0.8rem;
            margin-left: 1.875rem;
            color: $vg-blue-baby;
          }
        }

        @include for-mobile {
          flex-direction: row;
          justify-content: space-between;
          .secondary-action {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.modal-overlay.mobile {
  .modal-container {
    width: 100%;
    .modal-content .modal-action-wrapper {
      width: 100%;
    }
  }
}
