@import "Assets/styles/Global/_variables";

.workstation-container {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

#streaming-canvas {
  @include full-viewport-size;

  overflow: hidden;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  display: flex;
  z-index: 2;
}

.video-container-centered {
  background-color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#player {
  @include full-viewport-size;
  overflow: hidden;
  display: none;
  min-height: 100vh;
  min-width: 100vw;
  object-fit: fill;
  overscroll-behavior-y: none;
  touch-action: none;
  background-color: black;
}
.resize-active {
  border: 1px solid $vg-aqua-main;
  pointer-events: none;
}

.video-container-centered #player {
  min-height: initial;
  min-width: initial;
}

.video-container-centered.aspect-ratio-greater {
  #player {
    height: 100vh;
    width: calc(100vh * (var(--x) / var(--y)));
  }
}

.video-container-centered.aspect-ratio-less {
  #player {
    width: 100vw !important;
    height: calc(100vw * (var(--y) / var(--x)));
  }
}

#vr-player {
  @include full-viewport-size;
  overflow: hidden;
  min-height: 100vh;
  min-width: 100vw;
  display: none;
  object-fit: fill;
  overscroll-behavior-y: none;
  touch-action: none;
  background-color: black;
}

.intercom-hidden {
  display: none;
}

.workstation-overlay {
  @include full-viewport-size;
  @include flex;

  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  display: flex;
  z-index: 1;
  background: $connecting-screen-background-color;
  color: $vg-white;
  text-align: center;

  &.higher-z-index {
    z-index: 3;
    top: 0;
    left: 0;
  }

  &.white {
    background: $vg-white;
    color: $vg-gray-3;
    .loading-message,
    .loading-message a {
      color: $vg-gray-3;
      font-weight: 400;
    }
  }
  &.animated-bg {
    background-image: url("../../../Assets/images/appStreaming/white-bg.svg");
    background-size: 100% 100%;
  }
  &.custom {
    .loading-container {
      @include flex;
      height: auto;
      @include for-mobile {
        width: 100%;
        > img,
        > div,
        > video {
          width: 100%;
        }
      }
      img {
        margin-bottom: 3rem;
      }

      video {
        height: 60%;
      }
    }
  }

  .loader-container.centered {
    height: 80vh;
  }
  .loading-message {
    font-weight: $vg-font-weight-light;
    color: $vg-gray-4;
    margin-bottom: 2rem;
  }

  .description {
    position: absolute;
    bottom: 4rem;
  }

  .session-expired-notification {
    @include flex($direction: column, $align: center);
    gap: 1.5rem;
    margin-top: -2rem;
  }
  .logo {
    width: 5rem;
    position: absolute;
    bottom: 2rem;
    margin: 0 auto;
  }
}

.modal-file-upload-status {
  .modal-content {
    width: 40vw !important;
    padding: 2rem 0 !important;
    line-height: 1.8;

    .header {
      i {
        margin-right: 0;
      }
    }

    .description {
      p {
        margin: 0 !important;
        padding: 0.8rem 0;
        font-weight: 400;
        font-size: 0.9rem;
      }
    }
  }

  .divider {
    @include full-content-width;
    height: 1px;
    background-color: $blue-grey;
    margin: 1rem 0;
  }

  li {
    @include flex($direction: row, $justify: flex-start);
    padding: 1rem;
    width: 90%;
  }

  .fa-times-circle {
    &:hover {
      cursor: pointer;
      color: $dark;
    }
  }

  i {
    margin-right: 1rem;
  }

  .action {
    margin-top: 1rem !important;
  }

  .action-clear-uploaded {
    margin-left: auto;
    color: $blue-grey;
    text-decoration: underline;
    cursor: pointer;
    float: right;
  }
}

img.workstation-error-image {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

.workstation-error-buttons-container {
  @include full-content-width;
  @include flex($justify: center);
  gap: 0.3rem;
  .vg-button {
    width: 100%;
  }
}

.on-stream-buttons {
  position: absolute;
  left: 1rem;
  bottom: 2.5rem;
  z-index: 1;
  @include flex;

  .vg-icon {
    width: 1.8rem;
    height: 2rem;
    padding-right: 0.6rem;
  }

  .on-stream-button {
    display: flex;
    align-items: center;
    color: $vg-white;
    font-weight: 900;
    border-radius: 5px;
    background: rgba(141, 142, 143, 0.6);
    padding: 0.2rem 0.8rem;
    &:hover {
      background: rgba(245, 82, 42, 0.8);
    }
  }
}

.test-session-badge {
  @include flex($direction: row, $justify: center, $align: center);
  position: fixed;
  top: 1rem;
  left: 1rem;
  padding: 0.3rem 0.5rem;
  gap: 0.4rem;
  opacity: 0.9;

  height: 1rem;
  border-radius: 1rem;
  background-color: #333333;
  z-index: 99;

  .green-dot {
    border-radius: 100%;
    width: 0.4rem;
    height: 0.4rem;
    background-color: $vg-green-3;
  }

  p {
    font-size: $vg-font-size-tiny;
    font-weight: $vg-font-weight-medium;
    color: $white;
  }
}
