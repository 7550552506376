@import "Assets/styles/Global/_variables.scss";

.customize-step {
  width: 100%;

  &.disabled {
    pointer-events: none;
    animation: none;
    opacity: 0.5;
  }

  .preview-container {
    @include flex($direction: row, $justify: center, $align: center);
    width: 26rem;
    height: 14rem;
    margin: auto;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 1rem;
    @include for-mobile {
      width: auto;
    }

    .loading-modal {
      width: 60%;
      height: 60%;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: $vg-border-radius-small;
      @include flex($direction: column, $justify: center, $align: center);

      &.dark {
        background: rgba(0, 0, 0, 0.3);
      }

      img {
        margin-bottom: 0.6rem;
        height: 2rem;
        object-fit: contain;
        max-width: 9rem;
      }

      .divider {
        margin-bottom: 2px;
        height: 4px;
        background-color: $vg-gray-3;
        width: 70%;
        opacity: 0.5;
        border-radius: 10px;

        &:nth-child(2) {
          width: 50%;
        }
      }
    }
  }
  .logo-upload-container {
    margin-top: 1rem;
    height: 10rem;
    width: 50%;
    background-size: cover;
    border-radius: 10px;

    .logo-source-container {
      @include flex($direction: column, $justify: center, $align: center);
      height: 100%;
      gap: 0.4rem;
      font-size: 0.7rem;

      img {
        height: 70%;
      }
    }
  }

  .banner-upload-container {
    margin-top: 1rem;
    height: 20rem;
    width: 50%;
    border-radius: 10px;
    background-size: cover;
  }

  .bottom-content {
    @include flex($direction: row, $justify: center, $align: flex-start);
    color: $vg-gray-4;
    gap: 0.4rem;
    margin-bottom: 0.4rem;
    font-size: 0.7rem;

    color: $vg-gray-4;
    padding: 0;
    white-space: nowrap;

    .close-icon {
      width: 1rem;
      height: 1rem;
    }
  }

  .app-streaming-dock-content {
    @include flex($direction: row, $align: center, $justify: space-around);
    @include full-content-height;
    width: auto;
    gap: 1rem;
    padding: 0 1rem;

    .app-streaming-dock-menu-button {
      @include flex;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 3px;
      position: relative;

      &.active {
        color: $vg-purple-main;
      }

      &.larger {
        @include flex($direction: row, $justify: space-around, $align: center);
        width: 8rem;
        padding: 0 0.5rem;
        box-sizing: border-box;
        overflow-wrap: break-word;
        font-size: 0.7rem;
        line-height: 1rem;
        text-align: left;
        gap: 1rem;
      }

      .vg-icon {
        flex-grow: 0;
        flex-shrink: 0;
        width: 1.5rem;
        height: 1.5rem;
      }

      .hoverable-menu {
        position: absolute;
        bottom: 3rem;
        visibility: hidden;
        opacity: 0;
        transition: visibility 1s, opacity 1s linear;
        padding-bottom: 0.5rem;
      }

      .hoverable-item {
        @include flex($direction: row, $align: center, $justify: flex-start);
        width: 8rem;
        line-height: 1rem;
        background-color: $vg-white;
        padding: 0.4rem 0.5rem;
        box-sizing: border-box;
        margin-bottom: 0.5rem;
        border-radius: 3px;
        z-index: 99;
        &:hover {
          transform: scale(1.02);
        }
        .vg-icon {
          height: 0.7rem;
          width: 0.7rem;
          margin-left: 0.5rem;
          * {
            stroke-width: 0.8;
          }
        }
      }
    }
  }

  .color-selection-container {
    @include flex($direction: column, $justify: center, $align: flex-start);
    position: relative;
    width: 50%;
    font-size: $vg-font-size-small;

    p {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .color-placeholder {
      @include flex($direction: row, $justify: center, $align: center);
      gap: 1rem;
      width: 100%;
      .vg-input input {
        border-bottom: $border-gray-5;
        min-height: 1.5rem;
        margin-bottom: 10px;
      }
    }

    .color-picker {
      animation: fade-in 1s;
      align-self: flex-start;
      bottom: 150px;
      position: absolute !important;
      left: calc(100% + 1rem);
      transform: translate(0, 80%);
      > div:nth-child(1) {
        display: none;
      }
    }

    .color-preview {
      min-width: 25px;
      min-height: 25px;
      margin-bottom: 4px;
      border-radius: 3px;
      border: $border-gray-5;
      display: block;
    }
  }

  .image-name {
    @include flex($direction: row, $justify: flex-start, $align: center);
  }
}
