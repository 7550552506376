@import "Assets/styles/Global/_variables.scss";

.quick-start-container {
  display: grid;
  max-height: 100%;
  grid-template-rows: 0.1fr 1.3fr 0.1fr;
  height: 100%;
  gap: 1rem;

  .vg-search-input-container {
    width: 20%;
    margin-left: auto;
    margin-bottom: 2rem;
    @include for-mobile {
      width: 40%;
      margin-right: 1rem;
    }
  }

  .quick-start-table {
    transition: 0.1s;
    position: relative;
    cursor: pointer;
    border-bottom: $vg-gray-5 1px solid;
    padding: 2rem 0;

    &.selected {
      border-bottom: rgb(115 212 199 / 30%) 1px solid;

      h1 {
        color: $vg-aqua-light;
      }
    }

    &.active-opacity {
      opacity: 0.3;

      &:hover:not(.disabled) {
        opacity: 0.8;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }

    .header-line {
      display: flex;
      justify-content: space-between;
      h1 {
        font-size: 0.9rem;
        font-weight: $vg-font-weight-bold;
        padding-bottom: 1rem;
      }
      .trial-badge {
        letter-spacing: 0.4px;
        font-size: 0.6rem;
        font-weight: $vg-font-weight-medium;
        text-align: center;
        border: 0.75px solid $vg-gray-3;
        color: $vg-gray-3;
        height: max-content;
        padding: 0.2rem 0.4rem;
        border-radius: 3px;

        &.selected {
          border: 0.75px solid $vg-aqua-light;
          color: $vg-aqua-dark;
        }
      }
    }

    .table-header {
      display: grid;
      margin-bottom: 0.4rem;
      grid-template-columns: 1.7fr 1.2fr 1.2fr 2.3fr 1.2fr;
      .header-cell {
        font-size: 0.7rem;
        font-weight: $vg-font-weight-light;
      }
    }

    .table-body {
      display: grid;
      grid-template-columns: 1.7fr 1.2fr 1.2fr 2.3fr 1.2fr;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-semi-bold;

      .performance-list {
        width: 85%;
        line-height: 2;

        @include for-mobile {
          width: 8rem;
        }
      }

      .unit {
        font-weight: 300;
      }
    }

    .divider {
      height: 1px;
      background-color: $vg-gray-5;
      display: none;
    }
  }

  .button-container {
    @include for-mobile {
      display: flex;
      justify-content: center;
      position: fixed;
      width: 100%;
      bottom: 0;
      margin-bottom: 1rem;
    }

    .vg-button {
      max-height: 2rem;
      margin-left: auto;
      @include for-mobile {
        margin-left: unset;
      }
    }
  }
}
