@import "Assets/styles/Global/_variables.scss";

.organization-admin-billing-container {
  display: block;
  padding: 2rem 2rem 0;
  @include for-mobile {
    padding: 2rem 0;
  }
  background-color: white;
  animation: fade-in 0.5s;
  animation-fill-mode: forwards;
  .row {
    h4 {
      color: $vg-gray-3;
    }
    p {
      color: $vg-gray-4;
    }
  }

  .react-datepicker {
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
  }

  .row {
    display: grid;
    grid-auto-rows: auto;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    @include for-mobile {
      grid-row-gap: 1rem;
    }
    width: 100%;
    margin-bottom: 2rem;
    h3 {
      color: $vg-gray-1;
      margin-bottom: 1rem;
      &.active {
        color: $vg-purple-main;
      }
    }
    h4 {
      font-size: $vg-font-size-medium;
    }
    p {
      font-size: $vg-font-size-tiny;
    }
    .single-item {
      @include flex($direction: column, $align: stretch, $justify: flex-start);
    }
    .tab-buttons-container {
      @include flex($direction: row, $justify: flex-start, $align: center);
      .vg-text-button {
        margin-right: 1rem;
        font-size: $vg-font-size-small;
      }
    }
    .item-box {
      display: flex;
      flex-direction: column;
      border: $border-gray-5;
      border-radius: 2px;
      height: 100%;
      padding: 1rem;
      @include for-mobile {
        overflow-x: auto;
      }
      p {
        margin-bottom: 0.5rem;
        line-height: 1.8rem;
      }
      &.fixed-height {
        height: 12rem;
        position: relative;
        @include for-big-computer {
          height: 16rem;
        }
      }
      .vg-expandable-table {
        overflow-y: auto;
        .table-body {
          overflow-y: auto;

          .table-row {
            line-height: 1.4;
          }
        }
        .expandable-box.open {
          padding: 0 0 0.5rem 0;
        }
        .expandable-content {
          width: 100%;
          margin-left: 0;
        }
        .expandable-content > div {
          width: 100%;
          margin-left: 0;
        }
        .vg-table {
          td {
            line-height: 1.4;
          }
          th {
            line-height: 1.4;
            font-size: 0.8rem;
            font-weight: $vg-font-weight-medium;
          }
        }
      }
      .item-header {
        @include flex($direction: row, $align: center, $justify: space-between);
        margin-bottom: 1.5rem;
        border-bottom: $border-gray-5;
        padding-bottom: 0.5rem;
        > div {
          @include flex($direction: row, $align: center, $justify: space-between);
          color: $vg-gray-3;
          span {
            color: $vg-aqua-main;
            margin-right: 0.5rem;
          }
        }
        .vertical-divider {
          height: 1.5rem;
          width: 1px;
          margin-right: 1rem;
          display: block;
          background-color: $vg-gray-5;
        }
      }
      &.children-centered {
        @include flex($direction: column, $align: center, $justify: center);
      }
      &.report-box {
        span {
          font-size: $vg-font-size-medium;
          span {
            font-size: $vg-font-size-tiny;
            color: $vg-gray-4;
          }
        }
        p {
          font-size: $vg-font-size-small;
          margin-bottom: 0.4rem;
        }
        img {
          height: 5.5rem;
          margin-bottom: 0.5rem;
        }
      }
      .paragraph-tooltip-container {
        @include flex($direction: row, $align: center);
        .vg-tooltip:not(.reference-position) {
          bottom: calc(100% + 0.4rem);
          right: 0;
          transform: translateX(50%);
        }
      }

      .payment-failed-information {
        span {
          color: $vg-gray-3;
          font-weight: $vg-font-weight-semi-bold;
        }
      }
    }
    &.header-line {
      margin-bottom: 1rem;
      height: 2rem;
      @include for-mobile {
        display: contents;
      }
      .organization-single-item {
        @include flex($direction: row, $justify: space-between, $align: center);
        @include for-mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .react-datepicker-wrapper {
            margin-left: unset;
            margin: 0.5rem 0 1rem 0;
          }
        }
        min-height: 2rem;
      }
    }
  }
  .no-transactions-content {
    @include flex($direction: column, $align: center, $justify: center);
    @include full-content-height;
    opacity: 0;
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    gap: 1rem;
    img {
      height: 6rem;
    }
    span {
      color: $vg-gray-3;
      font-weight: $vg-font-weight-medium;
      font-size: $vg-font-size-tiny;
    }
  }
  .grid-2-columns {
    @include for-not-mobile {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .invisible {
    visibility: hidden;
  }

  .additional-row {
    .vg-table-container {
      overflow-y: initial;
    }

    .vg-tooltip-hoverable {
      display: flex;
      align-items: center;
    }
  }

  .network-tab {
    display: flex;
    height: 16rem;
    gap: 1rem;
    margin-bottom: 1rem;

    @include for-mobile {
      flex-direction: column-reverse;
      height: auto;
      .seats-container {
        .table-body {
          max-height: 10rem;
        }
      }
    }

    .network-usage-container {
      border: 1px solid $vg-gray-5;
      border-radius: 2px;
      height: 100%;
      flex: 6;
    }

    .table-body {
      overflow-y: auto;
    }

    .seats-container {
      width: 100%;
      border: 1px solid $vg-gray-5;
      border-radius: 2px;
      flex: 4;
    }

    .opacity-50 {
      opacity: 0.5;
    }
    .table-row {
      height: 2.5rem;
    }
  }
}
