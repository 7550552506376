@import "Assets/styles/Global/_variables";

$bar-transition-time: 0.6s;
$app-streaming-dock-height: 4rem;

.vg-app-streaming-dock-container {
  @include flex($direction: column, $align: center, $justify: flex-start);

  &.top {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.left {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  position: fixed;
  width: 5rem;
  z-index: 2;
  overscroll-behavior-y: none;
  color: $vg-gray-2;
  font-weight: $vg-font-weight-regular;

  .vg-app-streaming-dock-wrapper {
    @include full-content-width;
    transition: 1s ease-in-out;

    &.top {
      transform: translateY(-8rem);
      margin-top: 1rem;
    }

    &.bottom {
      transform: translateY(8rem);
      margin-bottom: 1rem;
    }

    &.left {
      transform: translateX(-8rem);
    }

    height: $app-streaming-dock-height;

    &.open {
      transform: translateX(0);
    }
  }

  .app-streaming-dock {
    @include flex($direction: column, $align: center, $justify: center);
    @include full-content-width;
    z-index: 9;
    height: $app-streaming-dock-height;
    color: $vg-black;
    box-sizing: border-box;

    .app-streaming-dock-content {
      @include flex($direction: row, $align: center, $justify: space-around);
      @include full-content-height;
      width: auto;
      gap: 1rem;
      padding: 0 1rem;
      background: rgba(234, 234, 234, 0.4);
      border-radius: 8px;
      border: 0.5px solid $vg-gray-5;

      &.left {
        @include flex($direction: column, $align: center, $justify: space-around);
        height: auto;
        padding: 0.5rem;
      }

      .app-streaming-dock-menu-button {
        @include flex;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 3px;
        position: relative;
        background: rgba(248, 248, 248, 0.6);

        &.active {
          color: $vg-purple-main;
        }

        &.larger {
          @include flex($direction: row, $justify: space-around, $align: center);
          width: 8rem;
          padding: 0 0.5rem;
          box-sizing: border-box;
          overflow-wrap: break-word;
          font-size: 0.7rem;
          line-height: 1rem;
          text-align: left;
          gap: 1rem;
        }

        .vg-icon {
          flex-grow: 0;
          flex-shrink: 0;
          width: 1.5rem;
          height: 1.5rem;
        }

        .hoverable-menu {
          position: absolute;
          bottom: 3rem;
          visibility: hidden;
          opacity: 0;
          transition: visibility 1s, opacity 1s linear;
          padding-bottom: 0.5rem;
        }

        .hoverable-item {
          @include flex($direction: row, $align: center, $justify: flex-start);
          width: 8rem;
          line-height: 1rem;
          background-color: $vg-white;
          padding: 0.4rem 0.5rem;
          box-sizing: border-box;
          margin-bottom: 0.5rem;
          border-radius: 3px;
          z-index: 99;

          .vg-icon {
            height: 0.7rem;
            width: 0.7rem;
            margin-left: 0.5rem;
            * {
              stroke-width: 0.8;
            }
          }
        }

        &:hover {
          &:not(.hoverable-item):not(.hoverable-menu) {
            background: rgba($vg-white, 0.8);
          }

          .hoverable-menu {
            visibility: visible;
            opacity: 1;
            transition: opacity 1s linear;
          }
        }
      }
    }
  }
}
