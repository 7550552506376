@import "Assets/styles/Global/_variables";

.usage-credit-modal-content {
  @include flex($direction: column, $justify: center, $align: center);
  width: 100%;

  .usage-content {
    margin-top: 3rem;
    .usage-content-input {
      @include flex($direction: row, $justify: center, $align: center);
      gap: 1rem;
      .vg-input {
        max-width: 3rem;
        text-align: center;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  .or-section {
    width: 100%;
    text-align: center;
    font-size: $vg-font-size-tiny;
    color: $vg-gray-3;
  }

  .animation-content {
    min-height: 0;
    padding: 0.2rem;

    .machine-types {
      @include flex($direction: row, $justify: center, $align: center);
      gap: 0.4rem;
      margin-top: 1rem;
      width: 100%;
      flex-wrap: wrap;

      .machine-type {
        border: 1px solid $vg-gray-5;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        font-size: 0.7rem;
        min-width: 5rem;

        &.selected {
          border: 1.2px solid $vg-aqua-main;
        }
      }
    }

    .storage-price-info {
      box-shadow: $medium-shadow;
      border-radius: 3px;

      margin-top: 3rem;
      padding: 1rem;
      text-align: center;
      font-size: 0.75rem;
      color: $vg-gray-2;

      .insufficient-balance {
        @include flex($direction: row, $justify: center, $align: center);
        gap: 2rem;
        .vg-text-button {
          margin-left: auto;
        }
      }
    }
  }

  .buttons-container {
    @include flex($direction: row, $justify: space-between, $align: center);
    margin-top: 3rem;
    width: 100%;

    &.single-button {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}
