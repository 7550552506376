@import "Assets/styles/Global/_variables.scss";

.organizations-stats-container {
  .filter-row {
    display: grid;
    position: relative;
    width: 100%;
    @include for-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 5rem;
    }

    .dropdown-container {
      @include for-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
    }

    .react-datepicker-wrapper {
      display: inline-block;
      right: 0;
      position: absolute;
      @include for-mobile {
        position: unset;
      }
    }

    .vg-dropdown {
      width: 20%;
      margin-right: 1rem;
      font-size: 0.75rem;

      @include for-mobile {
        width: 30%;
        font-size: 0.7rem !important;
      }

      .placeholder {
        font-size: 0.75rem;
        @include for-mobile {
          font-size: 0.7rem;
          padding-right: unset;
        }
      }

      .content {
        @include for-mobile {
          width: auto;
          font-size: 0.7rem;
          .dropdown-item {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .content-background {
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;
    @include full-content-width;
    box-sizing: border-box;
    padding: 1rem;
    @include for-mobile {
      padding: 1rem 0;
    }
    &.users-graph-container {
      padding: 1rem 2rem;
      @include for-mobile {
        padding: 1rem 1rem;
      }
    }
    &.white {
      background-color: $vg-white;
      border-radius: 6px;
    }
    h3 {
      margin-bottom: 1rem;
      color: $vg-gray-1;
    }
    .table-input-line {
      @include flex($direction: row, $align: center, $justify: space-between);
      width: 100%;
    }

    .pagination-buttons-box {
      height: 2rem;
    }
    .user-search-box-container {
      width: 15rem;
      .box-input-wrapper .vg-input .input-wrapper input {
        padding: 0 0.6rem;
      }
      .box-input-wrapper .vg-icon {
        height: calc(2rem + 2px);
        padding: 0.4rem;
        border: $border-gray-6;
        border-left: none;
        margin-left: -0.2rem;
        background-color: transparent;
      }
      .vg-icon {
        * {
          stroke: $vg-baby-powder;
        }
      }
    }
  }

  .users-graph-container {
    @include flex($direction: row, $align: center, $justify: space-between);
    margin: 2rem 0;
    @include for-mobile {
      margin: 1rem 0;
      flex-direction: column;
    }
    .info-container {
      @include flex($direction: column, $align: flex-start, $justify: space-between);
      @include for-mobile {
        flex-direction: row;
      }
      height: 100%;
      width: 100%;
      @include for-not-mobile {
        flex-basis: 25%;
        max-width: 12rem;
        border-right: $border-gray-5;
      }
      .icon-background {
        height: 2rem;
        width: 2rem;
        @include flex($direction: row, $justify: center, $align: center);
        flex-grow: 0;
        flex-shrink: 0;

        &.aqua-light {
          background-color: $vg-aqua-light-transparent;
        }
        &.baby-powder-background {
          background-color: $vg-baby-powder-transparent;
        }
        &.rounded {
          border-radius: 100%;
        }
      }
      .info-line {
        @include flex($direction: row, $align: center, $justify: flex-start);
        width: 100%;
        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
        .vg-icon {
          flex-grow: 0;
          flex-shrink: 0;

          &.fine-icon {
            * {
              stroke-width: 0.2;
            }
          }
        }
        .text-container {
          margin-left: 1rem;
        }
        p {
          font-size: $vg-font-size-tiny;
          color: $vg-gray-3;
        }
        span {
          &.bold {
            font-size: $vg-font-size-large;
            color: $vg-gray-1;
          }
          &.light {
            font-size: 0.6rem;
            color: $vg-gray-4;
          }
        }
      }
    }
    .graph-container {
      flex-basis: 80%;
      padding-left: 1rem;
      @include for-mobile {
        @include full-content-width;
        padding-left: 0;
      }
    }
  }

  .organization-sessions-table {
    font-size: $vg-font-size-tiny;
    width: calc(100% + 0.8rem);
    max-height: 20rem;
    @include for-mobile {
      overflow-x: auto;
    }

    .header-line {
      @include for-mobile {
        width: 50rem;
      }
    }

    .table-body {
      height: 100%;
      overflow-y: auto;
      @include for-mobile {
        width: 50rem;
      }
    }

    .status {
      @include flex($direction: row, $align: center, $justify: flex-start);
      > div {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.5rem;
        border-radius: 50%;
        opacity: 0.6;
      }
      span {
        line-height: 0.7rem;
      }
    }
  }

  .tab-buttons-container {
    @include flex($direction: row, $align: center, $justify: flex-start);
    .vg-text-button {
      margin-right: 1rem;
      font-size: $vg-font-size-small;
    }
  }

  .no-user-stats-content {
    @include flex($direction: column, $align: center, $justify: center);
    gap: 2rem;
    height: 100%;
    opacity: 0;
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    margin: 2rem 0;
    img {
      height: 5rem;
    }
    span {
      color: $vg-gray-1;
      font-weight: $vg-font-weight-medium;
      font-size: $vg-font-size-small;
    }
  }

  .content-container-xd {
    @include full-content-height;
    animation: fade-in 0.5s;
    width: 100%;

    .visitor-column {
      display: flex;
      gap: 4px;
      align-items: flex-end;
      .vg-icon {
        height: 1.2rem;
        width: 1.2rem;
      }

      .vg-tooltip-hoverable {
        height: 1.2rem;
      }
    }

    .connection-column {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: auto;

      .vg-icon {
        height: 1.2rem;
        width: 1.2rem;
      }
      .vg-tooltip-hoverable {
        display: flex;
      }
    }
  }
}
