@import "Assets/styles/Global/_variables.scss";

.stream-error-message-container {
  @include flex($direction: column, $align: center, $justify: space-around);
  text-align: center;
  img {
    height: 10rem;
  }
  p {
    margin: 1rem 2rem;
  }
  .vg-button {
    margin-top: 1rem;
  }
}
