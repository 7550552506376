@import "Assets/styles/Global/_variables.scss";

.multi-gpu-consent-modal {
  .description-content {
    @include flex($direction: column, $align: flex-start, $justify: center);
    p:nth-child(2) {
      margin-top: 6px;
    }
  }

  .description {
    width: 100%;
    @include flex($align: flex-start);
    margin-top: -1rem;
  }

  .inline-option {
    margin-top: 1rem;
  }
}
