@import "Assets/styles/Global/_variables";

.file-manager-container.mobile {
  min-width: 0;
  max-height: unset;
  height: fit-content;
  .table-body {
    padding-right: 1rem;
  }

  .file-manager-table {
    height: fit-content;
    .table-wrapper .table-body {
      overflow: visible;
      .selected-table-body-row {
        background-color: transparent;
      }
      .table-body-row {
        &:hover:not(.disabled) {
          background-color: transparent;
        }
        .table-body-column .cell-content {
          height: 2rem;
          .vg-text-button {
            height: 2rem;
          }
        }
      }
    }
  }
  .file-manager-actions-bar {
    margin-bottom: 1rem;
    .file-manager-left-actions {
      width: 100%;
    }
  }

  .breadcrumb {
    margin-bottom: 0;
  }
}
