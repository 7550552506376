@import "Assets/styles/Global/_variables";

.file-move-modal-content {
  width: 100%;
  height: 15rem;

  .file-manager-table {
    height: 100%;
  }
}

.breadcrumb .directories {
  @include flex($direction: row, $align: center, $justify: center);

  .vg-icon {
    height: 1rem;
    width: 1rem;
    margin: 0 0.25rem 0.1rem;
  }
}
