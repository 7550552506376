@import "Assets/styles/Global/_variables.scss";

.password-input-wrapper {
  position: relative;
  .password-strength {
    height: 3rem;
    .password-strength-bar {
      transition-duration: 0.5s;
      transition-timing-function: linear;
      transition-property: width;
      margin-bottom: 0.5rem;
      border-radius: 10px;
      width: 0%;
      height: 5px;
      background-color: $vg-gray-4;

      &.weak {
        width: 25%;
      }
      &.average {
        opacity: 0.5;
        width: 60%;
        background-color: $vg-purple-main;
      }
      &.strong {
        opacity: 1;
        transition-property: opacity, width;
        width: 100%;
        background-color: $vg-purple-main;
      }
    }

    span {
      font-size: 0.8rem;
      color: $vg-gray-3;
    }
  }
}
