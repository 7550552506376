@import "Assets/styles/Global/_variables";

.organization-choose-region-modal {
  .modal-action-wrapper {
    width: 100% !important;
  }
  .description {
    height: 100%;
  }

  .buttons-container {
    @include flex($direction: row, $align: center, $justify: space-between);
    @include full-content-width;
    position: absolute;
    bottom: 0;
    &.single-button {
      justify-content: flex-end;
    }
  }

  .result-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    .texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      width: 60%;

      h1 {
        font-size: $vg-font-size-large;
        color: $vg-aqua-dark;
        font-weight: $vg-font-weight-semi-bold;
      }

      p {
        font-size: $vg-font-size-small;
        color: $vg-gray-1;
        text-align: center;
      }
    }

    .vg-icon {
      margin-top: 1rem;
      width: 6rem;
      height: 6rem;
    }
  }
}
