@import "Assets/styles/Global/_variables.scss";

.powershell-modal {
  .vg-input {
    textarea {
      height: 5rem;
    }

    color: $vg-gray-3;

    &.disabled {
      color: $vg-gray-3;
      background-color: $vg-gray-7;
    }
  }

  .run-script-button {
    margin-left: auto;
    margin-top: 0.5rem;
    width: 11rem;
  }

  .animation-wrapper {
    width: 100%;
  }
}
