@import "Assets/styles/Global/_variables";

.file-upload-container {
  @include flex;
  @include full-content-size;
  overflow: auto;

  .file-list {
    width: 95%;
    max-height: 12.2rem;
  }

  .vg-file-drag-drop {
    height: 10rem;
    margin-top: 1rem;
  }

  p {
    color: $vg-gray-4;
  }
}
