@import "Assets/styles/Global/_variables.scss";

.image-from-computer-container {
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  margin-top: 2rem;
  padding: 0 8rem;
  height: 100%;
  max-height: calc(100vh - 12rem);

  @include for-mobile {
    padding: unset;
    overflow-x: auto;
    height: auto;
    background-color: $vg-white;
    margin: 1rem;
  }

  .vg-expandable-table {
    padding: 1rem;
  }

  .header-line {
    @include for-mobile {
      width: 45rem;
    }
  }
  .table-body {
    overflow-y: auto;
    white-space: nowrap;
    @include for-mobile {
      width: 45rem;
    }
  }

  .table-row {
    height: 2.4rem;
  }
}
