@use "sass:math";
@import "Assets/styles/Global/_variables.scss";

$radio-input-radius: 6px;
$radio-input-fill-radius: 4px;

$check-mark-input-radius: 8px;
$check-mark-input-fill-radius: 4px;

.vg-radio-input {
  @include flex($align: flex-start);
  outline: none;
  position: relative;
  &:not(.disabled) {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  .vg-icon {
    position: absolute;
    left: calc(#{$radio-input-radius} / 2);
    top: calc((#{$radio-input-radius} + #{$radio-input-fill-radius}) / 2);
    height: $radio-input-radius;
    width: $radio-input-radius;
  }

  input[type="radio"] {
    position: absolute;
    width: 100%;
    visibility: hidden;
  }

  label {
    font-size: 0.7rem;
    height: 0.8rem;
    padding-left: 1rem;
    width: auto;
  }

  &:focus {
    .check {
      border-color: $vg-purple-light;
    }
  }

  &.check-mark {
    border-color: $vg-gray-6;
    .check {
      border-color: $vg-gray-6;
      background-color: $vg-gray-7;
      height: 2 * $check-mark-input-radius;
      top: calc((#{$radio-input-fill-radius}) / 2);
      width: 2 * $check-mark-input-radius;
    }
    .vg-icon {
      position: absolute;
      left: calc(#{$check-mark-input-radius} / 2);
      top: calc((#{$check-mark-input-radius} + #{$check-mark-input-fill-radius}) / 2);
      height: $check-mark-input-radius;
      width: $check-mark-input-radius;
    }
    input[type="radio"]:checked ~ .check {
      background-color: $vg-aqua-light;
      border-color: $vg-aqua-light;
    }
    input[type="radio"]:checked ~ .check::before {
      background: $vg-aqua-light;
    }

    label {
      padding-left: 1.4rem;
    }
  }

  .check {
    border: $border-purple-main;
    border-radius: 100px;
    box-sizing: border-box;
    height: 2 * $radio-input-radius;
    left: 0;
    position: absolute;
    width: 2 * $radio-input-radius;
    top: calc((#{$radio-input-fill-radius}) / 2);

    &.square {
      border-radius: 2px;
    }
  }
  &.centered {
    .check {
      top: calc((#{$radio-input-radius} + #{$radio-input-fill-radius}) / 2);
    }
  }

  .check::before {
    background: transparent;
    border-radius: $radio-input-fill-radius;
    content: "";
    display: block;
    height: 2 * $radio-input-fill-radius;
    left: math.div($radio-input-radius - $radio-input-fill-radius, 2);
    top: math.div($radio-input-radius - $radio-input-fill-radius, 2);
    margin: auto;
    position: absolute;
    width: 2 * $radio-input-fill-radius;
  }
  .square {
    &.check::before {
      border-radius: 2px;
    }
  }

  input[type="radio"]:checked ~ .check::before {
    background: $vg-purple-main;
  }

  &.aqua-main {
    .check {
      border: $border-aqua-main;
    }
    input[type="radio"]:checked ~ .check::before {
      background: $vg-aqua-main;
    }
  }
}
