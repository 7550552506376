@import "Assets/styles/Global/_variables.scss";

.software-card-container {
  text-align: center;
  font-size: 0.7rem;

  .software-size {
    margin-top: 0.3rem;
    color: $vg-gray-6;
  }

  .software-card {
    position: relative;
    height: 8rem;
    width: 8rem;
    margin: 0 0.7rem 2rem;

    border: 2px solid $vg-gray-6;
    background-color: $white;
    color: $vg-gray-3;
    border-radius: 5px;
    font-weight: $vg-font-weight-light;
    font-size: 0.9rem;
    outline: none;
    cursor: pointer;

    animation: appear 0.2s 1 linear;

    img,
    .vg-icon {
      height: 4rem;
    }

    @include for-mobile {
      width: 5.5rem;
      height: 5.5rem;
      margin: 0 0 1rem 0.4rem;
      font-size: 0.7rem;
      img,
      .vg-icon {
        height: 3rem;
      }
    }

    .software {
      @include flex($direction: column, $justify: center, $align: center);
      @include full-content-size;
      .software-images {
        @include flex($direction: row, $justify: space-between, $align: center);
        width: 70%;
      }
      p {
        margin-top: 0.2rem;
      }
    }

    .vg-icon.info {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 1.4rem;
      width: 1.4rem;
      @include for-mobile {
        display: none;
      }
    }

    &.selected {
      border-color: $vg-aqua-light;
    }

    &:focus-visible {
      border-color: $vg-blue-baby;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.error,
    &.shake {
      animation: appear 0.2s 1 linear, shake 0.5s;
    }

    &.small {
      height: 3.5rem;
      width: 3.5rem;
      margin: 1rem 0.5rem 0;
      border-radius: 3px;
      font-size: 0.6rem;

      cursor: default;

      img {
        height: 2rem;
        width: auto;
      }
    }

    &.wide {
      width: 8rem;
    }
  }
}

.software-request-modal-content {
  width: 100%;
  margin-top: 1rem;
}

@keyframes appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
