@import "Assets/styles/Global/_variables";

.vg-history-card {
  border: 1px solid $vg-gray-6;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 1rem;
  &.active {
    border: 1px solid $vg-aqua-light;
  }

  .vg-history-card-item {
    @include flex($direction: row, $justify: space-between, $align: center);
    @include for-mobile {
      text-align: end;
    }
    margin-bottom: 0.5rem;
    .vg-history-card-title {
      font-size: 0.9rem;
      color: $vg-black;
    }
    .vg-history-card-description {
      font-size: 0.9rem;
      color: $vg-gray-2;
    }
  }
}
