@import "Assets/styles/Global/_variables.scss";

.configuration-option-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.expanded {
    height: 100%;
  }

  .preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    padding: 0.8rem 0;
    padding-right: 1rem;

    .option-description {
      color: $vg-gray-3;
      font-size: $vg-font-size-small;
      padding-top: 0.4rem;
    }

    .option-value {
      color: $vg-gray-1;
    }

    .action-info {
      @include flex($direction: row, $align: center, $justify: flex-start);
      gap: 1rem;
      p {
        color: $vg-gray-3;
        font-size: $vg-font-size-small;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 0;
    overflow: hidden;
    &.expanded {
      height: 100%;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
  }
}
