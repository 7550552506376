@import "Assets/styles/Global/_variables.scss";

.advanced-step {
  .game-engine-inputs {
    .vg-input {
      width: 100% !important;
      input {
        border-bottom: $border-gray-5;
        color: $vg-gray-3;
        text-align: left !important;
      }
    }
  }
}
