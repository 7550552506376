@import "Assets/styles/Global/_variables";

.assign-template-modal {
  .table-body {
    overflow-y: auto;
    white-space: nowrap;
    max-height: 40vh;
  }

  .vg-search-input-container {
    width: 50%;
    margin-top: .25rem 0 .5rem;
  }

  .checkbox {
    width: 0.85rem;
    height: 0.85rem;
    accent-color: $vg-purple-main;
  }

  .table-row {
    height: 2.4rem;
  }

  .box-input-wrapper {
    margin: 1rem 0;
    .vg-input {
      padding: 0;
      margin: 0;
    }
  }

  .buttons-container {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .vg-loader {
    margin: 0 auto;
  }
}
