@import "Assets/styles/Global/_variables.scss";

.vg-text-button {
  @include flex($direction: row);
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  color: $vg-black;

  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;

  .text-button {
    white-space: nowrap;
  }

  &:not(.disabled) {
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      color: $vg-purple-main;
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &.purple {
    color: $vg-purple-main;
  }

  &.baby-blue {
    color: $vg-blue-baby;
    &:hover,
    &:focus {
      color: $vg-purple-light;
    }
    &:active {
      color: $vg-purple-main;
    }
  }
  &.baby-powder {
    color: $vg-baby-powder;
  }

  &.gray-3 {
    color: $vg-gray-3;
  }

  &.gray-4 {
    color: $vg-gray-4;
  }

  &.aqua-main {
    color: $vg-aqua-main;
    &:hover,
    &:focus,
    &:active {
      color: $vg-aqua-main;
    }
  }
  &.hover-on-white {
    &:hover,
    &:focus,
    &:active {
      color: $vg-white;
    }
  }

  &.hover-on-aqua-main {
    &:hover,
    &:focus,
    &:active {
      color: $vg-aqua-main;
    }
  }
  &.aqua-dark {
    color: $vg-aqua-dark;
    &:hover,
    &:focus,
    &:active {
      color: $vg-aqua-main;
    }
  }

  &.aqua-light {
    color: $vg-aqua-light;
    &:hover,
    &:focus,
    &:active {
      color: $vg-aqua-light;
      opacity: 0.7;
    }
  }

  &.red {
    color: $red;
    &:hover,
    &:focus,
    &:active {
      opacity: 0.7;
    }
  }

  &.icon-position-left {
    .vg-icon {
      margin-right: 0.5rem;
    }
    .text-button {
      order: 2;
    }
  }
  .vg-icon {
    width: 1rem;
  }
}
