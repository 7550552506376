@import "Assets/styles/Global/_variables.scss";

.create-computer-action {
  @include flex($direction: row, $justify: flex-end, $align: flex-end);
  @include full-content-width;
  margin-bottom: $intercom-height;
  padding: 1rem 5% 2rem 5%;
  box-sizing: border-box;
  @include for-mobile {
    @include flex($direction: row, $justify: center, $align: center);
    margin-bottom: 0;
  }
  &.hidden {
    visibility: hidden;
  }
}
