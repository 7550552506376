@import "Assets/styles/Global/_variables.scss";

.new-computer-wrapper {
  top: 5%;
  margin: 0 5%;
  height: 90%;
  position: relative;
  min-height: 500px;
  .center-container {
    @include flex($direction: column, $align: center, $justify: center);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    min-height: 300px;

    @include for-mobile {
      height: 80%;
    }

    .vg-input {
      min-width: 15rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      .input-line {
        align-self: center;
        width: inherit;
      }
    }

    p {
      font-size: $vg-font-size-tiny;
      color: $vg-gray-2;
      text-align: center;
      white-space: pre-wrap;
      line-height: 2;
    }

    .buttons-container {
      @include flex($direction: row);
      white-space: nowrap;
      gap: 1.6rem;
      margin-top: 1.5rem;
      @include for-mobile {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }

  .vg-text-button {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    text-decoration: underline;
    font-size: 0.9rem;
  }

  .team-invite-container {
    @include flex($direction: column, $align: center, $justify: space-between);
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    padding: 2rem;

    img {
      width: 40%;
    }

    .invite-info-container {
      @include flex($direction: column, $align: center, $justify: space-between);
      gap: 1rem;
      margin: 1rem 0 2rem;
      text-align: center;
    }

    .button-container {
      display: flex;
      gap: 1rem;
      .vg-button {
        padding: 0.7rem;
      }
    }

    p {
      color: $vg-gray-2;
      opacity: 0.8;
      line-height: 2;
      font-size: 0.8rem;
    }

    p:nth-of-type(2) {
      font-size: $vg-font-size-small;
    }
  }

  .image-container {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    margin-right: 1rem;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: $vg-gray-6;
      background-position: center center;
    }
  }
  .image-filter {
    @include full-content-size;
    position: absolute;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .logo-container {
    @include flex($direction: column, $align: center);
    flex-shrink: 0;
    width: 6rem;
    height: 6rem;
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 100px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    .vg-icon {
      margin-top: 10%;
      width: 50%;
      height: 50%;
    }
  }
}
