@import "Assets/styles/Global/_variables";

.payment-history-table {
  @include flex($direction: row, $align: flex-start, $justify: space-between);
  height: 15rem;
  @include for-mobile {
    display: none;
  }
  .history-table-divider {
    border: 1px solid $vg-gray-5;
    height: 15rem;
    margin: 0 2rem;
  }
  .history-table {
    flex: 0.7;
    &.selected {
      background-color: $vg-gray-6;
    }
    .vg-history-table {
      table {
        tbody {
          tr {
            cursor: pointer;
            &:hover {
              background-color: $vg-gray-6;
            }
          }
        }
      }
    }
  }
  .history-details {
    flex: 0.3;
    border-left: $border-gray-5;
    box-sizing: border-box;
    padding-left: 2rem;
    .payment-details {
      @include full-content-width();
      thead,
      tbody {
        width: 100%;
        tr {
          text-align: left;
          th {
            font-size: 1rem;
            color: $vg-black;
          }
          td {
            font-size: 0.8rem;
            width: 5%;
            color: $vg-gray-2;
            &:last-child {
              text-align: right;
            }
          }
        }
        .total {
          font-weight: $vg-font-weight-semi-bold;
        }
      }
      thead {
        tr {
          height: 2.5rem;
        }
      }
      tbody {
        tr {
          height: 2.1rem;
        }
      }
    }
    .download-invoice-button {
      font-size: 0.8rem;
      color: $vg-purple-main;
      cursor: pointer;
    }
  }
  @include for-mobile {
    display: none;
  }
}

.payment-history-card {
  display: none;
  @include for-mobile {
    display: inherit;
  }
}
