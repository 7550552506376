@import "Assets/styles/Global/_variables.scss";

.summary-table-container {
  overflow-y: auto;
  .vg-expandable-table {
    .expand-table-button {
      color: $vg-gray-4;
      &:hover {
        color: $vg-gray-4;
      }
    }
  }

  .smooth {
    transition: none;
  }
}
