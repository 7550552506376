@import "Assets/styles/Global/_variables";

.vg-loader {
  height: 100%;
  @include flex;

  &.big {
    font-size: 2rem;
  }

  &.centered {
    @include full-content-width;
    @include flex;
    height: inherit;
    @include for-mobile {
      display: flex;
      width: 100%;
      height: 100vh;
      justify-content: center;
      align-items: center;
    }
  }

  &.dark-theme {
    background-color: $vg-black;
  }

  .description {
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 0;
  }
}
