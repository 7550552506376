@import "Assets/styles/Global/_variables";

.vg-checkbox {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  input[type="checkbox"] {
    width: 0.85rem;
    height: 0.85rem;
    accent-color: $vg-purple-main;

    &:not(.disabled) {
      cursor: pointer;
    }

    &:disabled {
      cursor: initial;
    }
  }
}
