@import "Assets/styles/Global/_variables.scss";

.app-streaming-error-container {
  @include full-viewport-size;
  @include flex($direction: column, $justify: center);
  position: relative;
  background-size: 100% 100%;
  background-image: url("../../../Assets/images/appStreaming/white-bg.svg");

  &.dark {
    background-image: url("../../../Assets/images/appStreaming/dark-bg.jpg");
  }

  .content-box {
    @include flex($direction: column, $align: center, $justify: space-around);
    gap: 1rem;
    padding: 2rem 3rem;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    min-width: 250px;

    p {
      font-size: $vg-font-size-small;
      text-align: center;
      white-space: pre-wrap;
    }

    button {
      padding: 0.7rem 1.8rem;
      background-color: $vg-aqua-dark;
      color: $vg-white;
      font-size: $vg-font-size-small;
      border: 2px solid $vg-aqua-dark;
      font-weight: $vg-font-weight-semi-bold;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  .app-logo-container {
    box-sizing: border-box;
    @include flex($direction: row, $align: flex-start);
    position: absolute;
    top: 4rem;
  }

  .error-img {
    width: 80px;
  }

  .logo-img {
    max-width: 100px;
  }

  @include for-mobile {
    min-width: auto;
    padding: 1rem;
    gap: 1rem;
    justify-content: center;

    .app-logo-container {
      display: none;
    }
  }

  @include for-small-computer {
    .app-logo-container {
      display: none;
    }
  }

  &.dark {
    .content-box {
      background: rgba(0, 0, 0, 0.3);
    }
    p {
      color: $vg-white;
      text-align: center;
    }

    button {
      background-color: $vg-dark-navy;
      border: 2px solid $vg-dark-navy;
      font-size: $vg-font-size-small;
    }
  }
}
