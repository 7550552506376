@import "Assets/styles/Global/_variables.scss";

.dashboard-base-container {
  @include full-viewport-size;
  @include flex($direction: row, $justify: flex-start, $align: flex-start);

  box-sizing: border-box;
  overflow: auto;

  @include for-mobile {
    @include flex($direction: column, $justify: flex-start, $align: center);

    overflow: auto;
  }

  .dashboard-content-container {
    @include dashboard-content-position;
    background-color: $vg-gray-7;
    overflow: auto;

    @include for-mobile {
      background-color: $vg-white;
      margin: 0;
      padding: 0;
    }
  }
}
