@import "Assets/styles/Global/_variables.scss";

.teams-templates-container {
  @include flex($direction: column, $justify: flex-start, $align: center);
  height: 100%;
  border-radius: 7px;
  position: relative;

  .top-bar {
    @include flex($direction: row, $justify: space-between, $align: center);
    @include full-content-width();
    margin-bottom: 2rem;

    @include for-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 8rem;
    }

    .left-actions {
      @include flex($direction: row, $justify: flex-start, $align: center);
      width: 25%;

      @include for-mobile {
        width: 100%;
      }

      .vg-search-input-container {
        width: 100%;
      }
    }
    .right-actions {
      @include flex($direction: row, $justify: flex-end, $align: center);
      @include full-content-height();
      .vertical-divider {
        width: 1px;
        height: 1.4rem;
        background-color: $vg-gray-3;
        margin: 0px 0.5rem;
      }
    }
  }

  .teams-templates-table {
    height: 100%;
    padding: 1rem;
    background-color: $vg-white;

    @include for-mobile {
      overflow-x: auto;
      .header-line {
        width: 45rem;
      }
    }

    .vg-tooltip {
      right: calc(100% + 0.6rem);
      top: -0.8rem;
      bottom: auto;
    }

    .table-body {
      overflow-y: auto;
      white-space: nowrap;
      border-radius: 5px;

      .table-row {
        line-height: 3;
      }

      @include for-mobile {
        width: 45rem;
      }
    }
    .table-cell {
      .highlighted {
        color: $vg-aqua-main;
      }
    }
    .buttons-cell {
      @include flex($direction: row, $justify: flex-end);
      height: 1rem;
      width: 100%;
      margin-left: 0.8rem;
      padding-top: 0.2rem;
      gap: 0.5rem;
      .vg-icon {
        display: inline-block;
        height: 1.15rem;
        width: 1.15rem;
        @include for-mobile {
          min-width: 1.15rem;
        }
      }
    }

    .assigned-users-cell {
      display: flex;
      align-items: center;
      gap: 0.15rem;
      .vg-icon {
        height: 0.9rem;
      }
    }

    .user-images-cell {
      @include flex($direction: row, $align: center, $justify: center);
      img {
        margin-right: -0.5rem;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 100px;
      }
    }

    .preinstalled-apps {
      width: 100% !important;

      .softwares {
        @include flex($direction: row, $align: center, $justify: flex-start);
        flex-wrap: wrap;

        .software-card {
          width: max-content;
          padding: 0 0.4rem;
          min-width: 3.5rem;
        }
      }
    }

    .expandable-box {
      background-color: $vg-gray-7;
    }
  }

  .no-content-container {
    padding: 1rem;
    height: 50vh;
    width: 100%;
    background-color: $vg-white;
  }
}
