@import "Assets/styles/Global/_variables";

.vg-verify-register-content {
  @include full-content-size;

  form,
  .vg-verify-register-failure-wrapper {
    @include full-content-size;
    @include flex($direction: column, $align: flex-start, $justify: space-between);

    .field-group {
      @include full-content-width;

      .vg-register-failure-content {
        color: $vg-gray-1;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: $vg-font-weight-regular;
      }
    }
  }

  a {
    color: $vg-purple-main;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-medium;
    line-height: 0.7rem;
    text-decoration: none;
  }
}
