.left-joystick {
  position: absolute;
  bottom: 6rem;
  left: 6rem;
}

.right-joystick {
  position: absolute;
  bottom: 6rem;
  right: 6rem;
}
