@import "Assets/styles/Global/_variables.scss";

.customize-texts {
  .texts-content {
    margin-top: 0.8rem;
    display: flex;
    align-items: flex-end;
    color: $vg-gray-2;
    font-size: $vg-font-size-tiny;
    gap: 0.4rem;

    .vg-input {
      color: $vg-gray-2;
      font-size: $vg-font-size-tiny;
      padding-bottom: 0rem;
      border-color: $vg-gray-5;
      margin: 0;
    }

    .vg-dropdown {
      padding: 0;
      flex: 1;
    }

    .text-input {
      width: 100%;
      flex: 2;
    }

    .vg-text-button {
      &:not(.disabled) {
        color: $vg-aqua-main;
      }
    }

    .actions {
      @include flex($direction: row);
      gap: 0.2rem;
      min-width: 4rem;
      padding-bottom: 9px;
      align-items: flex-end;
      justify-content: flex-end;

      .hide {
        opacity: 0;
      }
    }
  }
}
