@import "Assets/styles/Global/_variables";

.team-user-manager-table-container {
  @include flex($direction: column, $justify: flex-start, $align: stretch);
  width: 100%;
  max-height: calc(100vh - 16rem);
  background-color: $vg-white;
  position: relative;
  border-radius: 7px;

  .team-user-manager-table {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    @include for-mobile {
      width: 100%;
      overflow-x: scroll;
    }

    .table-body {
      overflow-y: auto;
      position: relative;
      white-space: nowrap;
      border-radius: 5px;
      @include for-mobile {
        width: 45rem;
      }
    }

    .header-line {
      @include for-mobile {
        width: 45rem;
      }
    }

    .checkbox {
      width: 0.85rem;
      height: 0.85rem;
      accent-color: $vg-purple-main;
    }

    .table-row {
      height: 2.4rem;
    }

    .table-cell {
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .vg-tooltip {
      right: calc(100% + 0.6rem);
      top: -0.75rem;
      bottom: auto;
    }

    .icon-buttons {
      @include flex($direction: row, $justify: flex-end);
      height: 1rem;
      width: 100%;
      margin-left: 0.8rem;
      gap: 0.5rem;
      .vg-icon {
        display: inline-block;
        height: 1.15rem;
        width: 1.15rem;
        &.disabled {
          opacity: 0.3;
        }
      }
      .vg-tooltip {
        width: fit-content;
        color: $vg-gray-3;
        @include for-mobile {
          display: none;
        }
      }

      .vertical-divider {
        background-color: $vg-gray-4;
        width: 1px;
        height: 80%;
        margin: 0 0.4rem;
      }
    }

    .expanded-object-info-container {
      width: 100% !important;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .expanded-content {
        max-width: 80%;
      }

      .v-tooltip {
        .tooltip-content {
          white-space: nowrap;
          width: fit-content;
          max-width: 16rem;
          white-space: pre-wrap;
        }
      }

      .vg-icon {
        width: 1.2rem;
      }

      .header {
        font-size: $vg-font-size-small;
        font-weight: $vg-font-weight-semi-bold;
        margin-bottom: 0.4rem;
        display: flex;
        align-items: flex-end;
        gap: 0.2rem;
      }

      .detail-container {
        display: flex;
        align-items: flex-end;
      }

      p {
        font-size: $vg-font-size-tiny;
        font-weight: $vg-font-weight-regular;
        white-space: break-spaces;

        span {
          color: $vg-gray-3;
        }
      }
    }
  }

  .create-computers-button {
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

  .show-revoke-description-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    .keep-file-checkbox {
      display: flex;
      align-items: flex-end;
      margin-top: 1rem;
    }

    input[type="checkbox"] {
      width: 0.85rem;
      height: 0.85rem;
      margin-right: 4px;
      accent-color: $vg-purple-main;
    }
  }

  .select-performance-pop-up {
    .menu-button {
      padding: 0.6rem 1rem;
    }
    .pop-up-header {
      padding: 4px 2rem 4px 4px;
      opacity: 0.6;
    }
  }
}
