@import "Assets/styles/Global/_variables";

.vg-circular-slider {
  @include flex($direction: row);
  box-sizing: border-box;
  cursor: pointer;
  margin: 2rem 0;
  position: relative;
  width: 100%;
  .display-value {
    position: absolute;
    left: 50;
    top: 50;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 50%;
  }
}
