@import "Assets/styles/Global/_variables";

.file-watcher {
  @include flex($direction: row, $justify: flex-start, $align: flex-start);
  position: fixed;
  bottom: 0;
  right: 6rem;
  height: 3.5rem;

  @include for-mobile {
    display: none;
  }

  .file-uploading {
    height: inherit;
    width: 16rem;
    .file-status {
      height: inherit;
      margin: 0;
      .file-name {
        margin-left: 1rem;
      }
    }
  }

  .files-uploading {
    font-size: 0.8rem;
    padding: 1.2rem 0;
    text-align: center;
    margin-left: 2rem;
    height: inherit;
    width: 8rem;
  }

  .file-information-bar {
    border: $border-gray-6;
    background-color: $vg-white;
    box-shadow: $light-shadow;
    cursor: pointer;
    &:hover {
      background-color: $vg-gray-6;
    }
    .file-status-content {
      padding: 0.3rem 1rem;
    }
  }
}
