@import "Assets/styles/Global/_variables";

.animation-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s;
  overflow: hidden;

  &.open {
    grid-template-rows: 1fr;
    height: auto;
  }

  .animation-content {
    min-height: 0;
    padding: 0.1rem;
  }
}
