@import "Assets/styles/Global/_variables.scss";

.payment-details {
  @include flex($direction: column, $align: flex-start, $justify: flex-start);
  width: 100%;
  border-collapse: collapse;
  table {
    width: 100%;
    thead {
      height: 1.5rem;
      border-bottom: $border-black;
      tr {
        text-align: left;
        font-size: $vg-font-size-small;
        th {
          height: 2rem;
          font-weight: $vg-font-weight-regular;
        }
      }
    }
    tbody {
      width: 100%;
      .discounted {
        text-decoration: line-through;
        opacity: 0.5;
      }

      tr {
        color: $vg-gray-2;
        width: 100%;
        line-height: 2.5rem;
        white-space: nowrap;
        font-size: $vg-font-size-small;
        font-weight: $vg-font-weight-regular;

        &:first-child {
          height: 3rem;
        }

        td {
          vertical-align: bottom;
          width: 100%;
          min-width: 5rem;
          &:last-child {
            color: $vg-gray-1;
            font-weight: $vg-font-weight-medium;
            text-align: end;
          }
        }
        &.total-amount {
          border-top: 2px solid $vg-gray-5;
          line-height: 3rem;
          td {
            font-weight: $vg-font-weight-bold;
            &:first-of-type {
              color: $vg-gray-2;
            }
          }
        }
      }
    }
  }
}
