@import "Assets/styles/Global/_variables";

.vg-usage-history-table {
  height: 80vh;
  max-height: calc(95vh - 10rem);
  @include for-mobile {
    display: none;
  }
  td {
    span {
      font-size: 0.7rem;
      text-transform: lowercase;
    }
  }

  .vg-history-table {
    table {
      margin-bottom: none;
      tbody {
        &.session-scroll {
          height: 70vh;
        }
      }
    }
  }
}

.vg-usage-history-card {
  display: none;
  @include for-mobile {
    display: inherit;
    flex-direction: column;
  }
}

.vg-usage-empty {
  @include flex;

  img {
    height: 25rem;
  }

  h1 {
    margin-top: -4rem;
    font-weight: $vg-font-weight-light;
    font-size: $vg-font-size-large;
  }

  p {
    color: $vg-gray-3;
  }

  .scrollable-tbody {
    max-height: 10rem;
    overflow-y: auto;
  }
}
