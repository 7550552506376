@import "Assets/styles/Global/_variables";

.vg-process-payment-modal {
  @include flex($direction: row, $align: flex-start, $justify: space-between);
  @include full-content-size;

  .credit-card-section {
    box-sizing: border-box;
    height: 15rem;
    padding-right: 2rem;
    width: 100%;

    .add-new-payment-method {
      @include flex;
      height: 100%;
      background-color: $vg-gray-4;
      border-radius: 0.25rem;
      box-sizing: border-box;
      cursor: pointer;
      opacity: 0.3;
      padding: 1rem 0.8rem;

      .vg-icon {
        height: 2.5rem;
        width: 2.5rem;
      }

      &:hover {
        background-color: $vg-gray-5;
      }
    }

    .stripe-info {
      @include flex($direction: row, $justify: flex-start, $align: center);
      margin-top: 0.8rem;

      .stripe-image {
        height: 25px;
      }

      .vg-icon {
        svg {
          height: 1.1rem;
          width: 1.1rem;
        }
      }
    }
  }

  .payment-details,
  .credit-card-section ~ .vg-loader {
    max-width: $big-button-width;
    min-width: $big-button-width;
    padding: 1rem 0;
    width: $big-button-width;

    .title {
      @include full-content-width;

      border-bottom: $border-black;
      color: $vg-black;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1.25rem;
      padding: 0.25rem 0;
    }

    .fee-detail {
      @include flex($direction: row, $align: center, $justify: space-between);
      @include full-content-width;

      color: $vg-gray-2;
      font-size: 0.8rem;
      line-height: 190%;
      padding: 0.8rem 0;
    }
  }
}
