@import "Assets/styles/Global/_variables";

.not-supported-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 5rem 0;
  box-sizing: border-box;

  .not-supported-info {
    font-size: 0.9rem;
    padding: 0rem 2rem 2.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      padding: 1rem 2rem;
      color: $vg-gray-3;
    }
  }

  img {
    height: 300px;
    width: 300px;
  }
  h1 {
    color: $vg-gray-2;
  }
  vg-button {
    height: 100px;
  }
}
