@import "Assets/styles/Global/_variables.scss";

.empty-content-container {
  @include flex($direction: column, $align: center, $justify: space-around);
  gap: 2rem;
  text-align: center;
  padding: 0 0 3rem;
  opacity: 0;
  animation: fade-in 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  margin: auto;
  img {
    height: 10rem;
    margin-bottom: 1rem;
  }
  h1 {
    color: $vg-black;
  }
  .empty-content-description {
    color: $vg-gray-1;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    max-width: 32rem;
    font-size: 0.85rem;
    line-height: 1.5;
  }
}
