@import "Assets/styles/Global/_variables.scss";

.vg-dropdown-menu-content {
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 999;
}
.vg-dropdown-menu-content[data-side="top"] {
  animation-name: slideDownAndFade;
}
.vg-dropdown-menu-content[data-side="right"] {
  animation-name: slideLeftAndFade;
}
.vg-dropdown-menu-content[data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.vg-dropdown-menu-content[data-side="left"] {
  animation-name: slideRightAndFade;
}

.vg-dropdown-menu-item {
  font-size: 0.8rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  position: relative;
  user-select: none;
  outline: none;
  color: $vg-gray-2;
  cursor: pointer;
}

.vg-dropdown-menu-item[data-disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.vg-dropdown-menu-item[data-highlighted] {
  background-color: $vg-gray-5;
  border-radius: 0;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.vg-dropdown-trigger-button {
  outline: none;
  border: none;
  border-radius: 100%;
  all: unset;
  width: 100%;
}

.right-slot {
  margin-left: auto;
  color: $vg-gray-2;
  display: flex;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
