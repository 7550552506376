@import "Assets/styles/Global/_variables.scss";

.vg-expandable-table,
.header-line {
  @include full-content-width;
  box-sizing: border-box;
}
.vg-expandable-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $vg-white;
  border-radius: 6px;
  padding: 0.2rem;
  .header-line,
  .table-row,
  .table-cell,
  .expandable-content {
    @include flex($direction: row, $align: center, $justify: flex-start);
  }

  .header-cell {
    &.centered {
      @include flex($direction: row, $align: center, $justify: center);
      text-align: center;
    }
  }

  .table-cell {
    &.centered {
      @include flex($direction: row, $align: center, $justify: center);
    }
  }

  .header-line,
  .table-row {
    padding: 0.4rem;
    line-height: 2.2;
    padding-right: 1rem;
  }

  .header-line {
    font-size: 0.8rem;
    &.overflowed {
      padding-right: calc(1rem + 8px); // 8px is the width of the scrollbar, constant
    }
  }
  .table-row {
    font-size: 0.75rem;
    color: $vg-gray-3;

    &.gray {
      background-color: $vg-gray-7;
    }

    &.disabled {
      opacity: 0.3;
    }

    &.clickable {
      cursor: pointer;
    }
  }
  .infinite-loading-bar {
    width: 100%;
    height: 1.5px;
    position: relative;
    border-radius: 2px;

    .loader {
      animation: loadingAnimation 2s linear infinite;
      position: absolute;
      width: 20%;
      height: 100%;
      background-image: linear-gradient(to right, #73d4c700, $vg-aqua-light, #73d4c700);

      border-radius: 2px;
    }
  }
  .expand-table-icon {
    margin-right: 0.5rem;
    padding: 0 0.2rem;
  }

  .expand-table-button {
    margin-left: auto;
  }

  .expandable-box-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s;
    overflow: hidden;

    &.disabled-animation {
      transition: grid-template-rows 0s;
      height: auto;
    }
    &.open {
      grid-template-rows: 1fr;
      height: auto;
    }
  }

  .expandable-box {
    font-size: 0.85rem;
    color: $vg-gray-2;
    position: relative;
    min-height: 0;
    transition: padding 0.5s;
    padding: 0 0.4rem;

    &.open {
      padding: 1.5rem 0.4rem;
    }

    .expandable-content {
      width: calc(100% - 4rem);
      margin-left: 2rem;
    }
    .expandable-content > div {
      width: 50%;
      box-sizing: border-box;
    }
    .vertical-bar {
      width: 3px;
      background-color: $vg-aqua-light;
      position: absolute;
      height: calc(100% - 3rem);

      border-radius: 2px;
      margin: 0 2rem 0 0.4rem;
    }
    span {
      p {
        font-size: 0.85rem;
        line-height: 1.5rem;
        span {
          color: $vg-gray-3;
        }
      }
    }
    h4 {
      font-weight: $vg-font-weight-semi-bold;
      margin-bottom: 0.4rem;
    }
  }
  .smooth {
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }
  .horizontal-divider {
    width: calc(100% - 1rem);
    height: 1px;
    background-color: $vg-gray-5;
    margin: auto;
  }

  .vg-table-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .loading-new-page {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 0.6rem;
  }
}

@keyframes loadingAnimation {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
