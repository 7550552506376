@import "Assets/styles/Global/_variables";
.update-performances-modal {
  .buttons-container {
    @include flex($direction: row, $align: center, $justify: space-between);
    @include full-content-width;
    margin-top: 1rem;

    &.single-button {
      justify-content: flex-end;
    }
  }
  .performance-options-table-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .vg-checkbox {
    margin-top: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    .save-for-all-checkbox {
      display: flex;
      align-items: flex-end;
      margin-top: 1rem;
    }

    input[type="checkbox"] {
      width: 0.85rem;
      height: 0.85rem;
      margin-right: 8px;
      accent-color: $vg-purple-main;
    }
  }
}
