@import "Assets/styles/Global/_variables";

.organization-member-billing-container {
  height: 100%;

  .billing-summary-container {
    display: grid;
    grid-template-columns: 3fr 1.8fr;
    gap: 1rem;
    margin-bottom: 4rem;
    .header {
      @include full-content-width;
      font-size: 1rem;
      font-weight: $vg-font-weight-medium;
    }

    .team-info-container {
      padding: 1rem 2rem;
      background-color: $vg-white;
      height: 100%;
      border-radius: 4px;
      box-sizing: border-box;

      @include for-mobile {
        padding: unset;
      }

      .header {
        font-size: 1.2rem;
        font-weight: $vg-font-weight-medium;
      }

      .team-usage-progress-container {
        @include flex($direction: row, $justify: flex-start, $align: center);
        margin-top: 1rem;

        .chart-container {
          height: 8rem;
          width: 8rem;
          position: relative;
          margin-right: 3rem;
          margin-top: 1rem;

          @include for-mobile {
            margin-right: 1rem;
          }

          .remaining-info {
            @include flex($direction: column, $justify: flex-start);
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
            p {
              color: $vg-gray-3;
              font-weight: $vg-font-weight-medium;
            }
            span {
              font-size: 0.8rem;
              font-weight: $vg-font-weight-regular;
              opacity: 0.6;
            }
          }
        }

        .team-usage-info-container {
          padding: 1rem;
          padding-left: 0;

          p:first-child {
            font-size: 0.75rem;
            font-weight: $vg-font-weight-light;
            color: $vg-gray-3;
            margin-bottom: 0.5rem;
            line-height: 2;
          }

          .color-explanations {
            @include flex($direction: row, $justify: flex-start, $align: center);
            margin-top: 1rem;
            gap: 2rem;
            font-size: 0.75rem;
            font-weight: $vg-font-weight-regular;
            opacity: 0.6;
            .color-explanation {
              @include flex($direction: row, $justify: flex-start, $align: center);
              gap: 0.5rem;
              .color {
                height: 0.75rem;
                width: 0.75rem;
                border-radius: 4px;
                &.aqua {
                  background-color: $vg-aqua-main;
                }
                &.baby-powder {
                  background-color: $vg-baby-powder;
                }
              }
            }
          }
        }
      }

      .divider {
        margin: 2rem 0 1rem;
        width: 70%;
        height: 1px;
        background-color: $vg-gray-5;
      }

      .alt-info {
        margin-top: 0.8rem;
        font-size: 0.75rem;
        font-weight: $vg-font-weight-light;
        opacity: 0.6;
        line-height: 2;
        color: $vg-gray-2;
        padding-top: 0.5rem;
      }
    }

    .personal-info-container {
      @include flex($direction: column, $justify: flex-start);
      @include full-content-width;
      box-sizing: border-box;
      padding: 1rem 2rem;
      background-color: $vg-white;
      height: 100%;
      border-radius: 4px;
      min-height: 18rem;

      .header-and-button {
        @include flex($direction: row, $justify: space-between);
        width: 100%;
        p {
          font-size: 1.2rem;
          font-weight: $vg-font-weight-medium;
        }
      }

      .transactions-container {
        @include flex($direction: column, $justify: center, $align: center);
        height: 80%;
        opacity: 0.4;
        margin-top: 1rem;

        .header {
          @include full-content-width;
          font-size: 1rem;
          font-weight: $vg-font-weight-medium;
        }
        .balance-information-container {
          @include full-content-width;
          @include flex($direction: row, $justify: space-between, $align: flex-end);
          .balance-information-content {
            @include flex($direction: column, $justify: flex-start, $align: flex-start);
            .balance-header {
              font-size: 0.6rem;
              line-height: 0.9rem;
              font-weight: $vg-font-weight-regular;
              margin-bottom: 0.5rem;
            }
            .balance-amount {
              font-size: 1.25rem;
              line-height: 1.25rem;
              font-weight: $vg-font-weight-medium;
            }
          }
          .balance-action {
            font-size: 0.8rem;
            line-height: 0.8rem;
            font-weight: $vg-font-weight-regular;
            color: $vg-purple-main;
            cursor: pointer;
          }
        }

        .divider {
          @include full-content-width;
          height: 0;
          border: $border-gray-5;
          margin: 1.25rem 0;
        }

        .description {
          font-size: 0.75rem;
          font-weight: $vg-font-weight-light;
          line-height: 2;
          opacity: 0.6;
        }
      }

      .payment-container {
        @include flex($direction: row, $justify: flex-start, $align: center);
        margin-top: 1rem;
        border-radius: 4px;
      }
      .usages-container {
        padding: 1.25rem 2rem;
        min-width: 500px;
        box-sizing: border-box;
        margin-right: 2rem;
        background-color: $vg-white;
        height: 17rem;
        border-radius: 3px;

        .remaining-credit {
          font-size: 0.8rem;
          text-align: end;
          color: $vg-gray-3;
        }
      }

      @include for-mobile {
        flex-direction: column;
        .transactions-container,
        .payment-container {
          padding-left: 0;
          margin-left: 0;
        }
        .usages-container {
          padding: 0;
        }
      }
    }
  }

  .billing-history-container {
    @include full-content-width();
    .billing-history-titles {
      @include flex($direction: row, $justify: flex-start, $align: flex-start);

      .vg-text-button {
        margin: 0 1rem 1rem 0;
        font-size: 1rem;
        color: $vg-black;
        &:disabled {
          color: $vg-purple-main;
          opacity: 1;
        }
      }
    }
  }
}
