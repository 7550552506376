@import "Assets/styles/Global/_variables";

.vg-account-container {
  .account-information-container {
    .header {
      color: $vg-black;
      font-size: 1rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1.25rem;
      margin-bottom: 1rem;
    }

    .description {
      color: $vg-gray-3;
      font-size: 0.7rem;
      font-weight: $vg-font-weight-regular;
      line-height: 1.1rem;
      margin-bottom: 1rem;
    }

    .label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1.1rem;
      padding: 0.3rem 0;
    }

    .referral-box {
      @include flex($direction: row, $justify: space-between, $align: center);
      flex-wrap: wrap;
      height: fit-content;
      min-height: 5rem;
      background: $vg-white;
      border: 1px solid $vg-gray-6;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 0.8rem;
      margin-top: 1rem;

      @include for-mobile {
        height: 10rem;
      }

      .vg-text-button {
        padding: 0.2rem 2rem;
        font-size: 0.8rem;
      }

      .divider {
        border-left: 2px solid $vg-gray-5;
        height: 3rem;
        margin: 0 3rem 0 1rem;

        @media (max-width: 1200px) {
          display: none;
        }
      }

      .referral-box-section {
        @include flex($direction: row, $justify: flex-start, $align: center);
        padding: 1rem 0;

        @media (max-width: 1200px) {
          margin: auto;
        }

        @include for-mobile {
          margin-left: 2rem;
        }
      }

      .referral-link-container {
        box-sizing: border-box;

        @include for-mobile {
          width: 100%;
          border: none;
        }

        h2 {
          color: $vg-gray-2;
          font-weight: $vg-font-weight-medium;
          font-size: 0.9rem;
        }

        .referral-link {
          @include flex($direction: row, $justify: flex-start, $align: flex-end);
          color: $vg-gray-3;
          white-space: nowrap;
          font-size: 1rem;
          font-size: 0.9rem;

          @include for-mobile {
            font-size: 0.7rem;
          }
        }
      }

      img {
        padding: 0 2rem;
        height: 3rem;
        @include for-mobile {
          display: none;
        }
      }
    }

    .change-password-container {
      display: flex;
      .profile-picture {
        margin-right: 2rem;
        margin-top: 1.5rem;
        .profile-edit-button {
          top: 3.5rem;
        }
      }

      .label {
        padding: 0 0 0.3rem;
      }
    }
    .profile-information-container {
      @include for-mobile {
        flex-direction: column;
      }
      color: $vg-black;
      font-size: 1rem;
      font-weight: $vg-font-weight-light;
      line-height: 1.5rem;
      padding: 1.5rem 0 1rem;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;

      .profile-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-radius: 50%;

        .information-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 25%;
          margin-left: 2rem;

          .action {
            justify-content: start;
            margin-top: 0.5rem;
          }

          .user-info {
            @include for-mobile {
              font-size: $vg-font-size-tiny;
            }
            p {
              font-size: 0.9rem;
            }
          }
        }
      }

      .additional-information-container {
        width: 100%;
        .time-zone-value-container {
          display: flex;
          justify-content: flex-start;
          gap: 0.5rem;
          .info-value {
            font-size: 0.9rem;
          }
        }
      }
    }

    .google-information {
      display: flex;
      color: $vg-black;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-light;
      line-height: 1.65rem;
      padding: 0rem 0 0.5rem;
      gap: 0.5rem;
    }

    .change-password-form {
      @include full-content-width();
      @include flex($justify: flex-start, $align: flex-start);
      position: relative;
      padding-top: 1.5rem;
      .top-right-close-icon {
        height: 1.5rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 1.5rem;
        cursor: pointer;
      }
      .password-input-wrapper {
        width: 40%;
        @include for-mobile {
          width: 100%;
        }
      }

      .button {
        align-self: flex-end;
      }
    }

    .action {
      color: $vg-purple-main;
      cursor: pointer;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1.1rem;
    }

    .reset-button-container {
      display: flex;
      gap: 0.3rem;
    }

    .action-reset {
      color: $vg-purple-main;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1.1rem;
    }

    .divider-container {
      margin: 1.5rem 0;
    }

    .delete-actions-container {
      @include flex($direction: row, $justify: space-between, $align: flex-start);
      @include for-mobile {
        flex-direction: column;
        gap: 1rem;
      }
      gap: 4rem;

      .delete-computer {
        @include for-mobile {
          width: 100%;
        }
      }
      .delete-computer,
      .delete-account {
        box-sizing: border-box;
        flex: 0.35;

        .header {
          color: $vg-black;
          font-size: 0.8rem;
          font-weight: $vg-font-weight-medium;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;
        }

        .action {
          color: $vg-purple-main;
          font-size: 0.7rem;
          font-weight: $vg-font-weight-medium;
          line-height: 1.4rem;
        }

        .reset-computer-text-buttons {
          display: flex;
          justify-content: space-between;
          @include for-mobile {
            width: 100%;
          }
        }
      }
    }
  }
}
