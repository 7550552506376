@import "Assets/styles/Global/_variables";

.bundled-app-list-modal {
  height: 20rem;

  .description {
    @include full-content-width;
    margin-top: 1rem;
  }
  .header {
    font-weight: $vg-font-weight-regular !important;
  }
  th,
  td {
    padding: 0.4rem;
    vertical-align: middle;
  }
  .scrollable-table-container {
    @include full-content-width;
    position: relative;
    height: 14rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
}
