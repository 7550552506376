@import "Assets/styles/Global/_variables";

.billing-history-container {
  @include full-content-width();
  .billing-history-titles {
    @include flex($direction: row, $justify: flex-start, $align: flex-start);

    .vg-text-button {
      margin: 0 1rem 1rem 0;
      font-size: 1rem;
      color: $vg-black;
      &:disabled {
        color: $vg-purple-main;
        opacity: 1;
      }
    }
  }
}

.transactions-and-payment-container {
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  @include full-content-width;
  margin-bottom: 5.5rem;

  .transactions-container {
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    flex: 0.4;
    height: 13rem;
    min-width: 20rem;
    margin-right: 1rem;
    background-color: $vg-white;
    border-radius: 3px;
    padding: 1.25rem 2rem;
    box-sizing: border-box;

    .header {
      @include full-content-width;
      font-size: 1rem;
      font-weight: $vg-font-weight-medium;
      margin-bottom: 1.25rem;
    }
    .balance-information-container {
      @include full-content-width;
      @include flex($direction: row, $justify: space-between, $align: flex-end);
      .balance-information-content {
        @include flex($direction: column, $justify: flex-start, $align: flex-start);
        .balance-header {
          font-size: 0.6rem;
          line-height: 0.9rem;
          font-weight: $vg-font-weight-regular;
          margin-bottom: 0.5rem;
        }
        .balance-amount {
          font-size: 1.25rem;
          line-height: 1.25rem;
          font-weight: $vg-font-weight-medium;
        }
      }
      .balance-action {
        font-size: 0.8rem;
        line-height: 0.8rem;
        font-weight: $vg-font-weight-regular;
        color: $vg-purple-main;
        cursor: pointer;
      }
    }

    .divider {
      @include full-content-width;
      height: 0;
      border: $border-gray-5;
      margin: 1.25rem 0;
    }

    .description {
      font-size: 0.75rem;
      font-weight: $vg-font-weight-regular;
      opacity: 0.6;
    }

    &.team-member {
      height: 100%;
      min-height: 13rem;
      max-width: 30rem;
      flex-grow: 1;
      .balance-header,
      .balance-amount {
        color: $vg-gray-3;
      }
      .divider {
        margin: 2rem 0;
      }
    }
  }

  .payment-container {
    margin-left: 2.5rem;
    flex: 0.5;
    .payment-methods-header {
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: $vg-font-size-medium;
      margin-bottom: 1.25rem;
    }
  }
  .usages-container {
    padding: 1.25rem 2rem;
    min-width: 500px;
    box-sizing: border-box;
    margin-right: 2rem;
    background-color: $vg-white;
    height: 17rem;
    border-radius: 3px;

    .remaining-credit {
      font-size: 0.8rem;
      text-align: end;
      color: $vg-gray-3;
    }
  }

  @include for-mobile {
    flex-direction: column;
    .transactions-container,
    .payment-container {
      padding-left: 0;
      margin-left: 0;
    }
    .usages-container {
      padding: 0;
    }
  }
}
