@import "Assets/styles/Global/_variables.scss";

$input-font-size: 0.8rem;
$medium-input-font-size: 1.2rem;
$big-input-font-size: 1.5rem;
$error-padding: 0.4rem;

.vg-otp-input-container {
  @include flex($direction: column, $align: flex-start, $justify: center);
  .input-line {
    @include flex($direction: row, $align: center, $justify: space-between);
    position: relative;

    .vg-icon {
      position: absolute;
      right: 0;
      top: 0.25rem;
    }

    .error-wrapper {
      @include flex();
      background-color: $vg-white;
      border-radius: 2px;
      bottom: 0.8rem;
      color: $vg-gray-2;
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
      font-size: 0.6rem;
      left: calc(100% + 1rem);
      min-height: 1.4rem;
      min-width: 7rem;
      padding: $error-padding;
      position: absolute;
      width: 10vw;

      &::after {
        border-color: transparent $vg-white transparent transparent;
        border-style: solid;
        border-width: $error-padding;
        content: "";
        margin-top: -$error-padding;
        position: absolute;
        right: 100%; /* To the left of the tooltip */
        top: 50%;
      }
    }
  }

  .error-wrapper-mobile {
    display: none;

    @include for-mobile {
      color: $vg-purple-main;
      display: block;
      font-size: 0.7rem;
      line-height: 1.3rem;
      margin-top: 0.2rem;
    }
  }
}
