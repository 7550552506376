@import "Assets/styles/Global/_variables";

.vg-storage {
  @include flex($direction: row, $align: flex-start, $justify: space-between);
  @include full-content-height;
  min-height: 100%;
  flex-grow: 1;

  .left {
    flex: 0.5;

    .header {
      color: $vg-black;
      font-size: 1rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1.25rem;
    }

    .disk-size {
      @include flex($direction: row, $align: center, $justify: flex-start);
      @include for-mobile {
        justify-content: space-between;
      }
      color: $vg-black;
      font-size: 1.25rem;
      font-weight: $vg-font-weight-light;
      height: 3rem;
      line-height: 1.25rem;
      margin: 1.5rem 0;
      padding-right: 2rem;

      .vg-icon {
        margin: 0 2rem;
        width: 3.75rem;

        @include for-mobile {
          margin: 0 0.5rem;
          width: 5rem;
        }
      }

      .monthly-increase {
        color: $vg-purple-main;
        font-size: 0.8rem;
        line-height: 1rem;
        margin: 0 1rem;
      }
      @media only screen and (max-width: 800px) {
        .monthly-increase {
          display: none;
        }
      }
    }

    .description {
      color: $vg-gray-3;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-light;
      line-height: 1.2rem;
      white-space: pre-line;
    }

    .slider {
      @include for-mobile {
        display: none;
      }
    }

    .circular-slider {
      display: none;
      @include for-mobile {
        display: block;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      .storage-disk-size {
        @include flex($direction: column, $align: center, $justify: center);
        font-size: 2rem;
        line-height: 2rem;
        color: #000000;

        span {
          font-size: 0.9rem;
          line-height: 1.5rem;
          text-align: center;
          color: #000000;
        }
        .primaryTextInCircularSlider {
          font-size: 1.8rem;
        }
        .secondaryTextInCircularSlider {
          font-size: 0.75rem;
        }
      }
    }
  }

  .right {
    @include flex($justify: space-between);
    @include full-content-height;

    flex: 0.35;
    margin-top: 1rem;

    @include for-mobile {
      @include full-content-width;
    }

    img {
      margin-left: 2rem;
    }

    .storage-image {
      height: 12rem;
      float: right;

      @include for-mobile {
        display: none;
      }
    }
  }

  .error {
    padding: 1rem 0;
  }

  @include for-mobile {
    flex-direction: column;
    .left {
      width: 100%;
    }
  }
}

.storage-action {
  @include flex($align: flex-start);
  margin-right: -50%;
  width: 100%;

  @include for-mobile {
    margin: 0;
  }
}
