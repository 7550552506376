@import "Assets/styles/Global/_variables.scss";

.usage-analytics-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;

  .filter-row,
  .dropdown-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    @include for-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .vg-dropdown {
      width: 20%;
      margin-right: 1rem;
      font-size: 0.75rem;
      @include for-mobile {
        width: 50%;
      }

      .placeholder {
        font-size: 0.75rem;
      }
    }

    .info-button-container {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      margin-left: auto;

      p {
        font-size: 0.8rem;
        color: $vg-gray-4;
      }

      &:hover {
        p {
          color: $vg-aqua-main;
          transition: color 0.1s ease-in-out;
        }

        .vg-icon {
          * {
            fill: $vg-aqua-main;
            transition: fill 0.1s ease-in-out;
          }
        }
      }

      .vg-icon {
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .dropdown-container {
      @include for-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
    }
  }

  .usage-info-cards {
    @include flex($direction: row, $align: center, $justify: space-around);
    gap: 2rem;
    margin-top: 2rem;
    @include for-mobile {
      overflow-x: auto;
    }

    .usage-info-card {
      @include flex($direction: row, $align: center, $justify: flex-start);
      width: 100%;
      background-color: white;
      padding: 1rem 2rem 1rem 1.2rem;
      border-radius: 4px;

      img {
        height: 2.2rem;
        width: 2.2rem;
      }

      .vg-icon {
        margin-left: auto;
        margin-bottom: auto;
      }

      .text-container {
        margin-left: 1rem;
      }

      p {
        font-size: $vg-font-size-tiny;
        color: $vg-gray-3;
      }
      span {
        &.bold {
          font-size: $vg-font-size-medium;
          color: $vg-gray-1;
        }
        &.light {
          font-size: 0.6rem;
          color: $vg-gray-4;
        }
      }
    }
  }

  .organization-sessions-table {
    max-height: 20rem;
    background-color: $vg-white;
    height: 100%;
    padding-top: 0.5rem;
    box-sizing: border-box;
    @include for-mobile {
      overflow-x: auto;
    }

    .header-cell {
      margin-left: 0.3rem;
    }

    .table-cell {
      margin-left: 0.3rem;
    }
    .header-line {
      @include for-mobile {
        width: 45rem;
      }
    }
    .table-body {
      height: 100%;
      overflow-y: auto;
      @include for-mobile {
        width: 45rem;
      }
    }

    .connection-column {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: auto;

      .vg-icon {
        height: 1.2rem;
        width: 1.2rem;
      }
      .vg-tooltip-hoverable {
        display: flex;
      }
    }
  }

  .analytic-charts-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .upper-row {
      margin-top: 2rem;
      display: flex;
      width: 100%;
      height: 100%;
      gap: 1rem;

      > div {
        &:first-child {
          width: 33%;
        }

        &:last-child {
          width: 66%;
        }
      }

      .content-container-xd {
        @include for-mobile {
          overflow-x: auto;
        }
      }
    }

    .lower-row {
      display: flex;
      width: 100%;
      height: 100%;
      margin-bottom: 2rem;
      gap: 1rem;

      > div {
        width: 50%;
      }
    }
  }

  .table-body {
    overflow-y: auto;

    .table-row {
      &.opacity-50 {
        opacity: 0.5;
      }
    }
  }

  .machine-name-cell {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .vg-icon {
      height: 1.2rem;
      width: 1.2rem;
      opacity: 0.4;
    }
  }

  .no-data-content {
    @include flex($direction: column, $justify: center, $align: center);
    height: 100%;
    gap: 2rem;

    .text-container {
      width: 70%;

      h3 {
        text-align: center;
        color: $vg-gray-2;
        font-weight: $vg-font-weight-semi-bold;
        font-size: $vg-font-size-medium;
      }

      p {
        margin-top: 0.8rem;
        text-align: center;
        color: $vg-gray-1;
        font-size: 0.8rem;
      }
    }

    img {
      height: 5rem;
      width: 5rem;
    }
  }
}
