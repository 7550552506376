@import "Assets/styles/Global/_variables.scss";

.connection-container {
  @include full-content-size;
  @include flex($direction: column, $justify: space-between, $align: center);

  .connection-content {
    @include full-content-size;
    @include flex($direction: column, $justify: flex-start, $align: center);
    min-height: 400px;
    height: 70vh;
    text-align: center;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;

    .map-section {
      @include full-content-size;
      scroll-snap-align: center;
      min-height: 95%;
    }

    .connection-section {
      @include flex($direction: column, $justify: flex-start, $align: center);
      width: 70%;
      min-height: 100%;
      padding-top: 5rem;
      box-sizing: border-box;
      scroll-snap-align: center;

      h2 {
        font-size: $vg-font-size-large;
        font-weight: $vg-font-weight-regular;
        color: $vg-gray-1;
        margin-bottom: 0.5rem;
      }

      h3 {
        font-size: $vg-font-size-medium;
        font-weight: $vg-font-weight-light;
        color: $vg-gray-2;
      }

      p {
        font-size: $vg-font-size-medium;
        font-weight: $vg-font-weight-light;
        color: $vg-gray-2;
      }

      .connection-status {
        @include flex($direction: row, $justify: center, $align: center);
        margin-top: 0.5rem;
        color: $vg-gray-1;
        font-size: $vg-font-size-huge;
        .vg-icon {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .scroll-page {
    @include flex($direction: column, $justify: center, $align: center);
    left: 50%;
    min-width: 10rem;
    transform: translate(-50%, 0);
    color: $vg-gray-4;
    text-align: center;
    position: absolute;
    white-space: pre-wrap;
    @include for-mobile {
      display: none;
    }
    @include for-short-screen {
      display: none;
    }
  }
}
