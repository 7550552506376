@import "Assets/styles/Global/_variables";

.google-sign-in-button {
  @include flex($direction: row, $justify: center);
  gap: 0.5rem;
  font-weight: 400;
  padding: 10px;
  border: 1px solid rgba($vg-gray-3, 0.5) !important;
  transition: all 0.25s ease;

  &.clickable:hover:not(:disabled) {
    box-shadow: none;
    border: 1px solid $vg-purple-main !important;
    color: $vg-purple-main;
  }
}
