@import "Assets/styles/Global/_variables";

.file-manager-table {
  @include full-content-size();
  flex-grow: 1;
  .table-wrapper {
    @include full-content-size();

    .table-header {
      //@include flex($direction: row, $justify: flex-start, $align: flex-end);
      display: grid;
      grid-template-columns: [first] 40% [line2] 20% [line3] 20% [line4] 20%;
      width: 100%;
      margin-bottom: 0.5rem;
      padding-right: 12px;
      box-sizing: border-box;
      .table-header-column {
        flex: 1 0 20%;
        text-align: left;
        .cell-content {
          @include flex($direction: row, $justify: flex-start, $align: center);
          box-sizing: border-box;
          padding-left: 20%;
          height: 1.5rem;
          .vg-icon,
          .vg-tooltip-hoverable {
            height: 1.5rem;
            position: relative;
          }
          .vg-icon {
            position: unset;
          }
        }
        &:first-of-type {
          flex-basis: 30%;
          .cell-content {
            flex: 0.5 1 1;
            min-width: 15rem;
            padding-left: 0.5rem;
          }
        }
        &:nth-of-type(2) {
          flex-basis: 10%;
        }
      }
    }
    .table-body {
      // @include flex($direction: column, $justify: flex-start, $align: flex-start);
      display: grid;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      grid-auto-rows: min-content;

      .table-body-row {
        @include full-content-width();
        // @include flex($direction: row, $justify: flex-start, $align: flex-end);
        display: grid;
        grid-template-columns: [first] 40% [line2] 20% [line3] 20% [line4] 20%;
        min-height: 2.5rem;
        outline: none;
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
        cursor: pointer;
        &:hover:not(.disabled) {
          background-color: $vg-gray-6;
        }
        &.disabled {
          cursor: default;
          opacity: 0.5;
          pointer-events: none;
        }

        .table-body-column {
          flex: 1 0 20%;
          width: 100%;
          text-align: left;
          font-size: 0.9rem;
          &.last-column-dock {
            .cell-content {
              justify-content: flex-end;
            }
          }
          .cell-content {
            @include full-content-height();
            display: flex;
            align-items: center;
            box-sizing: border-box;
            max-width: 100%;
            //overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 20%;
            color: $vg-gray-3;
            white-space: nowrap;

            .hovered-checkbox {
              &.show-checkbox {
                input[type="checkbox"] {
                  width: 15px;
                  height: 15px;
                }
                animation: check-input-animation 0.3s;
                position: relative;
                animation-timing-function: ease-out;
                display: flex;

                @keyframes check-input-animation {
                  from {
                    left: -100px;
                  }
                  to {
                    left: 0px;
                  }
                }
              }
            }

            .vg-text-button {
              font-size: 0.9rem;
              height: 1.2rem;
            }
            .vg-tooltip {
              width: auto;
              white-space: nowrap;
              bottom: -2rem;
              z-index: 1;
            }
            .fileNameContainer {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &:first-of-type {
            flex-basis: 30%;
            .cell-content {
              @include flex($direction: row, $justify: flex-start, $align: center);
              min-width: 15rem;
              flex: 0.5 1 30%;
              padding-left: 0.5rem;
              color: $vg-gray-1;
              .vg-icon {
                flex-grow: 0;
                flex-shrink: 0;
                height: 1.3rem;
                margin-right: 0.5rem;
              }
            }
          }
          &:nth-of-type(2) {
            flex-basis: 10%;
          }
        }
      }
      .selected-table-body-row {
        background-color: $vg-gray-6;
      }
    }
  }
  .vg-progress-bar-container {
    background-color: $vg-gray-6;
    width: 1rem;
    margin: 0 0.5rem;
  }
  .fileNameContainer {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.dock-body .file-manager-table .table-wrapper .table-body .table-body-row {
  grid-template-columns: 2fr 1fr;
}
.mobile .file-manager-table .table-wrapper .table-body .table-body-row {
  grid-template-columns: 2fr 1fr;
}
