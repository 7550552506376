@import "Assets/styles/Global/_variables.scss";

.add-balance-form {
  position: relative;

  .balance-line {
    @include flex($direction: row, $justify: space-between, $align: center);

    .default-credit {
      .text-info {
        position: relative;
        position: absolute;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        white-space: break-spaces;
        top: -20px;
        margin-left: -1.3rem;
        p {
          color: $vg-purple-main;
          font-size: 0.6rem;
          margin: 0rem;
          padding: 3px 12px;
        }
      }
    }

    .balance {
      white-space: pre-wrap;
    }
  }

  .select-balance {
    @include flex($direction: row, $justify: center, $align: center);
    flex-wrap: wrap;

    &:hover,
    &.selected {
      button {
        opacity: 0.5;
      }
    }

    .vg-button,
    p {
      font-weight: 500;
      font-size: $vg-font-size-large;
      color: $vg-gray-2;
    }

    span {
      font-weight: 300;
      font-size: $vg-font-size-small;
      color: $vg-gray-2;
    }

    .vg-button {
      @include for-mobile {
        width: 8rem;
      }
      width: 6rem;
      height: 3.5rem;
      padding: 0;
      margin: 0.5rem;

      border-color: $vg-gray-6;
      border-radius: 5px;
      &.selected,
      &.selected p,
      &.selected span {
        background-color: $vg-purple-main;
        color: $white;
        opacity: 1;
      }
      &:not(.selected):hover {
        box-shadow: $light-shadow;
        opacity: 1;
      }
    }
    .custom-balance-button {
      width: 6rem;
    }

    @include for-mobile {
      min-width: auto;

      margin: 0 0 2rem;
      .vg-button {
        margin: 0.3rem;
        font-size: 1rem;
      }
      .custom-balance-button {
        width: 10rem;
        @include for-mobile {
          width: 8rem;
        }
      }
    }
  }

  .custom-balance {
    @include flex($direction: row, $align: flex-end, $justify: center);
    font-size: $vg-font-size-large;
    font-weight: $vg-font-weight-light;
    color: $vg-gray-2;

    &.selected .vg-input {
      border-color: $vg-purple-main;
    }

    p {
      display: inline-block;
      font-size: 1rem;
      padding-left: 0.5em;
    }

    &:not(.credit) .vg-input::before {
      content: "$";
      position: absolute;
      left: 0.1rem;
      top: 1px;
    }

    .vg-input {
      width: 5rem;
      max-width: 5rem;
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      border-color: $vg-gray-6;
      text-align: center;

      @include for-mobile {
        width: 6rem;
        max-width: 6rem;
      }

      .input-wrapper {
        margin: 0;
        .vg-input {
          font-size: $vg-font-size-large;
        }
      }
    }

    .custom-balance-text-info {
      display: flex;
      flex-direction: column;
      border-radius: 2px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      white-space: break-spaces;
      margin-left: 5rem;

      p {
        color: $vg-purple-main;
        font-size: 0.6rem;
        margin: 0;
        padding: 5px 10px;
      }
    }
  }

  &.small {
    .select-balance {
      margin-bottom: 0;
    }
    .custom-button-wrapper {
      width: 14rem;
      .vg-button {
        &.selected,
        &.selected p,
        &.selected span {
          background-color: $vg-purple-main;
          color: $white;
          opacity: 1;
        }
      }
    }
    .custom-balance {
      margin-top: -4rem;
      margin-left: 4rem;
      height: 3.5rem;
      &:not(.selected) {
        .input-wrapper .vg-input,
        .vg-input::before {
          color: $vg-gray-5;
        }
      }
    }

    @include for-mobile {
      .custom-balance {
        left: 15rem;
        margin-left: 8rem;
        margin-top: -3rem;
      }
      .custom-button-wrapper {
        @include for-mobile {
          width: 17rem;
        }
        width: 21rem;
      }
    }
  }
}
