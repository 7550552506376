@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");

/* Color constants */
$vg-black: #000;
$vg-purple-main: #4500a6;
$vg-aqua-main: #47c2b5;
$vg-white: #fff;
$vg-silk-red: #e08989;
$vg-light-orange: #ffdc81;
$vg-lush-lavendar: #abb6ff;

$vg-dark-navy: #26055c;
$vg-light-navy: #41287d;
$vg-purple-light: #5e73ff;
$vg-purple-open: #8a5dc9;
$vg-baby-powder: #abb3ff;
$vg-blue-baby: #455ed4;
$vg-blue-paypal: #3d93ed;
$vg-aqua-dark: #45abb5;
$vg-aqua-light: #73d4c7;
$vg-yellow: #ffd600;
$vg-baby-powder-transparent: #abb3fe80;
$vg-aqua-light-transparent: rgba(115, 212, 199, 0.2);

$vg-gray-1: #333;
$vg-gray-2: #4f4f4f;
$vg-gray-3: #828282;
$vg-gray-4: #bdbdbd;
$vg-gray-5: #e0e0e0;
$vg-gray-6: #f2f2f2;
$vg-gray-7: #f8f8f8;

$vg-green-3: #6fcf97;

$vg-notification-bg: #515464;

/* Font weight constants */
$vg-font-weight-light: 300;
$vg-font-weight-regular: 400;
$vg-font-weight-medium: 500;
$vg-font-weight-semi-bold: 600;
$vg-font-weight-bold: 700;

$vg-font-size-tiny: 0.8rem;
$vg-font-size-small: 0.9rem;
$vg-font-size-medium: 1rem;
$vg-font-size-large: 1.2rem;
$vg-font-size-larger: 1.6rem;
$vg-font-size-huge: 2rem;

/* Border radius constants */
$vg-border-radius-small: 3px;

/* Borders */
$border-black: 1px solid $vg-black;
$border-purple-main: 1px solid $vg-purple-main;
$border-gray-3: 1px solid $vg-gray-3;
$border-gray-4: 1px solid $vg-gray-4;
$border-gray-5: 1px solid $vg-gray-5;
$border-gray-6: 1px solid $vg-gray-6;
$border-gray-7: 1px solid $vg-gray-7;
$border-white: 1px solid $vg-white;
$border-aqua-main: 1px solid $vg-aqua-main;
$border-aqua-light: 1px solid $vg-aqua-light;
$border-baby-powder: 1px solid $vg-baby-powder;

/* Color constants */
$white: #fff;
$black: #000;
$soft-blue: #6285f4;
$dark: #2b2b47;
$grey: #eceaee;
$gold: #ffaf57;
$blue-grey: #8481a3;
$soft-grey: #f5f4f6;
$dark-grey: #d4d4d4;
$green: #0fef43;
$red: #e62164;

$connecting-screen-background-color: $vg-black;

$white-transparent: rgba(255, 255, 255, 0.5);
$text-color-light: rgba(43, 43, 71, 0.7);
$text-color-extra-light: rgba(43, 43, 71, 0.5);
$soft-blue-light: #eef2fd;
$soft-blue-baby: rgba(69, 94, 212, 0.05);
$medium-blue-baby: rgba(69, 94, 212, 0.4);

$light-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
$medium-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
$hover-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);

/* Font family constants */
$default-font-family: "vagonRails", sans-serif;

/* Font size constants */
$widescreen-1-rem-size: 18px;
$computer-1-rem-size: 17px;
$mobile-1-rem-size: 16.8px;

/* Border width constants */
$border-width-small: 1px;

/* Box sizing constants */
$box-sizing-border-box: border-box;

/**
 * Custom spacing constants.
 */
$width-navbar-desktop: #{"max(15vw, 220px)"};

$intercom-height: 32px;
$big-button-width: 15rem;
$medium-button-width: 12rem;
$dock-width: 400px;

/* Responsive Mixins */
@mixin for-vertical-mobile {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin for-mobile {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin for-not-mobile {
  @media (min-width: 800px) {
    @content;
  }
}

@mixin for-big-mobile {
  @media (max-width: 800px) and (min-width: 501px) {
    @content;
  }
}

@mixin for-small-computer {
  @media (max-width: 1040px) and (min-width: 801px) {
    @content;
  }
}

@mixin for-computer {
  @media (max-width: 1440px) and (min-width: 1041px) {
    @content;
  }
}

@mixin for-big-computer {
  @media (min-width: 1041px) {
    @content;
  }
}

@mixin for-short-screen {
  @media (max-height: 600px) {
    @content;
  }
}

@mixin for-long-screen {
  @media (min-height: 1440px) {
    @content;
  }
}

@mixin for-small-screen {
  @include for-big-mobile {
    @content;
  }
  @include for-small-computer {
    @content;
  }
}

@mixin for-rotated-mobile {
  @media (max-width: 800px) and (orientation: landscape) {
    @content;
  }
}

@mixin flex($direction: column, $justify: center, $align: center) {
  align-items: $align;
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
}

@mixin full-viewport-width {
  width: 100vw;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin full-viewport-height {
  height: 100vh;
  max-height: 100vh;
  @include for-mobile {
    height: calc(var(--vh, 1vh) * 100);
  }
}

@mixin full-viewport-size {
  @include full-viewport-width;
  @include full-viewport-height;
}

@mixin full-content-width {
  width: 100%;
}

@mixin full-content-height {
  height: 100%;
}

@mixin custom-content-width($width) {
  width: $width;
}

@mixin custom-content-height($height) {
  height: $height;
}

@mixin full-content-size {
  @include full-content-width;
  @include full-content-height;
}

@mixin text-align($align: left) {
  text-align: $align;
}

@mixin dashboard-content-position {
  height: 100vh;
  margin-left: $width-navbar-desktop;
  max-width: calc(100vw - #{$width-navbar-desktop});
  width: calc(100vw - #{$width-navbar-desktop});

  @include for-mobile {
    max-width: inherit;
    width: inherit;
  }
}
