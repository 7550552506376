@import "Assets/styles/Global/_variables";

.vg-app-scope-content {
  @include full-content-size;
  form {
    @include full-content-size;
    @include flex($direction: column, $align: flex-start, $justify: space-between);

    .field-group {
      @include full-content-width;
    }
  }

  a {
    color: $vg-purple-main;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-medium;
    line-height: 0.7rem;
    text-decoration: none;
  }

  .app-scope-selection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    gap: 5%;
    margin-top: 2.5rem;

    .div-button-container {
      padding: 1.5rem;
      border: 1px solid $vg-gray-5;
      border-radius: 4px;
      transition: all 0.2s;

      &.selected {
        border: 1px solid $vg-aqua-dark;
      }

      p {
        font-size: 0.75rem;
        color: $vg-gray-3;
        margin-top: 0.5rem;
      }

      .vg-icon {
        width: auto;
        height: 0.8rem;
      }

      &:hover {
        border: 1px solid $vg-aqua-dark;
      }
    }
  }
}
