@import "Assets/styles/Global/_variables";

.send-referral-modal-content {
  @include flex($direction: column);
  width: 100%;

  h1 {
    color: $vg-gray-2;
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  img {
    height: 5rem;
  }

  .referral-template {
    @include flex($direction: row);
    margin: 1rem 0;
    font-size: 0.9rem;
    text-align: center;
    color: rgba($vg-gray-3, 0.8);

    p {
      white-space: break-spaces;
    }

    .vg-icon {
      height: 2rem;
    }
  }

  .referral-email-input {
    width: 100%;
  }
}
