@import "Assets/styles/Global/_variables.scss";

.box-input-wrapper {
  @include flex($direction: row, $align: flex-start, $justify: center);
  box-sizing: border-box;
  .vg-input {
    .input-wrapper {
      margin: 0;
      input {
        padding: 0.3rem 0.6rem 0.3rem;
        border: $border-gray-6;
        border-right: 0;
        border-radius: 5px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .vg-icon {
    height: 2.7rem;
    min-width: 2.7rem;
    background-color: $vg-purple-main;
    border-radius: 2px;
    padding: 0.6rem;
    box-sizing: border-box;
    margin-left: 1.3rem;
  }
  &.purple-light .vg-icon {
    background-color: $vg-purple-light;
  }
  &.dark-navy .vg-icon {
    background-color: $vg-dark-navy;
  }

  &.gray .vg-icon path {
    stroke: $vg-gray-3;
  }

  &.gray .vg-icon {
    background-color: white;
  }

  &.white .vg-icon {
    background-color: white;
    stroke: $vg-gray-2;
    border-top: 1px solid $vg-gray-6;
    border-bottom: 1px solid $vg-gray-6;
    border-right: 1px solid $vg-gray-6;
  }

  &.white .vg-icon path {
    stroke: $vg-gray-3;
  }

  &.white .vg-input {
    input {
      background-color: $vg-white;
    }
  }
  .vg-button {
    align-self: auto !important;
    margin-left: 0.5rem;
    width: 12rem !important;
  }
}
