@import "Assets/styles/Global/_variables.scss";

.Toastify__toast-container--bottom-center {
  @include for-mobile {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

.toast-notification-content {
  @include flex($direction: column, $justify: center, $align: center);
  padding: 0.2rem 2rem;
  gap: 0.2rem;

  text-align: center;
  width: max-content;

  p {
    font-size: 0.7rem;
    font-weight: $vg-font-weight-bold;
    color: $vg-gray-2;
  }

  p:nth-child(2) {
    font-size: 0.7rem;
    font-weight: $vg-font-weight-regular;
    white-space: pre-wrap;
  }
}
