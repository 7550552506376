@import "Assets/styles/Global/_variables.scss";

.team-payment-container {
  @include full-content-size;
  @include flex($direction: column, $justify: space-between, $align: flex-start);
  .payment-informations {
    @include flex($direction: row, $justify: center, $align: flex-start);
    width: 100%;
    margin-top: 2rem;
    font-size: $vg-font-size-small;
    flex-shrink: 0;
    .information-section {
      flex: 0.4 1;
      min-width: 300px;
      min-height: 200px;
      max-width: 400px;
      margin: 1rem 2rem;
      @include for-mobile {
        min-height: auto;
      }
    }
    .payment-card-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .payment-method-information {
      h2 {
        font-size: 1rem;
        line-height: 2rem;
        padding-bottom: 1rem;
      }
      .vg-radio-input {
        margin-bottom: 3rem;
      }
    }
    .billing-info {
      margin: 1rem 0 0 2rem;
      min-width: 300px;
      max-width: 400px;
    }
    .summary {
      h2 {
        font-size: $vg-font-size-large;
      }
      .stripe-info {
        text-align: right;
        height: 3rem;
        margin-bottom: 1rem;
        .vg-icon {
          height: 1.5rem;
        }
      }
      .payment-details {
        .payment-line-info {
          font-size: 0.7rem;
        }

        table {
          margin-bottom: 1rem;
          thead {
            border: none;
            th {
              font-size: $vg-font-size-large;
            }
            &.sub-header {
              tr {
                th {
                  font-size: 1rem;
                  line-height: 2rem;
                }
              }
            }
          }
        }
      }
    }

    .referral-box {
      @include flex($direction: row, $justify: center);
      color: $vg-gray-3;
      font-size: 0.9rem;

      img {
        height: 2rem;
        padding: 1rem;
      }
    }

    @include for-mobile {
      flex-direction: column;
      align-items: center;
      .payment-summary {
        margin-top: 2rem;
      }
      .payment-information-section {
        margin: 2rem;
      }
    }
    .alternative-payments {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      font-size: 0.8rem;
      margin: 0 2rem;
      margin-bottom: 1rem;

      .pay-with-balance {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1.5px solid $vg-gray-5;
        border-radius: 3px;
        width: 300px;
        padding: 1rem 0.8rem;
        font-size: 0.75rem;
        cursor: pointer;

        p {
          margin-right: 3rem;
        }

        &:hover {
          box-shadow: $light-shadow;
        }
        p1 {
          font-size: 0.9rem;
        }
        img {
          width: 2rem;
        }
        &.selected {
          border-color: $vg-purple-main;
        }
      }
    }
  }
  .payment-button-container {
    display: flex;
    color: $vg-baby-powder;
    .arrow-left-small path {
      fill: $vg-baby-powder;
    }
    .vg-icon {
      height: 15px;
      width: auto;
    }
  }
}

.billing-info-text {
  font-size: 0.8rem;
  margin: 0.6rem 0;
  color: $vg-gray-3;
  padding-right: 2rem;
  span {
    cursor: pointer;
    color: $vg-purple-main;
  }
}
