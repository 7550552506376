@import "Assets/styles/Global/_variables.scss";

.computer-space-container {
  height: 100px;

  p {
    font-size: 0.9rem;
    color: $vg-gray-3;
  }
  .vg-icon {
    height: 1.5rem;
  }

  .computer-space {
    margin-bottom: 1rem;
    position: relative;
    height: 15px;

    .computer-space-bar {
      position: absolute;
      border-radius: 5px;
      height: 20px;

      &.base {
        background-color: $vg-aqua-light;
        transition: width 1s;
        border-radius: 5px 0 0 5px;
      }
      &.empty {
        color: $dark;
        width: 100%;
        background-color: $vg-gray-6;
      }
      &.used {
        background-color: $vg-aqua-light;
        transition: width 1s;
        border-radius: 5px 0 0 5px;
      }
      &.full {
        border-radius: 5px;
      }
    }
  }

  @include for-mobile {
    height: 70px;
    p {
      font-size: 0.8rem;
    }
  }

  .available-space {
    @include flex($direction: row, $justify: flex-end);
    line-height: 2rem;
  }

  .error {
    text-align: end;
  }
}
