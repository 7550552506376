@import "Assets/styles/Global/_variables.scss";

.purpose {
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  box-sizing: border-box;
  padding: 1rem 2rem;
  border-radius: 3px;
  border: 2px solid $vg-gray-6;
  font-size: 1.3rem;
  font-weight: $vg-font-weight-light;
  line-height: 1.6rem;
  min-width: 310px;
  width: 28%;
  min-height: 180px;
  height: 25vh;
  margin: 1rem 0.5rem;
  outline: none;
  white-space: pre-wrap;
  cursor: pointer;

  @include for-mobile {
    min-width: 300px;
    width: 80%;
    max-height: 150px;
  }

  &:focus,
  &:hover {
    opacity: 1;
    box-shadow: $light-shadow;
  }

  &.selected {
    opacity: 1;
    box-shadow: $light-shadow;
    border: 2px solid $vg-aqua-dark;
  }

  p {
    font-weight: $vg-font-weight-light;
  }

  img {
    width: auto;
    height: 100%;
  }
}
