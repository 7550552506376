@import "Assets/styles/Global/_variables.scss";

.feedback-modal {
  .feedback-input-label {
    font-size: 0.9rem;
  }

  .vg-dropdown {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 0.9rem;
  }
}
