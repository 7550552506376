@import "Assets/styles/Global/_variables";

.credit-card {
  width: 8.5rem;
  height: 10rem;
  padding: 1rem 0.8rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background-image: url("../../Assets/images/CreditCardBG.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  position: relative;
  color: $vg-white;
  &.selected {
    opacity: 1;
  }

  .deletable {
    opacity: 0;
    position: absolute;
    top: 0.35rem;
    right: 0.35rem;
    z-index: 10;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  &:hover {
    .deletable {
      opacity: 1;
    }
  }

  .credit-card-wrapper {
    @include full-content-size();
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    .provider-icon {
      margin-bottom: auto;
    }
    .preferred {
      margin-top: auto;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.625rem;
    }
    .credit-card-placeholder {
      font-size: 0.625rem;
      opacity: 0.5;
    }
    .card-owner-name {
      font-size: 0.8rem;
      font-weight: $vg-font-weight-regular;
      margin-bottom: 0.5rem;
      overflow-wrap: anywhere;
    }
    .credit-card-number {
      @include flex($direction: row, $align: center, $justify: flex-start);
      margin-top: 0.35rem;
      .credit-card-number-square {
        height: 5px;
        width: 5px;
        background-color: $vg-white;
        margin-right: 5px;
      }
      .credit-card-digits {
        font-size: 0.8rem;
        margin-left: 7px;
      }
    }
  }

  &.big {
    width: 12rem;
    height: 14rem;
    padding: 1.1rem 1.1rem 1.2rem 1.1rem;

    .deletable {
      display: none;
    }
    .changable {
      position: absolute;
      top: 1rem;
      right: 0.8rem;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-medium;
      cursor: pointer;
    }

    .credit-card-wrapper {
      .provider-icon {
        width: 4.5rem;
        margin-bottom: auto;
      }
      .card-owner-name {
        font-size: 0.9rem;
      }
      .credit-card-number {
        @include flex($direction: row, $align: center, $justify: flex-start);
        margin-top: 0;
        .credit-card-number-square {
          height: 7px;
          width: 7px;
          background-color: $vg-white;
          margin-right: 7px;
        }
        .credit-card-digits {
          font-size: 1.1rem;
          margin-left: 0.6rem;
        }
      }
    }
  }

  &.opaque {
    opacity: 0.5;
  }

  &.preferable {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}
