@import "Assets/styles/Global/_variables";

.vg-forget-password-content,
.vg-forget-password-reset-content {
  @include full-content-size;

  form {
    @include full-content-size;
    @include flex($direction: column, $align: flex-start, $justify: space-between);

    .field-group {
      @include full-content-width;
      height: 150px;
      .vg-animation {
        height: 5rem;
        margin-top: -100px;
        margin-bottom: 1rem;
      }

      .description {
        color: $vg-black;
        font-weight: $vg-font-weight-medium;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
      }
    }

    .form-description {
      color: $vg-gray-3;
      font-size: 0.7rem;
      font-weight: $vg-font-weight-light;
      line-height: 2;
    }

    .vg-authentication-header {
      .description {
        .vg-animation {
          @include for-mobile {
            margin: 2rem auto;
          }
        }
      }
    }
  }

  a {
    color: $vg-gray-3;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-medium;
    line-height: 0.7rem;
    text-decoration: none;
  }
}
