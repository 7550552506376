@import "Assets/styles/Global/_variables";

.file-status {
  @include full-content-width;
  margin: 1rem 0;
  box-shadow: $light-shadow;

  &:nth-of-type(1) {
    margin: 0.1rem;
  }

  &.complete {
    // background-color: $soft-blue-baby;
  }

  &.block {
    border-radius: 4px;
    box-shadow: none;
    background-color: $vg-white;
  }

  .vg-icon {
    height: 1.5rem;
  }
  .file-upload {
    height: 2rem;
  }

  .v-tooltip {
    .tooltip-content {
      max-width: 12.5rem;
    }
  }

  .file-status-content {
    @include flex($direction: row, $align: center, $justify: space-between);
    padding: 0.5rem 1rem;

    .progress-bar-container {
      margin-right: auto;
      width: 60%;
      height: 8px;
      border-radius: 4px;
      background-color: $vg-gray-6;
      .progress-bar {
        border-radius: 4px;
        position: relative;
        height: 8px;
        background-color: $vg-purple-main;
      }
    }
  }

  .file-name {
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
    margin-left: 2rem;
    margin-right: auto;
    font-size: $vg-font-size-small;
    span {
      font-size: $vg-font-size-tiny;
      color: $vg-gray-4;
    }
  }
}
