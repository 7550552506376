@import "Assets/styles/Global/_variables";

.user-cell {
  display: flex;
  align-items: center;
  padding: 0.1rem 0;

  .profile-image {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 0.75rem;
    background-color: #47c2b533;
    border-radius: 100%;
    text-align: center;
    color: $vg-aqua-dark;
    font-weight: $vg-font-weight-semi-bold;
    @include flex($align: center, $justify: center);
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 0.75rem;
    border-radius: 100px;
    object-fit: cover;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.6;
    .user-name {
      font-size: 0.85rem;
      color: $vg-gray-1;
      display: flex;
      gap: 0.25rem;
      align-items: flex-end;
      span {
        font-size: $vg-font-size-tiny;
        font-weight: $vg-font-weight-semi-bold;
        color: $vg-aqua-dark;
      }
    }
    .user-email {
      font-size: 0.75rem;
      color: $vg-gray-3;

      &.fade {
        opacity: 40%;
      }
    }
  }
}
