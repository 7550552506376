@import "Assets/styles/Global/_variables";

.vg-register-content {
  @include full-content-size;
  min-height: 650px;

  form,
  .vg-register-success-wrapper {
    @include full-content-size;
    @include flex($direction: column, $align: flex-start, $justify: space-between);
    min-height: 35rem;

    .field-group {
      @include full-content-width;
      min-height: 22rem;

      .vg-animation {
        height: 5rem;
        margin-bottom: 1rem;
      }

      .description {
        color: $vg-black;
        font-weight: $vg-font-weight-medium;
        margin-bottom: 0.5rem;
      }

      .vg-register-success-content {
        color: $vg-gray-1;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: $vg-font-weight-regular;
      }

      .vg-register-success-email {
        font-weight: $vg-font-weight-semi-bold;
      }
    }
  }

  .hr-text {
    width: 100%;
    border: 0;
    position: relative;
    text-align: center;
    margin-top: 1rem;
    font-size: $vg-font-size-tiny;

    &:before {
      content: "";
      background: $vg-gray-5;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &::after {
      background-color: $vg-white;
      color: $vg-gray-3;
      content: attr(data-content);
      padding: 0 10px;
      position: relative;
    }
  }

  a {
    color: $vg-purple-main;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-medium;
    line-height: 0.7rem;
    text-decoration: none;
  }

  .vg-text-button {
    font-size: 0.8rem;
  }

  .divider {
    @include full-content-width;
    height: 1px;
    background-color: $vg-gray-5;
  }

  .account-type {
    @include flex($direction: row, $align: center, $justify: center);
    position: relative;
    margin-bottom: 1rem;

    .divider {
      height: 1.5rem;
      width: 0;
      margin: 0 1rem;
      border: 1px solid $vg-gray-5;
    }

    .vg-text-button {
      padding: 1rem;
      font-size: 1rem;
      color: $vg-black;
      opacity: 0.4;
      &.active {
        opacity: 1;
      }
    }

    .account-type-bar {
      position: absolute;
      transition: all 0.1s ease-out;
      left: calc(50% - 6rem);
      bottom: 0.5rem;
      width: 4rem;
      border: 1px solid $vg-aqua-light;
    }

    &.team .account-type-bar {
      left: calc(50% + 3rem);
    }
  }

  .vg-terms-and-conditions,
  .vg-register-success-bottom {
    display: flex;
    margin-top: 0.7rem;
    text-align: left;

    .vg-terms-and-conditions-content,
    .vg-register-success-bottom-content {
      margin-right: 0.2rem;
      line-height: 1rem;
      font-size: 0.7rem;
      font-weight: $vg-font-weight-regular;

      a {
        text-decoration: none;
        font-weight: $vg-font-weight-bold;
        color: $vg-gray-3;
      }

      .vg-register-success-resend {
        color: $vg-purple-main;
      }
    }
  }
}
