@import "Assets/styles/Global/_variables.scss";

.payment-container {
  @include full-content-size;
  @include flex($direction: column, $justify: space-between, $align: flex-start);
  .payment-informations {
    @include flex($direction: row, $justify: center, $align: flex-start);
    width: 100%;
    margin-top: 2rem;
    font-size: $vg-font-size-small;
    flex-shrink: 0;

    .payment-options {
      @include flex($direction: column);
      .alternative-payments {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 150px;
        font-size: 0.8rem;
        margin: 1rem 2rem;

        .pay-with-balance {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: 1.5px solid $vg-gray-5;
          border-radius: 3px;
          width: 300px;
          padding: 1rem 0.8rem;
          font-size: 0.75rem;
          cursor: pointer;

          p {
            margin-right: 3rem;
          }

          &:hover {
            box-shadow: $light-shadow;
          }
          p1 {
            font-size: 0.9rem;
          }
          img {
            width: 2rem;
          }
          &.selected {
            border-color: $vg-purple-main;
          }
        }
        .pay-with-paypal {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: solid 1px $vg-blue-paypal;
          margin-right: 4rem;
          border-radius: 3px;
          width: 300px;
          padding: 0.5rem;
          opacity: 0.3;
          cursor: pointer;

          p {
            font-size: 0.75rem;
            color: $vg-blue-paypal;
          }
          img {
            margin-left: 1rem;
          }
          &.selected {
            opacity: 1;
          }
        }
      }
    }

    .information-section {
      flex: 0.4 1;
      min-width: 300px;
      min-height: 200px;
      max-width: 400px;
      margin: 1rem 2rem;
      @include for-mobile {
        min-height: auto;
      }
    }
    .payment-method-information {
      h2 {
        font-size: $vg-font-size-large;
        margin-bottom: 1rem;
      }
      .vg-radio-input {
        margin-bottom: 3rem;
      }
    }
    .summary {
      .stripe-info {
        text-align: right;
        height: 3rem;
        margin-bottom: 1rem;
        .vg-icon {
          height: 1.5rem;
        }
      }
      .payment-details {
        table {
          margin-bottom: 1rem;
          thead {
            border: none;
            th {
              font-size: $vg-font-size-large;
            }
          }
        }
      }
    }

    .referral-box {
      @include flex($direction: row, $justify: center);
      color: $vg-gray-3;
      font-size: 0.9rem;

      img {
        height: 2rem;
        padding: 1rem;
      }
    }

    @include for-mobile {
      flex-direction: column;
      align-items: center;
      .payment-summary {
        margin-top: 2rem;
      }
      .payment-information-section {
        margin: 2rem;
      }
    }
  }
}
