@import "Assets/styles/Global/_variables.scss";

.authentication-layout {
  @include full-viewport-height;
  @include flex($direction: row, $justify: flex-start, $align: flex-start);

  background-color: $white;

  .side-image-container {
    @include full-content-height;
    @include custom-content-width(50%);

    font-size: 0.71rem;
    position: relative;
    overflow: hidden;

    @include for-mobile {
      display: none;
    }

    .side-image {
      @include full-content-size;

      object-fit: cover;
    }

    .side-image-title {
      bottom: 3.5%;
      color: $white;
      position: absolute;
      right: 5%;
      text-align: right;
    }

    .author-title {
      font-weight: 500;
    }
    &.app-streaming {
      @include flex($direction: column, $align: center, $justify: space-between);
      @include for-mobile {
        display: none;
      }
      background: linear-gradient(146.43deg, #060408 0%, #26055c 79.58%);
      white-space: break-spaces;
      h1 {
        color: $vg-white;
        text-align: center;
        font-size: $vg-font-size-huge;
        line-height: 2.5rem;
        letter-spacing: 2px;
        margin-top: 4.5rem;
        margin-bottom: 3.5rem;
        span {
          font-size: 3.5rem;
          font-weight: $vg-font-weight-bold;
        }
      }
      .side-image {
        height: auto;
        margin: auto;
      }
    }
  }

  .content {
    @include full-viewport-height;
    @include full-content-width;
    overflow: auto;
    position: relative;
    flex: 1;

    .back-button {
      position: absolute;
      top: 2rem;
      left: 3rem;
    }

    .form-container {
      box-sizing: border-box;
      padding: 12vh 13.25vw 18vh;

      @include for-mobile {
        padding: 6vh 11vw 9vh;
      }
    }
  }
}
