@import "Assets/styles/Global/_variables.scss";

.divider-container {
  width: 100%;
  height: 3px;
  margin: 3rem 0;
  .line-wrapper {
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    height: 0;

    .divider-line {
      height: 1px;
      background-color: $vg-gray-5;
    }

    &.with-logo {
      @include flex($direction: row, $justify: space-between, $align: center);
      .divider-line {
        width: 50%;
      }
      .divider-logo {
        @include flex($direction: row, $align: center);
        z-index: 1;
        background-color: $vg-white;
        width: 20%;
        min-width: 4rem;
        text-align: center;
        .vg-icon {
          width: 4rem;
          height: auto;
        }
      }
      @include for-mobile {
        .divider-line {
          width: 30%;
        }
        .divider-logo {
          width: 40%;
        }
      }
    }
  }
}
