@import "Assets/styles/Global/_variables.scss";

.vg-dropdown {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;

  &.underlined {
    padding-bottom: 0.2rem;
    border-bottom: 1px solid $vg-gray-5;
  }

  &.box {
    border: 1.5px solid $vg-gray-5;
    border-radius: 2px;

    span {
      padding: 0.4rem 0.5rem;
    }

    .vg-icon {
      margin-right: 1rem;
      opacity: 0.5;
    }
  }
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }

  &.onboarding {
    opacity: 1;
  }

  .placeholder,
  .selected {
    @include flex($direction: row, $justify: space-between, $align: center);
    width: 100%;
    background-color: transparent;
    border: 0;
    min-width: 5rem;
    outline: none;
    padding-right: 0.7rem;

    @include for-mobile {
      padding-right: 0rem;
    }

    span {
      margin: 0 !important;
    }

    .vg-icon {
      width: 0.7rem;
    }

    .placeholder {
      color: $vg-gray-3;
      font-size: 0.9rem;
    }
  }

  .content {
    background-color: $vg-white;
    border-radius: 0 0 $vg-border-radius-small $vg-border-radius-small;
    box-shadow: $light-shadow;
    margin-top: 0.8rem;
    max-height: 12rem;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 9;

    .dropdown-item {
      color: $vg-gray-3;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-light;
      margin: 0.5rem;
      box-sizing: border-box;
      padding: 0.5rem;

      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: $vg-border-radius-small;
        border-bottom-right-radius: $vg-border-radius-small;
      }

      &:hover {
        background-color: $vg-gray-7;
      }

      &.disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
    &.editable {
      margin: 0;
      width: 100%;
    }
  }

  .vg-input {
    .input-wrapper {
      margin: 0;
    }
  }

  &.reverse {
    .content {
      margin-top: -2rem;
      transform: translateY(-100%);
    }
  }
}
