@import "Assets/styles/Global/_variables.scss";

.pixelstreaming-container {
  #playerUI {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  canvas {
    image-rendering: crisp-edges;
    position: absolute;
  }

  video {
    position: absolute;
    width: 100% !important; 
    height: 100% !important;
    overflow: hidden;
  }

  #playerPixel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    background-color: #000;
  }

  #videoPlayOverlay {
    position: absolute;
    font-size: 1.8em;
    width: 100%;
    height: 100%;
    color: white;
  }

  .play-button-container {
    @include flex($direction: column, $align: center, $justify: center);
    min-width: 300px;
    gap: 0.4rem;
    margin-top: -1rem;

    p {
      color: $vg-aqua-dark;
      font-size: $vg-font-size-small;
      font-weight: $vg-font-weight-medium;
      margin: 1rem 0;
    }
    p:nth-of-type(2) {
      color: $vg-aqua-dark;
      font-size: $vg-font-size-medium;
      font-weight: $vg-font-weight-semi-bold;
      margin: 1rem 0;
    }
  }

  /* State for element to be clickable */
  .clickableState {
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }

  /* State for element to show text, this is for informational use*/
  .textDisplayState {
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }

  /* State to hide overlay, WebRTC communication is in progress and or is playing */
  .hiddenState {
    display: none;
  }

  #playButton {
    display: inline-block;
    height: auto;
    z-index: 30;
  }

  img#playButton {
    max-width: 241px;
    width: 10%;
  }

  #freezeFrameOverlay {
    background-color: transparent;
  }

  .freezeframeBackground {
    background-color: #000 !important;
  }

  #overlay {
    width: 100%;
    height: 100%;
    z-index: 20;
    position: absolute;
    color: var(--colour2);
    pointer-events: none;
    overflow: hidden;
  }

  #overlay button {
    background-color: var(--colour7);
    border: 1px solid var(--colour7);
    color: var(--colour2);
    position: relative;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    text-align: center;
  }

  #fullscreen-btn {
    padding: 0.6rem !important;
  }

  #overlay button:hover {
    background-color: var(--colour3);
    border: 3px solid var(--colour3);
    transition: 0.25s ease;
    padding-left: 0.55rem;
    padding-top: 0.55rem;
  }

  #overlay button:active {
    border: 3px solid var(--colour3);
    background-color: var(--colour7);
    padding-left: 0.55rem;
    padding-top: 0.55rem;
  }

  #overlay img {
    width: 100%;
    height: 100%;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    color: var(--colour2);
    text-align: center;
    border-radius: 15px;
    padding: 0px 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 0.75rem;
    letter-spacing: 0.75px;
    /* Position the tooltip */
    position: absolute;
    top: 0;
    transform: translateY(25%);
    left: 125%;
    z-index: 20;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    background-color: var(--colour7);
  }

  #connection .tooltiptext {
    top: 125%;
    transform: translateX(-25%);
    left: 0;
    z-index: 20;
    padding: 5px 10px;
  }

  #settings-panel .tooltiptext {
    display: block;
    top: 125%;
    transform: translateX(-50%);
    left: 0;
    z-index: 20;
    padding: 5px 10px;
    border: 3px solid var(--colour5);
    width: max-content;
  }

  #controls {
    position: absolute;
    top: 2%;
    left: 1%;
    font-family: "Michroma", sans-serif;
    pointer-events: all;
    display: block;
  }

  #controls > * {
    margin-bottom: 0.5rem;
    border-radius: 50%;
    display: block;
    height: 2rem;
    line-height: 1.75rem;
    padding: 0.5rem;
  }

  #controls #additionalinfo {
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }

  #unrealengine {
    position: absolute;
    bottom: 5%;
    right: 10%;
    font-family: "Michroma", sans-serif;
    pointer-events: all;
    width: min-content;
  }

  #unrealengine p {
    visibility: hidden;
    width: 15rem;
  }

  #connection {
    position: absolute;
    bottom: 5%;
    left: 10%;
    font-family: "Michroma", sans-serif;
    height: 3rem;
    width: 3rem;
    pointer-events: all;
  }

  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
  }

  .panel-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    min-width: 20vw;
    transform: translateX(100%);
    transition: 0.3s ease-out;
    pointer-events: all;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(30, 29, 34, 0.5);
  }

  .panel-wrap-visible {
    transform: translateX(0%);
  }

  .panel {
    color: #eee;
    overflow-y: auto;
    padding: 1em;
  }

  #heading {
    display: inline-block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    position: relative;
    padding: 0 0 0 2rem;
  }

  #close {
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    font-size: 2em;
    float: right;
  }

  #close:after {
    padding-left: 0.5rem;
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
  }

  #close:hover {
    color: var(--colour3);
    transition: ease 0.3s;
  }

  #content {
    margin: 2rem;
  }

  .setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0.5rem 0;
  }

  .settings-text {
    margin-right: 2rem;
    display: flex;
  }

  /*** Toggle Switch styles ***/
  .tgl-switch {
    vertical-align: middle;
    display: inline-block;
  }

  .tgl-switch .tgl {
    display: none;
  }

  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-slider {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .tgl::-moz-selection,
  .tgl:after::-moz-selection,
  .tgl:before::-moz-selection,
  .tgl *::-moz-selection,
  .tgl *:after::-moz-selection,
  .tgl *:before::-moz-selection,
  .tgl + .tgl-slider::-moz-selection {
    background: none;
  }

  .tgl::selection,
  .tgl:after::selection,
  .tgl:before::selection,
  .tgl *::selection,
  .tgl *:after::selection,
  .tgl *:before::selection,
  .tgl + .tgl-slider::selection {
    background: none;
  }

  .tgl + .tgl-slider {
    outline: 0;
    display: block;
    width: 40px;
    height: 18px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .tgl + .tgl-slider:after,
  .tgl + .tgl-slider:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }

  .tgl + .tgl-slider:after {
    left: 0;
  }

  .tgl + .tgl-slider:before {
    display: none;
  }

  .tgl-flat + .tgl-slider {
    padding: 2px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background: var(--colour6);
    border: 3px solid var(--colour7);
    border-radius: 2em;
  }

  .tgl-flat + .tgl-slider:after {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background: var(--colour7);
    content: "";
    border-radius: 1em;
  }

  .tgl-flat:checked + .tgl-slider {
    border: 3px solid var(--colour3);
  }

  .tgl-flat:checked + .tgl-slider:after {
    left: 50%;
    background: var(--colour3);
  }

  .subtitle-text {
    margin: 0 0 0 1rem;
    color: var(--colour5);
    position: relative;
  }

  .form-group {
    padding-top: 4px;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 4px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .form-group label {
    color: var(--colour2);
    vertical-align: middle;
    font-weight: normal;
  }

  #stats {
    margin-left: 1rem;
  }

  #LatencyStats {
    margin-left: 1rem;
  }

  #hiddenInput {
    position: absolute;
    left: -10%;
    /* Although invisible, push off-screen to prevent user interaction. */
    width: 0px;
    opacity: 0;
  }

  #editTextButton {
    position: absolute;
    height: 40px;
    width: 40px;
  }

  .form-group label {
    margin-right: 2rem;
    min-width: 75%;
  }

  input {
    text-align: right;
  }

  .warning {
    box-sizing: border-box;
    position: relative;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 40px;
    display: none;
  }

  .warning::after,
  .warning::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    background: currentColor;
    left: 7px;
  }

  .warning::after {
    top: 2px;
    height: 8px;
  }

  .warning::before {
    height: 2px;
    bottom: 2px;
  }

  /* Flat buttons */
  input[type="button"] {
    background-color: transparent;
    color: var(--colour2);
    font-family: "Montserrat";
    border: 3px solid var(--colour3);
    border-radius: 1rem;
    font-size: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  input[type="button"]:hover {
    background-color: var(--colour3);
    transition: ease 0.3s;
  }

  input[type="button"]:active {
    background-color: transparent;
  }

  #encoder-params-submit,
  #webrtc-params-submit {
    text-align: center;
  }

  select,
  input[type="number"] {
    background-color: var(--colour7);
    color: var(--colour2);
    border: 1px solid var(--colour6);
    padding: 0.25rem;
    font-family: "Montserrat";
    border-radius: 0.25rem;
  }

  input[type="number"]::-webkit-inner-spin-button {
    margin-left: 0.5rem;
  }

  input[type="number"]:disabled {
    padding-right: 0.5rem;
    appearance: textfield;
  }

  input[type="number"]:disabled::-webkit-inner-spin-button {
    display: none;
  }

  #settingsBtn,
  #statsBtn {
    cursor: pointer;
  }

  #streamingVideo {
    pointer-events: all;
    object-fit: fill;
  }

  embed {
    border: none;
    width: 100%;
    height: 100%;
  }

  g {
    fill: var(--colour2);
  }

  object {
    pointer-events: none;
  }

  #connectionStrength {
    fill: var(--colour7);
  }

  #minimize {
    display: none;
  }

  #afkOverlay {
    z-index: 999;
    background-color: rgba(30, 29, 34, 0.5);
    display: inline-block;
    height: 100vh;
    width: 100vw;
    line-height: 100vh;
    text-align: center;
    overflow: hidden;
  }

  #afkOverlay center {
    display: inline-block;
    line-height: 1.5;
    height: 100vh;
  }
}
