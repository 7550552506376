@import "Assets/styles/Global/_variables.scss";

.workstation-iframe-container {
  @include full-viewport-size();
  @include for-mobile {
    height: 100vh;
  }
  background-color: rgba(170, 170, 170, 0.2);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  .workstation-iframe-modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    background-color: $vg-white;
    padding: 2rem 4rem;
    box-sizing: border-box;
    .workstation-iframe {
      width: 100%;
      height: 100%;
    }

    .top-right-icon {
      height: 1.25rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.25rem;
      cursor: pointer;
    }
  }
}
