@import "Assets/styles/Global/_variables.scss";

.not-found-container {
  @include flex($direction: row, $align: center, $justify: center);
  @include full-viewport-size;

  h1 {
    margin: 1rem 0 2rem;
    font-size: 5rem;
    font-weight: $vg-font-weight-light;
    color: $vg-gray-4;
  }
  .nothing-img {
    height: 30rem;
    margin-right: 2rem;
    @include for-mobile {
      display: none;
    }
  }
  .error-info-container {
    max-width: 20rem;
  }

  .logo {
    width: 6rem;
  }

  .something-went-wrong {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: $vg-purple-main;
  }
}
