@import "Assets/styles/Global/_variables.scss";

.input-box {
  p {
    white-space: nowrap !important;
  }
}

.file-storage-size {
  .selected {
    span {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    p {
      color: $vg-gray-3;
      display: inline;
      font-size: 0.7rem;
    }
  }

  .dropdown-item {
    span {
      white-space: nowrap;
      font-size: 0.6rem;
    }
    p {
      display: none;
    }
  }
}

.files-step-description {
  a {
    color: $vg-aqua-main;
    font-weight: 400;
  }
}

.configuration-option {
  .with-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
  }
}

.preload-files-button {
  margin-left: auto;
}

.preload-files-table {
  margin-top: -2rem;
  margin-bottom: 2rem;

  .table-body {
    max-height: 10rem;
    overflow-y: auto;

    .table-cell {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 1rem;
      }
    }
  }

  .actions-cell {
    .vg-icon {
      padding: 0 0.6rem 0;
      width: 1rem;
      height: 1rem;
    }
    .vg-progress-bar-container {
      background-color: $vg-gray-6;
      width: 3rem;
      margin: 0 0.5rem;
    }
    .vg-tooltip {
      right: 100%;
      top: -0.5rem;
      bottom: auto;
    }
    margin-left: auto;
    @include flex($direction: row, $align: center, $justify: center);

    .baby-blue {
      font-size: 0.65rem;
    }
  }
}
