@import "Assets/styles/Global/_variables";

.mobile-keyboard-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 3rem;
  width: 4rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  display: flex;
  justify-content: center;

  .vg-icon {
    height: 100%;
    width: 100%;
  }

  input {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
}
