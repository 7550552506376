@import "Assets/styles/Global/_variables";

.vg-slider {
  @include flex($direction: row);

  cursor: pointer;
  margin: 2rem 0;
  position: relative;
  width: fit-content;

  .slider-step {
    background-color: $vg-gray-5;
    height: 5px;
    margin: 0 0.5px;
    width: 2.7rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.fill {
      background-color: $vg-purple-main;
      cursor: auto;
      &.light {
        background-color: $vg-purple-light;
      }
    }
  }

  .slider-handle {
    background: $vg-purple-main;
    border-radius: 3px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
    height: 18px;
    position: absolute;
    width: 18px;
    &.light {
      background-color: $vg-purple-light;
    }
  }
}
