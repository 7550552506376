@import "Assets/styles/Global/_variables.scss";

.create-computers-container {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow-y: auto;

  background-color: $vg-gray-7;

  .dashboard-sub-screen-header {
    @include flex($direction: row, $justify: space-between, $align: flex-end);

    .header-left {
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      flex: 0.8;

      @include for-mobile {
        flex: 1;
      }

      .title {
        color: $vg-black;
        font-size: 1.5rem;
        font-weight: $vg-font-weight-medium;
        line-height: 2.5rem;
        margin-bottom: 0.25rem;
      }

      .description {
        color: $vg-gray-3;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-regular;
        line-height: 1rem;
      }
    }

    .header-right {
      @include flex($direction: column, $justify: flex-start, $align: flex-end);
      flex: 0.2;
      font-size: 1.5rem;
      white-space: nowrap;

      @include for-mobile {
        display: none;
      }

      .balance-title {
        color: $vg-black;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-regular;
        line-height: 1rem;
        margin-bottom: 0.25rem;
      }

      .balance-amount {
        color: $vg-black;

        text-align: end;
        font-weight: $vg-font-weight-medium;
        line-height: 1.5rem;
      }
    }

    .close-icon {
      height: 2rem;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      width: 2rem;

      @include for-mobile {
        display: none;
      }
    }
  }
  .teams-choose-plan-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 8rem;
    @include for-mobile {
      padding: unset;
    }
    .start-options-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin: 1rem;

      @include for-mobile {
        width: unset;
      }

      .start-options-buttons {
        display: flex;
        justify-content: space-between;
        min-width: 14rem;
        .button-container {
          display: flex;
          align-items: center;
          gap: 2px;
          padding: 0rem 0.5rem;
          background: white;
          border-radius: 5px;
          justify-content: center;
          cursor: pointer;
          p {
            font-size: 0.7rem;
            font-weight: $vg-font-weight-semi-bold;
            padding: 0.4rem 0;
          }
          color: $vg-gray-2;
          .button-icon {
            margin-right: 0.2rem;
          }
          border: 1px solid $vg-white;
          &.selected {
            border: 1px solid $vg-aqua-light;
            color: $vg-aqua-dark;
            .button-icon {
              * {
                fill: $vg-aqua-light;
              }
            }
          }
        }
      }
    }

    .plan-table-container {
      overflow-y: auto;
      max-height: calc(50vh + 2rem);
      padding-right: 1rem;
      @include for-mobile {
        padding: 0 1.5rem 2rem;
      }
    }
  }

  .page-button {
    padding: 0 8rem;
  }
  .user-manager-actions-bar {
    @include flex($direction: row, $justify: flex-end, $align: center);
    @include full-content-width();

    .search-input {
      margin: 1rem 0;

      height: 2.5rem;
      width: 20rem;

      input {
        padding-left: 1rem;
        background-color: $vg-white;
        height: 2rem;
        line-height: 2rem;
      }
      .vg-icon {
        width: 2.5rem;
        * {
          fill: unset;
        }
      }
    }

    .user-manager-left-actions {
      @include flex($direction: row, $justify: flex-start, $align: center);
      .vg-tooltip-hoverable {
        cursor: pointer;
        margin-right: 1rem;
        .vg-tooltip {
          width: auto;
          white-space: nowrap;
        }
      }
      .left-action-button {
        @include flex;
        width: 4rem;
        height: 2.5rem;
        text-align: center;
        background-color: $vg-white;
        border: $border-gray-6;
        border-radius: 3px;
        .vg-icon {
          width: 1.8rem;
        }
        &:hover {
          background-color: $vg-gray-7;
        }
      }
    }
    .user-manager-right-actions {
      @include flex($direction: row, $justify: flex-end, $align: center);
      @include full-content-height();
      .vertical-divider {
        width: 1px;
        height: 1.4rem;
        background-color: $vg-gray-3;
        margin: 0px 0.5rem;
      }
      .vg-tooltip-hoverable {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 1rem;
        .selected-user-actions {
          width: 1.5rem;
          height: 1.4rem;
        }
        .vg-tooltip {
          width: auto;
          white-space: nowrap;
        }
      }
    }
  }
}
