@import "Assets/styles/Global/_variables.scss";

.app-bundle-modal {
  .app-bundle-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }

  .app-selection-table {
    width: 100%;
    .vg-expandable-table {
      padding: 0;
    }

    .table-body {
      max-height: 9rem;
      overflow-y: auto;
    }

    .header-cell {
      font-weight: $vg-font-weight-semi-bold;
      color: $vg-gray-1;
      font-size: $vg-font-size-small;
      line-height: 1.4;
    }

    .status {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100px;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    color: $vg-black;
  }
  h3 {
    font-weight: $vg-font-weight-semi-bold;
    color: $vg-gray-1;
    font-size: $vg-font-size-small;
    margin-bottom: 0.5rem;
  }
  .description {
    flex: 1;
    > p {
      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }

  .input-container {
    margin-top: 1rem;
    margin-bottom: auto;
    padding: 0.43rem;
  }

  .box-input-wrapper {
    .vg-input {
      .input-wrapper {
        input {
          border: $border-gray-5;
          box-sizing: border-box;
          border-radius: 2px;

          &::placeholder {
            opacity: 0.3;
          }
        }
      }
    }
  }
  .row {
    display: grid;
    grid-auto-rows: auto;
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    @include full-content-width;
  }
  .grid-2-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .key-mapping-options {
    @include full-content-width;
    @include flex($direction: column, $align: center, $justify: center);
    height: calc(100% - 2rem);
    .row {
      margin-bottom: 2rem;

      @include for-not-mobile {
        padding: 0 1rem;
        grid-column-gap: 3rem;
      }

      box-sizing: border-box;
    }
    .vg-radio-input .check {
      top: 5px;
    }
  }

  .key-mapping-option {
    border: $border-gray-6;
    border-radius: 6px;
    padding: 2rem 1rem 1rem;
    opacity: 0.8;

    &.selected {
      border-color: rgba($vg-aqua-dark, 0.4);
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
    h5 {
      color: $vg-black;
      font-weight: $vg-font-weight-regular;
    }
    p {
      color: $vg-gray-2;
      font-size: 0.7rem;
    }
  }

  .machine-type-cards-container {
    @include full-content-width;
    box-sizing: border-box;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    height: 14rem;
  }
  .pricing-info {
    color: $vg-gray-2;
    font-size: 0.65rem;
    width: 7rem;
    margin: 0.5rem 0.5rem 0;
    text-align: center;
    span {
      font-weight: $vg-font-weight-bold;
    }
  }
  .pricing-annotation {
    color: $vg-gray-3;
    font-size: 0.65rem;
    text-align: center;
    white-space: break-spaces;
    margin: 1rem 4rem 0;
    @include for-mobile {
      margin: 2rem auto;
    }
  }

  .vg-switch {
    gap: 1rem;
    flex-direction: row-reverse;

    &.checked {
      .switch {
        background-color: $vg-baby-powder;
      }
    }
  }

  .completion-step {
    @include flex($direction: column, $align: center, $justify: flex-start);
    text-align: center;
    padding-top: 2rem;
    img {
      height: 8rem;
      margin-bottom: 2rem;
    }
    h1,
    p {
      margin-bottom: 1rem;
      width: 70%;
    }
    p {
      &:last-of-type {
        color: $vg-gray-3;
      }
    }
  }
}
