@import "Assets/styles/Global/_variables";

.payment-methods {
  @include flex($direction: row, $justify: flex-start, $align: flex-start);
  gap: 1rem;
  .add-new-payment-method {
    @include flex($direction: column, $justify: center, $align: center);
    background-color: $vg-gray-4;
    border-radius: 0.25rem;
    box-sizing: border-box;
    cursor: pointer;
    height: 10rem;
    opacity: 0.3;
    padding: 1rem 0.8rem;
    width: 8.5rem;

    .vg-icon {
      height: 2.5rem;
      width: 2.5rem;
    }

    &:hover {
      background-color: $vg-gray-5;
    }
  }
}
