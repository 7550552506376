@import "Assets/styles/Global/_variables";

.vg-animation {
  @include flex();
  height: 100%;

  &.big {
    font-size: 2rem;
  }

  &.centered {
    @include full-content-width;
    @include flex;
    height: inherit;
  }

  .description {
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 0;
  }
}
