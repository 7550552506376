@import "Assets/styles/Global/_variables.scss";

.slide {
  width: 100%;
  overflow: hidden;

  .slide-content {
    transition: transform ease-in-out 0.2s;
    display: flex;

    .slide-page {
      min-width: 100%;
    }
  }
  .dots {
    @include flex($direction: row);
    margin-bottom: 1rem;
    .dot {
      width: 8px;
      height: 8px;
      margin: 5px;
      border-radius: 8px;
      background-color: $vg-gray-6;
      cursor: pointer;
      &[disabled] {
        cursor: default;
        background-color: $vg-purple-main;
      }
    }
  }
}
