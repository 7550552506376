@import "Assets/styles/Global/_variables.scss";

.vg-authentication-header {
  min-height: 7.5rem;

  .logo {
    height: 1rem;
  }

  .title {
    color: $vg-purple-main;
    font-size: 1.6rem;
    font-weight: $vg-font-weight-medium;
    line-height: 2rem;
    margin: 0.3rem 0;
    overflow-wrap: anywhere;
  }

  .description {
    color: $vg-black;
    font-size: 0.85rem;
    font-weight: $vg-font-weight-regular;
    line-height: 1.4rem;
    white-space: break-spaces;
    .description-action-text {
      color: $vg-purple-main;
      cursor: pointer;
    }
    .vg-animation {
      margin-top: 3rem;
    }
  }
}
