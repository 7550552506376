@import "Assets/styles/Global/_variables";

.vg-history-table {
  @include full-content-width();
  table {
    @include full-content-width();
    margin-bottom: 5rem;
    thead,
    tbody {
      tr {
        display: table;
        table-layout: fixed;
        text-align: left;
        width: 100%;
        th {
          font-size: 1rem;
          color: $vg-black;
          &:last-of-type {
            max-width: 6.5rem;
            width: 6.5rem;
          }
        }
        td {
          font-size: 0.9rem;
          color: $vg-gray-2;
          &:last-of-type {
            max-width: 6rem;
            width: 6rem;
          }
        }
      }
    }
    thead {
      tr {
        height: 2.5rem;
      }
    }
    tbody {
      overflow-y: auto;
      display: block;
      height: 60vh;
      &.session-scroll {
        height: 50vh;
      }
      &.payment-scroll {
        height: 25vh;
      }
      tr {
        height: 2.1rem;
        line-height: 2.1rem;
      }
    }
  }
  .selected-data-row {
    background-color: $vg-gray-6;
  }
}
