@import "Assets/styles/Global/_variables.scss";

$menu-item-size: 0.9rem;
$menu-balance-size: 1.5rem;
$menu-balance-label-size: 0.8rem;

.menu-widescreen-container {
  @include full-viewport-height;
  @include flex($direction: column, $justify: flex-start, $align: center);
  box-sizing: border-box;
  font-size: $menu-item-size;
  left: 0;
  overflow: auto;
  padding-top: 2rem;
  position: absolute;
  top: 0;
  width: $width-navbar-desktop;
  transition: 0.5s;

  @include for-mobile {
    display: none;
  }

  &.blue {
    background-color: $vg-dark-navy;
    color: white;
  }

  .vagon-icon {
    height: 3rem;
    width: 3rem;
  }

  hr {
    border: $border-gray-5;
    border-radius: 10px;
    margin: 1.5rem 0;
    width: 100%;
  }
}

.menu-mobile-container {
  display: none;

  @include for-mobile {
    @include full-content-width;
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    height: 4.5rem;
    background-color: $vg-white;
    left: 0;
    max-width: 100%;
    position: relative;
    top: 0;

    &.open {
      // @include full-content-size;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 99;
    }

    .menu-balance {
      width: 100%;
      padding: 0 7.5%;
      box-sizing: border-box;
      color: $vg-gray-2;
      font-size: $menu-balance-label-size;
      font-weight: 400;

      .divider {
        width: 100%;
      }

      p {
        font-size: $menu-balance-size;
        font-weight: 500;
        margin-top: 0.5rem;
      }
    }
  }

  .menu-mobile-header-row {
    display: none;
    @include for-mobile {
      @include flex($direction: row, $align: center, $justify: space-between);
      @include full-content-width;

      box-sizing: border-box;
      color: $vg-purple-main;
      font-size: 1.5rem;
      padding: 1rem 7.5%;

      .vg-icon {
        height: 2rem;
        width: 2rem;
      }

      i {
        outline: none;
      }
    }
  }
}

.menu-wrapper {
  @include flex($direction: column, $align: flex-start, $justify: flex-start);
  height: calc(100% - 6rem);
  width: 100%;
  margin-top: 2rem;
  align-items: center;
  white-space: nowrap;
  @include for-mobile {
    height: 80vh;
  }
  div {
    width: 100%;
  }
  .menu-item {
    box-sizing: border-box;
    color: $vg-gray-2;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: $vg-font-weight-medium;
    line-height: 3rem;
    position: relative;
    text-decoration: none;
    padding: 0 15%;
    &.dropdown {
      padding: 0 15%;
      > .vg-icon {
        &:first-of-type {
          position: absolute;
          left: 0.5rem;
          height: 0.8rem;
          width: 0.8rem;
        }
      }
    }

    p {
      color: inherit;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

    width: 100%;
    text-align: start;
    &.app-streaming-menu-item {
      @include flex($direction: row, $align: center, $justify: flex-start);
      color: $vg-gray-2;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      font-weight: 400;

      .vg-icon {
        color: $vg-gray-3;
      }
      &.active {
        color: $vg-purple-main;
        .vg-icon {
          color: $vg-purple-main;
        }
      }
      &.disabled {
        cursor: default;
      }
    }
    @include for-mobile {
      padding: 0 7.5%;
    }

    &.dark-navy {
      color: $vg-white;
      &:hover:not(.active) {
        background-color: $vg-light-navy;
      }

      &.always-active {
        &:hover {
          background-color: $vg-light-navy;
        }
      }

      &.active {
        color: $vg-baby-powder;
        cursor: default;
      }
    }

    &:hover:not(.active):not(.disabled):not(.dark-navy) {
      background-color: $vg-gray-7;
    }

    &.active {
      cursor: default;
    }

    &.new::after {
      content: "New";
      color: $vg-aqua-main;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0.2rem 0.6rem 0.1rem;
    }

    &.contactus::after {
      content: "Contact Us";
      color: $vg-baby-powder;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0.2rem 0.6rem 0.1rem;
    }
  }

  .vg-dropdown-trigger-button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vg-icon {
      margin-left: auto;
    }
  }
  .menu-item-icon {
    height: 1.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
  .menu-item-bottom {
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
    margin-top: auto;
    width: 100%;

    @include for-mobile {
      @include flex($direction: column, $align: flex-start, $justify: flex-start);
    }

    .menu-item {
      margin: 0.1rem 0;
      &.support {
        display: block;
        color: $vg-gray-2;
        margin: 0;
        font-size: 0.75rem;
        text-align: left;

        &.dark-navy {
          color: $vg-white;
        }
        &:hover {
          background-color: transparent;
        }
      }
      &.app-streaming-menu-item {
        color: $vg-gray-2;
      }
    }
  }

  .active {
    color: $vg-purple-main;
  }

  .divider {
    width: 70%;
    height: 1px;
    align-self: center;
    background-color: $vg-gray-5;
    margin: 1rem 0;

    &.disabled {
      opacity: 0.5;
    }
  }
  .sub-items-container {
    @include flex($direction: row, $justify: flex-start);
    height: 0rem;
    transition: 0.6s;
    overflow: hidden;
    opacity: 0;
    &.open {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
    @include full-content-width;
    padding: 0 0 0 25%;
    box-sizing: border-box;
    .dropdown-menu-background-line {
      height: 100%;
      background-color: $vg-gray-6;
      width: 0.2rem;
      border-radius: 100px;
    }
    .sub-items-list {
      @include flex($direction: column, $align: flex-start);
      @include full-content-width;
      a {
        margin: 0.5rem 0;
      }

      .link-container {
        @include flex($direction: row, $justify: flex-start, $align: center);
        @include full-content-width;
        height: 2.5rem;
        padding-left: 1rem;
        cursor: pointer;
        a {
          font-weight: $vg-font-weight-regular;
          color: $vg-gray-3;
          @include full-content-size;
          line-height: 2.5rem;
        }
        &:hover:not(.active):not(.disabled) {
          background-color: $vg-gray-7;
        }
        &.disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.5;
        }
        .dropdown-menu-line {
          height: 100%;
          width: 0.2rem;
          margin-right: 1rem;
          margin-left: -1.2rem;
          border-radius: 100px;
        }
        &.active {
          a {
            color: $vg-purple-main;
          }

          .dropdown-menu-line {
            background-color: $vg-purple-main;
          }
        }
      }
    }
  }
}

.smooth-transition {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
