@import "Assets/styles/Global/_variables";

$menu-width: 12rem;

.file-menu-pop-up-container {
  position: absolute;
  height: 0;
  width: 0;
  z-index: 9;
  .file-menu-pop-up {
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    box-shadow: $light-shadow;
    background-color: $vg-white;
    padding: 1rem;
    box-sizing: border-box;
    width: $menu-width;

    .menu-button {
      width: 100%;
      margin: 0 -1rem;
      padding: 0 1rem;
      &:hover {
        background-color: $vg-gray-6;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .vg-text-button {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding: 0.5rem 0.2rem;
      text-align: left;
      font-size: 0.9rem;
      font-weight: $vg-font-weight-light;
      white-space: nowrap;

      .vg-icon {
        height: 1.2rem;
        margin-right: 0.8rem;
      }
    }
  }
}
