@import "Assets/styles/Global/_variables.scss";

.executable-helpers-modal {
  height: 25rem;

  .vg-table-container {
    max-height: 15rem;
    width: 100%;
    margin-top: 1rem;
    @include for-mobile {
      width: 17rem;
      overflow-x: auto;
    }
    .vg-table th {
      font-size: 0.75rem;
    }
    .vg-table td {
      font-size: 0.7rem;
    }
    .vg-text-button {
      font-size: 0.7rem;
    }
  }

  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    color: $vg-black;
  }
  h3 {
    color: $vg-gray-1;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    font-weight: $vg-font-weight-regular;
  }

  .new-helper-form {
    margin-top: 1rem;
    width: 100%;

    .app-folder-box {
      @include full-content-width;
      @include flex($direction: row, $align: center, $justify: space-between);
      border: $border-gray-5;
      border-radius: 2px;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      box-sizing: border-box;
      min-height: 2rem;
      font-size: 0.8rem;
      color: $vg-gray-2;

      &.vg-dropdown {
        .content {
          top: 1.2rem;
          margin-left: -0.5rem;
        }
      }
    }

    .flex-options {
      @include flex($direction: row, $align: center, $justify: space-between);
      margin-top: 1rem;
      gap: 0.5rem;
      > div {
        width: 48%;
      }
    }
  }

  .no-helpers-content {
    @include flex($direction: column, $align: center, $justify: center);
    height: 100%;
    width: 100%;
    font-size: 0.9rem;
    color: $vg-gray-2;

    .vg-button {
      align-self: initial;
    }
  }

  .buttons-container {
    @include flex($direction: row, $align: center, $justify: space-between);
    width: 100%;
    margin-top: auto;
    gap: 0.5rem;
  }

  .vg-button {
    align-self: flex-end;
    width: 11rem;
    margin-top: 1rem;
    .vg-icon {
      height: 0.8rem;
    }
  }

  .vg-loader {
    width: 100%;
  }
}

.confirm-version-change-modal {
  .vg-button {
    background-color: $vg-light-navy;
    font-weight: $vg-font-weight-regular;
  }
  .vg-text-button {
    color: $vg-light-navy;
  }

  .description {
    align-self: flex-start !important;
  }
}
