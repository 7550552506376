@import "Assets/styles/Global/_variables";

.app-upload-modal {
  .description {
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
  }
  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    color: $vg-black;
  }
  .description {
    @include full-content-height;
    > p {
      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }

  .app-upload-container {
    height: 19rem;
  }
  .app-upload-modal-container {
    @include for-mobile {
      width: 20rem;
      overflow-x: scroll;
    }
  }

  .app-upload-container,
  .app-type-selection-container {
    @include flex;
    @include full-content-width;
    flex-grow: 1;
  }

  .app-type-selection-container {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 1rem;
    @include for-mobile {
      width: 35rem;
    }

    .actual-content {
      @include full-content-size;
      @include flex($direction: column, $align: flex-start, $justify: flex-end);
      gap: 0.4rem;
      transition: transform 0.5s ease;
    }

    .hoverable-content {
      @include full-content-size;
      @include flex($direction: column, $align: flex-start, $justify: space-between);
      transition: transform 0.5s ease;
    }
    .hoverable-content {
      visibility: hidden;
      opacity: 0;
    }

    .type-description {
      position: relative;
      font-weight: $vg-font-weight-medium;
      .beta-text {
        position: absolute;
        top: -1.5rem;
        font-size: 0.65rem;
        font-weight: $vg-font-weight-light;
        line-height: 1rem;
        color: $vg-aqua-light;
      }
    }

    .app-type-option {
      width: 35%;
      border: $border-gray-6;
      border-radius: 6px;
      padding: 1.4rem 1rem;

      box-sizing: border-box;
      overflow: hidden;
      max-height: 16rem;
      margin-top: 1rem;
      &:hover {
        .hoverable-content {
          visibility: visible;
          opacity: 1;
          transform: translate(0, -100%);
        }
        .actual-content {
          transform: translate(0, -150%);
          .app-action-button {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
      img {
        height: 2.5rem;
        margin-bottom: auto;
        margin-top: 0.5rem;
      }
      h5 {
        color: $vg-black;
        max-width: 80%;
      }
      .app-action-button {
        color: $vg-gray-2;
        font-size: 0.75rem;
        opacity: 1;
        transition: opacity 1s;
      }
      .vg-text-button {
        .vg-icon {
          margin-left: 0.5rem;
        }
      }
      &.transparent {
        opacity: 0.4;
      }
    }
  }
}
