@import "Assets/styles/Global/_variables.scss";

.app-card-container {
  @include flex($direction: column, $align: stretch);
  flex-grow: 0;
  flex-shrink: 0;
  width: 15rem;
  position: relative;

  .budget-limit-badge {
    background-color: $vg-white;
    padding: 0.2rem 0.25rem;
    top: -0.6rem;
    position: absolute;
    font-size: 0.75rem;
    left: 0.4rem;
    text-align: center;
    border-radius: 4px;
    color: $vg-gray-3;
  }

  &.transparent {
    opacity: 0.6;
  }
  .app-logo-container {
    @include full-content-width;
    @include flex($direction: column, $justify: flex-start);
    background: linear-gradient(322.89deg, #053d5c 0%, #28787d 100%);
    border-radius: 6px 6px 0 0;
    height: 10rem;
    position: relative;
    &.centered {
      justify-content: center;
    }
    .top-line {
      @include flex($direction: row, $align: center, $justify: space-between);
      @include full-content-width;
      padding: 1rem 1rem 0.5rem;
      line-height: 1rem;
      box-sizing: border-box;
      .vg-dropdown-trigger-button {
        width: auto;
      }
      .status {
        @include flex($direction: row, $align: center, $justify: flex-start);
        background-color: $vg-white;
        border-radius: 100px;
        padding: 0.3rem 0.6rem;
        font-size: $vg-font-size-tiny;
        transition: all 1s ease-in-out;
        .status-circle {
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.5rem;
          border-radius: 100px;
        }

        .info-icon {
          height: 1rem;
          width: 1rem;
          margin-left: 0.3rem;
        }
      }
      .vg-icon {
        height: 1.4rem;
        width: 1.4rem;
        * {
          stroke-width: 0.1;
        }
      }
    }

    img {
      width: 100%;
      max-height: 4rem;
      object-fit: contain;
    }

    .os-logo {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      * {
        fill: #83b3c099;
      }
    }

    .bundled-app-icon {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    .optimization-badge {
      display: none;
    }

    &:hover {
      .os-logo {
        opacity: 0.6;
      }

      .bundled-app-icon {
        opacity: 0.6;
      }

      .optimization-badge {
        display: block;
      }
    }
  }

  .content {
    background-color: $vg-white;
    padding: 0.7rem 1rem 0.4rem;
    border-radius: 0 0 6px 6px;

    box-sizing: border-box;
    h5 {
      line-height: 2;
      margin: 0;
      margin-bottom: 0.5rem;
    }
    p {
      color: $vg-gray-2;
      font-size: $vg-font-size-tiny;
      line-height: 2.6;
      a {
        color: $vg-blue-baby;
      }
    }
    .action-buttons-container {
      @include flex($direction: row, $justify: space-around, $align: center);
      box-sizing: border-box;
      width: 100%;
      bottom: 0.5rem;
      .vertical-divider {
        width: 1px;
        height: 2rem;
        background-color: $vg-gray-6;
      }
    }
  }
}

.app-card-container {
  &.use-case {
    .content {
      display: flex;
      flex-grow: 1;
      .content-box {
        > div {
          @include flex($direction: column, $align: flex-start, $justify: space-between);
          @include full-content-height;
          padding: 0.5rem 0;
          box-sizing: border-box;
        }
      }
    }
  }
}
.app-card-container:nth-child(3n) {
  .app-logo-container {
    background: linear-gradient(322.89deg, #053d5c 0%, #28787d 100%);
  }
}
.app-card-container:nth-child(3n + 1) {
  .app-logo-container {
    background: linear-gradient(322.89deg, #05285c 0%, #43287d 100%);
  }
}
.app-card-container:nth-child(3n + 2) {
  .app-logo-container {
    background: linear-gradient(322.89deg, #05285c 0%, #28547d 100%);
  }
}

.application-card-pop-up-menu {
  .vg-dropdown-menu-item {
    color: $vg-gray-3;
    font-size: $vg-font-size-tiny;
  }

  .pro-badge {
    color: $vg-baby-powder;
    line-height: 1rem;
    border-radius: 14px;
    font-size: $vg-font-size-tiny;
    font-weight: $vg-font-weight-medium;
    margin-left: 0.4rem;
  }

  .enterprise-badge {
    color: $vg-aqua-light;
    line-height: 1rem;
    border-radius: 14px;
    font-size: $vg-font-size-tiny;
    font-weight: $vg-font-weight-medium;
    margin-left: 0.4rem;
  }

  .disabled {
    opacity: 0.6;
  }
}

@keyframes packagingAppsColorChange {
  0% {
    background-color: $vg-baby-powder;
  }
  50% {
    background-color: $vg-blue-baby;
  }
  100% {
    background-color: $vg-baby-powder;
  }
}
