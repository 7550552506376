@import "Assets/styles/Global/_variables";

.resize-button {
  position: absolute;
  right: 2vh;
  top: 5vh;
  padding: 0;
  width: 12rem;
  height: 2.4rem;
  font-size: 0.9rem;
  color: $vg-white;
  background-color: $vg-gray-1;
  z-index: 99;
  box-shadow: $light-shadow;
}

.fadeout-animation {
  animation: fadeout 7s linear 1 forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
