@import "Assets/styles/Global/_variables.scss";

.modal-overlay {
  .modal-container.boarding-modal {
    left: calc(#{$width-navbar-desktop} * -1 / 2);
    width: 22rem;
    text-align: center;

    @include for-mobile {
      left: 0;
    }

    .modal-content {
      .header {
        color: $vg-gray-2;
        width: 100%;
        img {
          height: 10rem;
          margin-bottom: 1.5rem;
          width: auto;
        }
      }

      .description {
        color: $vg-gray-4;
      }

      .modal-action-wrapper {
        align-self: center;
        width: 10rem;
        height: 3rem;
      }
    }
  }
}
