@import "Assets/styles/Global/_variables.scss";

.upload-app-version-modal {
  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    color: $vg-black;
  }
  h3 {
    color: $vg-gray-1;
    margin-bottom: 0.5rem;
    font-weight: $vg-font-weight-regular;
  }

  .app-upload-container {
    height: 90%;
  }

  .file-status {
    box-shadow: none;
    border: 1px solid $vg-gray-5;
  }

  .vg-icon:last-of-type {
    margin-left: 0.5rem;
  }

  .file-name {
    margin-left: 1rem;
  }

  .description {
    flex: 1;
    > p {
      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }
  .app-folder-box {
    @include full-content-width;
    @include flex($direction: row, $align: center, $justify: space-between);
    border: $border-gray-5;
    border-radius: 2px;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    min-height: 2rem;
    .name-container {
      @include flex($direction: row, $align: center);
      color: $vg-gray-3;
      .vg-icon {
        margin-right: 0.5rem;
      }
    }
    &.vg-dropdown {
      .content {
        top: 1.2rem;
        margin-left: -0.5rem;
      }
    }
  }

  .box-input-wrapper {
    width: 100%;
    .vg-input {
      .input-wrapper {
        input {
          border: $border-gray-5;
          box-sizing: border-box;
          border-radius: 2px;
          &::placeholder {
            opacity: 0.3;
          }
        }
      }
    }
  }

  .executable-step {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .content {
      margin: auto 0;
      padding: 0 1rem;
    }

    .file-name {
      white-space: nowrap;
      max-width: 40%;
      padding-right: 1rem;
    }

    .footer {
      font-size: 0.65rem;
      font-weight: $vg-font-weight-light;
      text-align: center;
      width: 60%;
      color: $vg-gray-3;
      white-space: break-spaces;
      position: absolute;
      bottom: 1rem;
      width: 100%;
    }
  }

  .completion-step {
    @include flex($direction: column, $align: center, $justify: center);
    height: 100%;
    gap: 1rem;
    text-align: center;

    img {
      height: 8rem;
    }

    .completion-description {
      color: $vg-gray-3;
      text-align: center;
      white-space: break-spaces;
      width: 70%;
    }

    .optimization-info-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 0;
      width: 65%;
      border: solid 1px $vg-aqua-main;
      border-radius: 5px;
      margin-top: auto;
      padding: 1rem;

      .vg-icon {
        height: 1.2rem;
        margin-right: 0.3rem;
      }

      h1 {
        color: $vg-aqua-main;
        font-weight: $vg-font-weight-bold;
        font-size: 0.7rem;
        text-align: left;
        margin-bottom: 0.2rem;
      }

      p {
        text-align: left;
        color: $vg-gray-1;
        font-size: 0.7rem;
        line-height: 1.6;
      }
    }
  }

  .image-upload-container {
    height: 8rem;
    max-height: 8rem;
  }
}
