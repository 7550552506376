@import "Assets/styles/Global/_variables";

.invite-team-members-modal .content-container .modal-content {
  display: flex;
}

.invite-team-members-modal {
  .invite-team-members-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .invite-status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 10rem;
      padding: 1rem 0 0.5rem;
      .status-button {
        display: flex;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        border-radius: 3px;
        padding: 0.2rem 0.4rem;
        background-color: $vg-gray-6;

        &.selected {
          background: $vg-purple-main;
          p {
            background-color: $vg-purple-main;
            color: white;
          }
          span {
            background-color: white;
            color: $vg-gray-3;
          }
        }
        p {
          font-size: 0.7rem;
          color: $vg-gray-3;
        }

        span {
          display: flex;
          background-color: $vg-gray-5;
          color: white;
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          font-size: 0.6rem;
          font-weight: 700;
          margin-left: 0.5rem;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .team-user-list {
      &.no-invitation {
        height: 12rem;
      }

      .table-body {
        overflow-y: auto;
        max-height: 12rem;

        &.no-invitation {
          height: 12rem;
        }
      }

      .no-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        p {
          font-size: 0.8rem;
          color: $vg-gray-3;
        }

        img {
          width: 6rem;
        }
      }
    }

    .invite-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid $vg-gray-6;
      border-radius: 3px;
      min-height: 2rem;

      .team-invite-button {
        margin: 0 0.5rem;
      }
      .team-member-input {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        overflow-x: auto;
        margin: 0 0 0 0.5rem;
        &::-webkit-scrollbar {
          height: 0.3rem;
        }
        .vg-input {
          min-width: 4rem;
        }
        .vg-input input {
          margin: 0;
          opacity: 0.5;
          font-size: 0.7rem;
        }
        .vg-text-button {
          position: absolute;
          bottom: 0.5rem;
          right: 0;
        }

        .invited-user {
          display: flex;
          align-items: center;
          background-color: $vg-gray-6;
          width: fit-content;
          border-radius: 7px;
          margin-right: 0.5rem;

          &.failed {
            background-color: hsla(0, 58%, 71%, 0.25);
            color: #e87f7f;
          }

          .close-icon {
            margin-right: 0.4rem;
            height: 0.5rem;
            width: 0.5rem;
          }

          p {
            opacity: 0.5;
            font-size: 0.7rem;
            margin: 0 0.4rem;
          }
        }
        .invite-box {
          width: 100%;
          border: #f2f2f2;
        }
      }
    }
  }

  .modal-action-wrapper {
    .vg-button {
      margin-left: auto;
      font-size: 0.75rem;
    }
  }
}
