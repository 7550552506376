@import "Assets/styles/Global/_variables.scss";

.timezone-selection-modal {
  font-weight: 700;
  p {
    font-weight: 500;
    opacity: 0.6;
    margin: 0.5rem 0 0;
    font-size: 0.9rem;
  }
  .time-zone-container {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    flex-direction: column;
  }

  .timezone-modal {
    .header {
      margin-bottom: 1.5rem !important;
    }

    .modal-action-wrapper {
      height: 3rem !important;
    }
  }
}
