@import "Assets/styles/Global/_variables";

.hoverable-icon {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-wrap;
  cursor: pointer;
  outline: none;

  &:hover .hover-panel,
  &.hover .hover-panel {
    opacity: 1;
  }

  p {
    color: $vg-gray-2;
    font-size: 10px;
    line-height: 12px;
    &.icon-text {
      margin-left: 8px;
    }
  }
  &.active {
    color: $vg-purple-main;
    &.stroke {
      .vg-icon path {
        stroke: $vg-purple-main;
      }
    }
    &:not(.stroke) {
      .vg-icon path {
        fill: $vg-purple-main;
      }
    }
  }
  &.disabled {
    cursor: default;
    color: $vg-gray-4;
    &.stroke {
      .vg-icon path {
        stroke: $vg-gray-4;
      }
    }
    &:not(.stroke) {
      .vg-icon path {
        fill: $vg-gray-4;
      }
    }
  }
}
