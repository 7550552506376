@import "Assets/styles/Global/_variables";

.vr-tab {
  .installation-state-container {
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;

    p {
      font-size: $vg-font-size-tiny;
      margin-right: auto !important;
      color: $vg-gray-4;

      @include for-mobile {
        font-size: 0.675rem;
      }
    }

    span {
      font-size: $vg-font-size-tiny;
      @include for-mobile {
        font-size: 0.675rem;
      }
    }

    .vg-progress-bar-container {
      width: 10%;
    }
  }

  .beta-header {
    display: flex;
    align-items: center;

    span {
      font-size: 0.75rem;
      color: $vg-aqua-main;
    }
  }

  .empty-folder {
    @include flex;
    width: 100%;
    @include for-mobile {
      width: 60%;
    }

    img {
      display: block;
      height: 5rem;
      margin: 3rem 0 2rem;
    }

    h1 {
      font-weight: $vg-font-weight-light;
      margin-bottom: 0.5rem;
    }

    p {
      @include for-mobile {
        font-size: 0.7rem;
      }
      max-width: 15rem;
      font-size: $vg-font-size-tiny;
      color: $vg-gray-3;
      text-align: center;

      span {
        color: $vg-purple-light;
        font-weight: $vg-font-weight-medium;
      }
    }

    .with-margin {
      margin-top: 1rem;
    }
  }

  .join-session-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
    align-items: center;

    .vr-headset {
      .vg-icon {
        width: 2rem;
        * {
          fill: $vg-purple-main;
        }
      }
    }
  }
}
