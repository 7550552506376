@import "Assets/styles/Global/_variables.scss";

.vg-tooltip-hoverable {
  .tooltip-content {
    z-index: 9999;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 15px;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    background-color: $vg-white;
    border-radius: 2px;
    width: max-content;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: $vg-gray-2;
    white-space: break-spaces;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-light;
    padding: 0.5rem 1rem;
    border-radius: 4px;

    strong {
      font-weight: $vg-font-weight-semi-bold;
    }
  }
  .tooltip-content[data-state="delayed-open"][data-side="top"] {
    animation-name: slideDownAndFade;
  }
  .tooltip-content[data-state="delayed-open"][data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  .tooltip-content[data-state="delayed-open"][data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  .tooltip-content[data-state="delayed-open"][data-side="left"] {
    animation-name: slideRightAndFade;
  }

  .tooltip-children {
    display: flex;

    button {
      // all: unset;
    }
  }

  .tooltip-arrow {
    stroke: $vg-gray-6;
    fill: $vg-white;
  }

  .tooltip-children {
    display: flex;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
