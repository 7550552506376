@import "Assets/styles/Global/_variables.scss";

$left-content-width: 15rem;

.teams-new-template-container {
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  height: 100%;

  @include for-mobile {
    min-height: 0;
  }

  .softwares-board {
    @include flex($direction: column, $justify: space-between, $align: flex-start);
    width: 100%;
    height: calc(100% - 2rem);
    padding: 0 5rem;
    margin-top: 2rem;

    @include for-mobile {
      padding: 0 1rem;
      margin: 1rem 0;
    }

    .storage-selection-section {
      width: 100%;

      p {
        margin-right: 1rem;
      }

      .installation {
        margin: auto;
        width: 15rem;
      }

      .storage-selection {
        @include flex($direction: row, $justify: space-between, $align: center);
        margin-bottom: 1rem;

        @include for-mobile {
          width: 15rem;
          margin: auto;
          .vg-dropdown {
            height: 2.5rem;
          }
        }

        .vg-radio-input label {
          font-size: 0.9rem;
          min-width: 4rem;

          span {
            white-space: nowrap;
            font-size: 0.75rem;
            color: $vg-gray-3;
          }
        }
        .vg-radio-input:not(.checked) {
          color: $vg-gray-3;
          .check {
            border-color: $vg-gray-3;
          }
        }
      }
    }

    .search-container {
      @include flex($direction: column, $justify: space-between, $align: center);
      width: 100%;
      margin-bottom: 1rem;
    }

    .search-input {
      width: 100%;
      display: block;
      max-width: 30rem;
      margin-bottom: 1.75rem;
      margin-right: auto;
    }

    .search-tags {
      @include flex($direction: row, $justify: space-between, $align: center);
      width: 100%;
      .vg-text-button {
        color: $vg-black;
        &.selected {
          font-weight: $vg-font-weight-bold;
        }
      }
    }
    .estimated-size-container {
      height: 50px;
      width: 100%;

      p {
        font-size: 0.9rem;
        color: $vg-gray-3;
      }
      .vg-icon {
        height: 1.5rem;
      }

      .estimated-size {
        @include flex($direction: row, $justify: flex-end);
        line-height: 2rem;
      }
    }
  }

  .software-list-container {
    width: 100%;
    flex: 50% 1 0;
    margin: 0rem 0 1rem -1rem;
    padding-right: 1rem;
    overflow-y: auto;

    .software {
      p {
        margin: 0 1rem;
      }
    }

    @include for-mobile {
      margin: 1rem 0 0;
    }

    @include for-vertical-mobile {
      width: 20rem;
      align-self: center;
      padding: 0;
    }
  }

  .software-list {
    @include flex($direction: row, $justify: flex-start, $align: flex-start);
    width: 100%;
    flex-wrap: wrap;
    min-height: 18rem;

    @include for-mobile {
      min-height: 15rem;
    }
  }

  .vagon-application-info {
    display: flex;
    align-items: center;
    height: 8rem;
    width: 17.6rem;
    border: 2px solid $vg-aqua-light;
    border-radius: 5px;
    margin: 0 0.7rem 2rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      img {
        height: 5rem;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;
      background-color: $vg-aqua-light;
      width: 60%;
      height: 100%;
      margin-left: auto;

      h1 {
        color: $vg-white;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-bold;
        margin: 0 20px;
      }

      p {
        color: $vg-white;
        font-size: 0.7rem;
        font-weight: 500;
        line-height: 1.8;
        margin: 0 1rem;
      }

      .vg-icon {
        height: 0.8rem;
        width: 1rem;
        position: absolute;
        margin-left: 85%;
        margin-top: -60%;
      }
    }
  }

  .selected-software-list {
    @include flex($direction: column, $justify: space-between, $align: center);
    width: 40rem;
    height: 100%;
    background-color: $vg-dark-navy;

    @include for-small-screen {
      display: none;
    }

    h1 {
      font-weight: $vg-font-weight-bold;
      color: $vg-white;
      margin-top: 2rem;
    }

    .selected-sotwares {
      width: 20rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background-color: rgba($vg-gray-6, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($vg-gray-6, 0.2);
      }

      .software-row {
        @include flex($direction: row);
        flex-wrap: wrap;
        .software-card-container {
          height: 6.5rem;
        }
      }
    }

    .selected-package {
      @include flex($direction: column);

      .vg-icon {
        margin: 1.5rem 0 0.5rem;
        height: 1rem;
        width: 1rem;
        background-color: $vg-aqua-light;
        border-radius: 10rem;
        padding: 0.5rem;
      }
    }

    .create-computer-action {
      @include flex($direction: column, $justify: center, $align: center);
      color: $vg-white;
      .text-button {
        color: $vg-white;
      }
    }

    .installation {
      @include flex($direction: row, $justify: space-between, $align: center);
      width: $left-content-width;
      font-size: 0.7rem;
    }
    .divider-container {
      width: $left-content-width;
      margin: 1rem 0;
      .divider-logo {
        margin: 0 1rem;
        background-color: transparent;
      }
    }
    .create-template-action-container {
      margin: 1rem 5% 3rem 5%;
      .create-computer-action {
        margin-bottom: 0;
        padding: 0;
      }
    }
  }
}
