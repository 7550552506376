@import "Assets/styles/Global/_variables";

.shortcut-buttons {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1rem;
  @include for-mobile {
    width: 185px;
  }

  .hoverable-icon {
    background-color: $vg-gray-7;
    border-radius: 3px;
    padding: 10px;
  }

  .vg-icon {
    width: 25px;
    height: 25px;
  }

  .hover-panel {
    background-color: $vg-gray-7;
    color: $vg-gray-2;
    left: 50%;
    transform: translateX(-50%);
    top: 60px;
    &.alpha {
      p::after {
        font-family: $default-font-family;
        font-size: 10px;
        color: $vg-baby-powder;
        content: "Alpha";
        margin-left: 10px;
      }
    }
  }
}
