@import "Assets/styles/Global/_variables.scss";

.teams-plans-container {
  @include flex($direction: column, $justify: flex-start, $align: center);
  height: 100%;
  border-radius: 7px;
  position: relative;

  .top-bar {
    @include flex($direction: row, $justify: space-between, $align: center);
    @include full-content-width();
    margin-bottom: 2rem;

    @include for-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 6rem;
      margin-top: unset;
    }

    .left-actions {
      @include flex($direction: row, $justify: flex-start, $align: center);
      width: 25%;

      @include for-mobile {
        width: 100%;
      }

      .vg-search-input-container {
        width: 100%;
      }
    }
    .right-actions {
      @include flex($direction: row, $justify: flex-end, $align: center);
      @include full-content-height();
      .vertical-divider {
        width: 1px;
        height: 1.4rem;
        background-color: $vg-gray-3;
        margin: 0px 0.5rem;
      }
    }
  }

  .teams-plans-table {
    padding: 1rem;
    background-color: $vg-white;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 16rem);

    @include for-mobile {
      overflow-x: auto;
      .header-line {
        width: 45rem;
      }
    }

    .table-body {
      overflow-y: auto;
      border-radius: 5px;
      position: relative;

      @include for-mobile {
        width: 45rem;
      }

      .plan-name {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        .vg-icon {
          height: 1rem;
          width: 1rem;
          margin-top: -0.1rem;
        }
      }

      .price {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        p {
          color: $vg-gray-3;
          span {
            font-weight: $vg-font-weight-semi-bold;
          }
        }
        .vg-icon {
          height: 1rem;
          width: 1rem;
          margin-top: -0.1rem;
        }
      }

      .trial-plan-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 1.5;
        justify-content: center;

        p:first-of-type {
          color: $vg-aqua-main;
          font-weight: $vg-font-weight-semi-bold;
        }
      }

      &::-webkit-scrollbar {
        width: 0.3rem;
      }

      .table-row {
        line-height: 3;
        .vg-tooltip {
          right: calc(100% + 0.6rem);
          top: -0.8rem;
          bottom: auto;
        }
      }

      .expandable-content {
        width: 100%;
        align-items: flex-start;

        .machine-types {
          @include flex($direction: column, $justify: flex-start, $align: flex-start);

          .header {
            display: flex;
            gap: 0.4rem;
            align-items: flex-start;
            margin-bottom: 0.6rem;
            .vg-icon {
              margin-bottom: -0.6rem;
            }
          }

          .duration {
            @include flex($direction: row);
            gap: 4px;
            line-height: 2;
            & > p:last-child {
              color: $vg-gray-3;
            }
            p {
              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .table-cell {
      .highlighted {
        color: $vg-aqua-main;
      }
    }

    .assigned-users-cell {
      display: flex;
      align-items: center;
      gap: 0.15rem;
      .vg-icon {
        height: 0.9rem;
      }
    }

    .button-cell {
      @include flex($direction: row, $justify: flex-end);
      height: 1rem;
      width: 100%;
      margin-left: 0.8rem;
      padding-top: 0.2rem;
      gap: 0.5rem;
      .vg-icon {
        display: inline-block;
        height: 1.15rem;
        width: 1.15rem;
        @include for-mobile {
          min-width: 1.15rem;
        }
      }
    }
  }
}
