@import "Assets/styles/Global/_variables.scss";

.hot-world-map {
  @include flex($direction: column, $justify: center, $align: center);
  @include full-content-size;

  width: 90%;
  margin: 0 auto;
  height: 18rem;
  svg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  svg.location-mark {
    width: 50px;
    height: 50px;
    path {
      fill: $vg-purple-main;
    }
  }

  .map-point {
    height: 20px;
    width: 20px;
    position: absolute;
    cursor: default;

    &.uncovered {
      cursor: default;
    }

    .region-pointer {
      path {
        filter: drop-shadow(0px 20px 30px $vg-gray-3);
      }
    }

    .marker-not-available {
      fill: $vg-gray-4;
    }

    .covered-region {
      fill: $vg-purple-main;
    }
    .uncovered-region {
      fill: $vg-gray-4;
    }
    .inactive-region {
      fill: none;
    }
    .active-region {
      animation: opacity-breath 2s infinite;
      fill: $vg-aqua-light;
    }

    &.selected {
      fill: $vg-purple-main;
      path {
        fill: $vg-purple-main;
      }
    }

    &.show-label {
      .region-label {
        display: block;
        animation: fade-in 0.5s;
      }
    }

    .region-label {
      display: none;
    }

    &:hover {
      .region-label {
        display: block;
        animation: fade-in 0.5s;
        path {
          filter: drop-shadow(0px 10px 30px $vg-gray-5);
        }
      }
      &.uncovered {
        .not-available-label {
          display: block;
          animation: fade-in 0.5s;
          path {
            filter: drop-shadow(0px 10px 30px $vg-gray-5);
          }
        }
      }
    }
  }

  @keyframes opacity-breath {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.15;
    }
    100% {
      opacity: 0.3;
    }
  }
}
