@import "Assets/styles/Global/_variables";

.workstation-usages {
  .workstation-usage-container {
    @include flex($direction: column, $justify: center, $align: center);

    border: $border-gray-6;
    border-width: 2px;
    border-radius: 100px;
    height: 100px;
    min-width: 100px;
    background-color: $vg-white;
    overflow: hidden;

    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);

    &.clickable {
      cursor: pointer;
    }

    p {
      transition: font-size 0.3s ease-in-out;
      font-size: 0.5rem;
      opacity: 0.6;
    }

    img {
      transition: all 0.3s ease-in-out;
      height: 30px;
    }

    &.selected {
      border: 1px solid $vg-aqua-main;
    }

    &:hover {
      .computer-information {
        margin: 0;
        display: block;
      }
      .credits-per-hour {
        display: none;
      }
      .computer-icon {
        display: none;
      }
    }

    &.highlighted {
      z-index: 8;
      height: 140px;
      min-width: 140px;
      font-size: 1.2rem;
      left: 50% !important;
      opacity: 1 !important;

      p {
        font-size: 0.7rem;
      }
      img {
        height: 55px;
        margin: -10px 0;
      }
    }
  }
}

.workstation-usages-container {
  @include flex($direction: row, $justify: space-between, $align: center);
  min-width: 300px;
  .workstation-usages {
    @include flex($direction: row, $justify: space-between, $align: center);
    flex: 1 0 90%;
    max-width: 100%;
    margin: auto;
    height: 180px;
    overflow: hidden;
    position: relative;

    .workstation-usage-container {
      position: absolute;
      opacity: 0.5;
      left: -20%;
    }

    // classes after highlighted
    .highlighted ~ .workstation-usage-container {
      left: 120%;
    }

    .highlighted-side {
      left: calc(50% - 110px);
    }

    .highlighted ~ .highlighted-side {
      left: calc(50% + 110px);
    }

    .computer-information {
      display: none;
    }
  }
}
