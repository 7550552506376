@import "Assets/styles/Global/_variables";

.introjs-tooltip {
  min-width: 0rem;
  @include for-mobile {
    display: none !important;
  }
  * {
    font-family: "vagonRails", "sans-serif";
    font-size: 0.7rem;
  }
  &.prevent-overflow {
    top: -15rem !important;
    &.introjs-left {
      .introjs-arrow.right-bottom {
        bottom: 2.5rem !important;
      }
    }
  }

  img {
    border-radius: 5px 5px 0 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    animation: fade-in 1s;
    animation-fill-mode: forwards;
  }
  .introjs-skipbutton {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    color: white;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    padding: 4px;
    font-size: 1.4rem;
    line-height: 0.5;
    font-weight: lighter;
  }
  .introjs-tooltip-header {
    padding: 0;
    .introjs-tooltip-title {
      @include full-content-height;
      box-sizing: border-box;
      width: 100%;
      line-height: 0;
    }
  }
  .introjs-tooltiptext {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    h1 {
      margin-bottom: 0.5rem;
      font-weight: 600;
      font-size: 0.85rem;
    }
    p {
      color: #4f4f4f;
      line-height: 2;
      margin-bottom: 1rem;
      white-space: break-spaces;
      span {
        font-weight: $vg-font-weight-bold;
      }
    }
  }
  .introjs-tooltipbuttons {
    border: none;

    .introjs-nextbutton {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      font-weight: 400;
      color: #5e73ff;
      background-color: rgba(255, 255, 255, 0);
      border: none;
      &:active,
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .introjs-prevbutton {
      display: none;
    }
  }
  .introjs-bullets {
    text-align: left;
    padding: 0 1.8rem;
    width: 7rem !important;
    ul {
      li {
        margin: 2px 2px;
      }
    }

    ul {
      position: absolute;

      li {
        a {
          background-color: rgba(94, 115, 255, 0.2);
          &.active {
            width: 6px;
            background-color: $vg-purple-light;
          }
          &:hover {
            background-color: $vg-purple-light;
          }
        }
      }
    }
  }
  &.onboarding-modal-hanging {
    width: 19rem;
    max-width: 20rem;
    min-height: 21rem;
    transition: display;
    .introjs-bullets {
      ul {
        bottom: 1.8rem;
        left: 1.5rem;
      }
    }
    img {
      margin-top: -2px;
    }
  }

  &.onboarding-modal-hanging-files {
    width: 19rem;
    max-width: 20rem;
    min-height: 19rem;
    transition: display;
    .introjs-bullets {
      ul {
        bottom: 1.8rem;
        left: 1.5rem;
      }
    }
    img {
      margin-top: -2px;
    }
  }

  &.create-streams-onboarding-modal {
    width: 11rem;
    max-width: 11rem;
    transition: display;
    .introjs-bullets {
      ul {
        bottom: 1.8rem;
        left: 1.5rem;
      }
    }
    img {
      margin-top: -2px;
    }
    @media (min-width: 1750px) {
      width: 19rem;
      max-width: 20rem;
      min-height: 21rem;
    }
  }

  &.onboarding-modal-floating {
    display: flex !important;
    align-items: stretch;
    max-width: 32rem;
    width: 32rem;
    box-sizing: border-box;
    flex-direction: row;
    min-height: 14rem;
    transition: display;

    &.settings-tab {
      @media (max-height: 950px) {
        top: 145px !important;
      }
    }
    img {
      height: 100%;
      width: 14rem;
      border-radius: 5px 0px 0 5px;
      margin-left: -2px;
    }

    .introjs-bullets {
      position: absolute;
      bottom: 1.5rem;
      left: 15.5rem;
      width: 4.5rem;
      height: 6px;
      padding: 0;
    }
    .introjs-tooltipbuttons {
      padding: 0;
    }
    .introjs-nextbutton {
      line-height: 0.7rem;
      bottom: 1rem;
    }
    .introjs-tooltiptext {
      padding: 1.5rem 1.5rem 2.5rem;
    }
  }
}

.introjs-helperLayer {
  &.myHighlightClass {
    box-shadow: rgba(33, 33, 33, 0.4) 0px 0px 0px 5000px !important;
    @include for-mobile {
      display: none !important;
    }
    &.settings-tab {
      @media (max-height: 950px) {
        top: 145px !important;
      }
    }
  }
}
