@import "Assets/styles/Global/_variables.scss";

.budget-limit {
  display: flex;
  flex-direction: row;

  @include for-mobile {
    flex-direction: column;
  }
  .input-box {
    @include flex($direction: row, $align: flex-end, $justify: flex-end);
    font-size: 1;
    font-weight: $vg-font-weight-light;
    color: $vg-gray-3;
    @include for-mobile {
      display: flex !important;
      justify-content: flex-start !important;
      min-width: none;
      width: unset !important;
      margin-left: 1rem !important;
    }

    .vg-input {
      @include for-mobile {
        text-align: unset !important;
        width: 45% !important;
      }
    }
    &.disabled {
      color: $vg-gray-5;
      .vg-input::before {
        color: $vg-gray-5;
      }
      .vg-input {
        .input-wrapper {
          .vg-input {
            color: $vg-gray-5;
          }
        }
      }
    }

    &.selected .vg-input {
      border-color: $vg-purple-main;
    }

    p {
      display: inline-block;
      font-size: 1rem;
      padding-left: 0.5em;
    }

    .vg-input::before {
      content: "$";
      position: absolute;
      font-size: 1rem;
      color: $vg-gray-3;
      right: 3rem;
      top: -1px;
      z-index: 1;
      @include for-mobile {
        right: 8rem;
      }
    }

    .vg-input {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      border-color: $vg-gray-6;
      text-align: end;

      .input-wrapper {
        margin: 0;
        .vg-input {
          font-size: 1rem;
          color: $vg-gray-3;
          text-align: end;
          @include for-mobile {
            text-align: end !important;
          }
        }
      }
    }
  }

  .budget-limit-container {
    .vg-icon {
      margin-left: 0.3rem;
    }
  }
}
