@import "Assets/styles/Global/_variables";

.vg-computer-performance {
  position: relative;
  min-width: 40rem;
  height: fit-content;
  min-height: 100%;

  @include for-mobile {
    min-width: 17rem;
    margin-bottom: 2rem;
  }

  .computer-performance-row {
    @include flex($direction: row, $align: flex-start, $justify: flex-start);
    border: $border-gray-6;

    margin: 0.7rem 0;
    width: fit-content;

    @include for-mobile {
      border: 0;
      flex-direction: column;
      width: 100%;

      .divider {
        background-color: $vg-gray-5;
        height: 4rem;
        width: 1px;
      }
    }

    .row-header {
      @include flex($justify: flex-start, $align: flex-start);
      color: $vg-black;
      line-height: 1rem;
      padding: 2rem 0 0 2rem;

      width: 10rem;

      @include for-mobile {
        align-items: center;
        border: $border-gray-6;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        padding: 0 1rem;
        width: 100%;

        .img-container {
          @include flex;
          max-width: 7.5rem;
          min-width: 7.5rem;
          img {
            max-height: 5rem;
            min-height: 5rem;
          }
        }

        .vg-icon {
          max-width: 1.5rem;
          min-width: 1.5rem;
          width: 1.5rem;
        }

        .title-container {
          width: 8rem;
          padding: 0.5rem 1rem;
        }
      }

      .title {
        font-size: 0.8rem;
        font-weight: $vg-font-weight-bold;
        margin: 0.5rem 0;
      }

      .description {
        font-size: 0.6rem;
        font-weight: $vg-font-weight-regular;

        .provider {
          white-space: pre-line;
        }
      }
    }

    .vg-machine-type-card {
      margin: 2rem 1rem 1rem;
      width: 9rem;

      @include for-mobile {
        @include full-content-width;

        border: $border-gray-6;
        margin: 0.5rem 0;
      }
    }
  }
}
