@import "Assets/styles/Global/_variables.scss";

.app-streaming-dashboard-container {
  h2 {
    margin-bottom: 1rem;
  }
  .dashboard-row {
    @include full-content-width;
    @include flex($direction: row, $align: stretch, $justify: flex-start);
    gap: 1.5rem;
    margin-bottom: 2rem;
    opacity: 0;
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;

    @include for-mobile {
      flex-direction: column;
    }

    &.flex-column {
      flex-direction: column;
    }

    &.application-upload-row {
      @include flex($direction: column, $align: center, $justify: center);
      height: 28vh;
      min-height: 16rem;
      background-image: url("../../../Assets/images/appStreaming/dashboard-background.png"),
        linear-gradient(239.17deg, #000000 24.19%, #0b0a39 71.05%);
      background-size: 100% 100%;
      @include for-mobile {
        background-size: cover;
        min-height: 10rem;
      }
      color: $vg-white;
      .upload-app-button {
        width: 12rem;
        height: 2.5rem;
        z-index: 9;
      }
      p {
        opacity: 0.7;
        font-size: $vg-font-size-tiny;
      }
    }

    &.use-case-cards-container {
      @include flex($direction: row, $align: stretch, $justify: flex-start);
      gap: 1.5rem;
      max-width: 100%;
      padding-bottom: 0.5rem;
      overflow-x: auto;
    }
  }

  .add-new-app {
    @include flex();
    color: $vg-gray-3;
    height: 17rem;

    @include for-mobile {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      height: unset;
    }

    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    @include for-not-mobile {
      margin-left: 2rem;
    }

    .mobile-new-app {
      @include for-mobile {
        display: flex;
      }
    }
    .add-new-app-button {
      @include flex();
      background-color: $vg-aqua-light;
      border-radius: 100px;
      width: 3rem;
      height: 3rem;
      margin-bottom: 1rem;

      .vg-icon {
        height: 25px;
        width: 25px;
      }
      &:hover {
        opacity: 0.7;
      }
    }

    .mobile-add-new-app-button {
      @include flex();
      background-color: $vg-aqua-light;
      border-radius: 100px;
      width: 1rem;
      height: 1rem;
      margin-bottom: 1rem;
      margin-right: 0.5rem;

      .vg-icon {
        height: 8px;
        width: 8px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .text-button,
    .horizontal-divider {
      margin-bottom: 1rem;
      font-size: 0.8rem;
    }

    .text-button {
      font-size: 0.8rem;
    }

    .horizontal-divider {
      @include full-content-width;
      height: 1px;
      background-color: $vg-gray-5;
    }

    .app-bundle-button {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      color: $vg-blue-baby;

      .vg-icon {
        height: 1.4rem;
        width: 1.4rem;
      }
    }
  }

  .server-status,
  .user-data,
  .sessions {
    border-radius: 6px;
  }
  .activity-monitor-container {
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;

    @include full-content-width;
    @include for-not-mobile {
      display: grid;
      grid-auto-rows: auto;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-columns: 1fr 50%;
      grid-template-rows: repeat(5, 1fr);
      grid-auto-flow: column;
    }
    .server-status,
    .user-data,
    .sessions {
      background-color: $vg-white;
      padding: 1rem;
      h3 {
        color: $vg-gray-2;
        font-size: $vg-font-size-small;
        margin-bottom: 1rem;
      }
    }
    .server-status {
      grid-column: 1;
      grid-row: 1 / 6;

      h3 {
        margin-bottom: 1rem;
      }
      .world-map {
        @include full-content-size;
        #world-map-svg {
          @include for-not-mobile {
            margin-top: -2rem;
          }
        }
      }
    }
    .user-data {
      grid-row: 1 / 3;
      > div {
        @include flex($direction: row, $align: center, $justify: space-between);
        @include for-not-mobile {
          padding: 0.5rem 1rem;
        }
      }
      .info-item {
        @include flex($direction: row, $justify: space-between, $align: center);
        p {
          font-size: $vg-font-size-tiny;
          color: $vg-gray-3;
          span {
            font-size: $vg-font-size-tiny;
            color: $vg-gray-4;
          }
          &:first-child {
            font-size: $vg-font-size-medium;
            color: $vg-black;
          }
        }
      }
    }
    .sessions {
      grid-row: 3 / 6;
    }
  }

  .icon-background {
    height: 3rem;
    width: 3rem;
    @include for-mobile {
      height: 2.5rem;
      width: 2.5rem;
    }
    margin-right: 1rem;
    border-radius: 100px;
    @include flex($direction: row, $justify: center, $align: center);
    flex-grow: 0;
    flex-shrink: 0;

    &.aqua-light {
      background-color: rgba($vg-aqua-light, 0.5);
      .vg-icon {
        * {
          stroke-width: 0.4;
        }
      }
    }
    &.baby-powder {
      background-color: rgba($vg-baby-powder, 0.5);
      .vg-icon {
        * {
          stroke-width: 0.1;
        }
      }
    }
  }

  .graph-container {
    width: 100%;
  }
}

.delete-app-file-confirmation-modal {
  .vg-button {
    background-color: $vg-light-navy;
    font-weight: $vg-font-weight-regular;
  }
  .vg-text-button {
    color: $vg-light-navy;
  }
}

.app-streaming-announcement {
  opacity: 0;
  animation: fade-in 0.5s;
  animation-fill-mode: forwards;
  @include for-mobile {
    display: none;
  }
  img {
    height: 4rem;
  }
  .right-close-icon {
    height: 1.6rem;
    top: 1.2rem;
  }
  p {
    width: 90%;
  }
  a {
    color: $vg-aqua-main;
    font-size: $vg-font-size-tiny;
    font-weight: $vg-font-weight-regular;
  }
}

.vendor-payment-required-announcement {
  img {
    height: 2.5rem;
  }
}

.test-balance-error-modal {
  height: 28rem;

  .description {
    @include full-content-width;
    flex: 1;
    @include flex($direction: column, $align: stretch, $justify: flex-start);
    position: relative;
    h1 {
      font-size: 1.1rem;
      font-weight: $vg-font-weight-semi-bold;
      line-height: 1.5;
      margin-bottom: 0.5rem;
      color: $vg-black;
    }
    p {
      color: $vg-gray-1;
      line-height: 1.5;
      margin: 1rem 2rem;
    }
    .vg-button {
      position: absolute;
      bottom: 3rem;
      line-height: 2;
    }
  }
}

.start-test-session-modal {
  p:nth-child(2) {
    margin-top: 1rem;
  }

  p {
    span {
      font-weight: $vg-font-weight-bold;
    }
  }
  .vg-text-button {
    color: $vg-light-navy;
    &:hover {
      color: $vg-light-navy;
      opacity: 0.7;
    }
  }
}

.youtube-video-modal-overlay {
  background: rgba(128, 128, 128, 0.5);
}
