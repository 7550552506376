@import "Assets/styles/Global/_variables";

$menu-width: 9rem;

.app-menu-pop-up-container {
  position: absolute;
  z-index: 9;
  height: 0;
  width: 0;

  .app-menu-pop-up {
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    width: max-content;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 0.5rem;
    background-color: $vg-white;
    box-shadow: $light-shadow;
    max-height: calc(50vh - 4rem);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    .menu-button {
      @include full-content-width;
      box-sizing: border-box;
      color: $vg-gray-3;
      font-size: $vg-font-size-tiny;
      padding: 0.8rem;
      white-space: nowrap;

      &:first-of-type {
        border-radius: 5px 5px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 5px 5px;
      }
      &:not(.disable-hover) {
        &:hover {
          background-color: $vg-gray-6;
        }
      }
    }

    .vg-text-button {
      padding: 0.5rem 0.2rem;
      text-align: left;
      font-size: 0.75rem;
      font-weight: $vg-font-weight-light;
      white-space: nowrap;

      .vg-icon {
        height: 1.2rem;
        margin-right: 0.8rem;
      }
    }

    .date-container {
      @include flex($direction: row, $justify: flex-start, $align: flex-start);
      gap: 3px;
      font-size: $vg-font-size-tiny;
      font-weight: $vg-font-weight-light;

      p {
        font-weight: $vg-font-weight-medium;
      }
    }

    .divider-container {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}
