@import "Assets/styles/Global/_variables";

.vg-avatar {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border: 3px solid $vg-aqua-light;
  border-radius: 100px;
  overflow: hidden;
  text-align: center;
  background-color: $vg-white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
  }

  p {
    color: $vg-aqua-light;
    font-size: 1.4rem;
    line-height: 30px;
  }
}

.vg-avatar-group {
  @include flex($direction: row-reverse, $justify: flex-start, $align: center);
  padding-left: 20px;
  .vg-avatar {
    margin-left: -20px;
  }
}
