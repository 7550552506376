@import "Assets/styles/Global/_variables.scss";

.organization-member-home {
  padding: 0 13%;

  @include for-mobile {
    padding: 0;
  }

  .vg-banner-container {
    margin: 6vh 0 0;
    padding: 0;
    max-height: 60vh;
    position: relative;
    margin-bottom: 3rem;

    @include for-mobile {
      margin: 0 0 2rem;
    }

    .vg-banner {
      @include flex;

      height: 50vh;
      max-height: 400px;
      width: 100%;
      transition: height 1s;

      &.small {
        height: 25vh;
      }

      &.closed {
        height: 0;
        min-height: 0;
        overflow: hidden;
      }

      @include for-mobile {
        height: 10rem;
      }

      &.other {
        background: url("../../../../Assets/images/dashboardBackgrounds/other.jpg") no-repeat;
      }

      // 'background-size: cover' must come AFTER 'background'. Avoid redundancy by using separate class
      &.bg {
        background-position: center;
        background-size: cover;
      }
    }

    .computer-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $vg-white;
      font-size: 1.4rem;
      font-weight: $vg-font-weight-medium;
      line-height: 2.8rem;

      .teams-badge {
        background-color: $vg-dark-navy;
        width: min-content;
        line-height: 1.5rem;
        letter-spacing: 1px;
        border-radius: 4px;
        padding: 0 1rem;
        font-size: 0.85rem;
      }
    }

    .progress-container {
      padding: 1rem 0;
    }

    .progress-bar {
      @include flex($direction: row);

      .step {
        background: $vg-gray-4;
        height: 0.2rem;
        margin: 0 0.05rem;
        width: 2.5rem;

        &.active {
          background: $vg-white;
        }
      }
    }

    .progress-text {
      @include for-mobile {
        margin-bottom: 1.5rem;
      }
    }

    .progress-text,
    .timer-text {
      color: $vg-white;
      font-size: 0.7rem;
      font-weight: $vg-font-weight-medium;
      line-height: 1rem;
      padding: 0.7rem;
      text-align: center;
    }

    .timer-text {
      font-size: 0.8rem;
      padding: 0.5rem;
    }

    .info-container {
      @include flex($direction: row, $justify: space-between, $align: center);
      position: absolute;
      bottom: 2.2rem;
      background-color: $vg-white;
      font-size: 0.75rem;
      border-radius: 4px;
      padding: 0.6rem 0.8rem;
      color: $vg-gray-3;
      @include for-mobile {
        margin: 1rem;
      }

      .vg-icon {
        height: 1rem;
      }
    }

    .computer-action-button-container {
      @include flex;

      margin-top: -1rem; // use exactly half of button's height to center vertically
      left: calc(50% - 5rem);
      position: absolute;
      .computer-action-button-text {
        position: absolute;
        bottom: 1.5rem;
      }
      .error-container {
        @include flex($direction: row, $justify: space-between);
        margin: 0.5rem 0;
        font-size: 0.8rem;
        color: $vg-white;
        .vg-icon {
          height: 1.5rem;
        }
      }

      .vg-button {
        z-index: 1;
      }
    }

    .shut-down-action,
    .description-secondary {
      color: $vg-white;
      font-size: 0.8rem;
      font-weight: $vg-font-weight-semi-bold;
      line-height: 1rem;
      outline: 0;
      margin-bottom: 1.2rem;
      text-align: center;
      width: 9rem;

      &.shut-down-action {
        cursor: pointer;
      }

      &.description-secondary {
        font-size: 0.8rem;
        font-weight: $vg-font-weight-medium;
      }
    }

    .machine-type-selection-wrapper {
      height: 0;
      transition: height 1s;
      overflow: hidden;
      &.open {
        height: 24rem;
      }
      &.full-height {
        height: 38rem;
      }
    }

    .machine-type-selection-container {
      @include flex;

      background-color: $vg-white;
      height: 100%;
      padding: 0 4rem;

      @include for-mobile {
        margin-top: 0;
      }

      .header {
        font-size: 0.8rem;
        font-weight: $vg-font-weight-medium;
        line-height: 1rem;
      }

      .toggle-display-all {
        font-size: 0.6rem;
        min-height: 1.5rem;
        padding: 0.2rem 0.5rem;
        width: 6rem;
      }

      .footer {
        @include flex($direction: row, $align: center, $justify: space-between);
        @include full-content-width;

        margin: 1rem 0;

        .balance {
          font-size: 0.8rem;
          font-weight: $vg-font-weight-regular;

          .amount {
            font-weight: $vg-font-weight-semi-bold;
          }
        }
      }
    }
  }

  .app-download-announcement {
    margin-top: 0;
  }

  .workstation-overview {
    @include flex;
    margin-top: 2rem;
    @include for-mobile {
      margin: 0 1.5rem;
    }

    .row {
      @include flex($direction: row, $justify: space-between);
      @include full-content-width;

      @include for-mobile {
        flex-wrap: wrap;
      }

      .workstation-overview-item {
        border-bottom: $border-gray-5;
        margin: 0.5rem 0;
        padding: 1rem 0 0;
        width: 45%;

        @include for-mobile {
          @include full-content-width;
        }

        .label {
          color: rgba(0, 0, 0, 0.6);
          font-size: 0.7rem;
          line-height: 1rem;
        }

        .vg-dropdown {
          span {
            font-size: 1rem;
            color: inherit;
          }
        }

        .item-content {
          @include flex($direction: row, $justify: space-between);

          padding: 0.5rem 0;

          @include for-mobile {
            padding: 0.25rem 0;
          }

          .content {
            flex-grow: 1;
          }

          .second-content {
            color: $vg-gray-3;
            font-size: 0.7rem;
          }

          .action {
            color: $vg-gray-3;
            font-size: 0.7rem;
            font-weight: $vg-font-weight-light;
            line-height: 0.9rem;

            .vg-icon {
              width: 1rem;
            }
          }
        }
      }
    }
  }
}
