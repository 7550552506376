@import "Assets/styles/Global/_variables.scss";

.app-budget-modal {
  .box-input-wrapper {
    width: 100%;
    .vg-input {
      .input-wrapper {
        input {
          border: $border-gray-5;
          box-sizing: border-box;
          border-radius: 2px;
          &::placeholder {
            opacity: 0.3;
          }
        }
      }
    }
  }

  .app-budget-input-header {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    margin-bottom: 0.5rem;
  }
  .app-budget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 1rem;

    .app-budget-input {
      width: 100%;

      &.disabled {
        .vg-input::before {
          color: $vg-gray-5;
        }
      }

      .vg-input::before {
        content: "$";
        font-size: 0.8rem;
        font-weight: $vg-font-weight-medium;
        position: absolute;
        left: 0.5rem;
        top: 5px;
        margin: 2px;
        z-index: 1;
      }

      .vg-input {
        position: relative;
        box-sizing: border-box;
        display: inline-block;
        input {
          text-indent: 13px;
        }
      }

      h1 {
        font-size: 0.9rem;
        color: $vg-gray-1;
        font-weight: $vg-font-weight-medium;
      }

      .app-budget-input-container {
        display: flex;

        .vg-input {
          .input-wrapper {
            margin: 0;
            input {
              text-indent: 13px;
              padding: 0.3rem 0.6rem 0.3rem;
              border: $border-gray-5;
              border-radius: 2px;
            }
          }
        }
        .vg-button {
          align-self: auto !important;
          margin-left: 0.5rem;
          width: 12rem !important;
          height: 1.7rem;
        }
      }
    }

    .app-budget-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      width: 100%;
      border: solid 1px $vg-aqua-main;
      border-radius: 5px;
      margin: 0.5rem 0 1.5rem;
      h1 {
        color: $vg-aqua-main;
        font-weight: $vg-font-weight-bold;
        font-size: 0.7rem;
      }
      p {
        color: $vg-gray-1;
        font-size: 0.7rem;
        padding-right: 1rem;
        line-height: 1.6;
      }
      .app-budget-descriptions {
        margin-left: 1.5rem;
      }
      .app-budget-icons {
        display: flex;
        margin-right: 1.2rem;
        gap: 0.5rem;
      }
    }

    .app-budget-info {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 4rem;
      box-shadow: $medium-shadow;
      border-radius: 4px;
      width: 100%;

      p {
        text-align: left;
        margin-left: 0.5rem;
        font-size: 0.7rem;
        color: $vg-gray-1;
        font-weight: $vg-font-weight-light;
      }
    }
  }
}
