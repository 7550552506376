@import "Assets/styles/Global/_variables.scss";

.configure-plan-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 0.15fr 1.2fr;
  column-gap: 1.5rem;
  justify-items: center;

  @include for-mobile {
    width: 17rem;
    margin: 1.5rem;
    overflow-x: auto;
  }
  .vg-slider {
    width: 96%;
  }

  .vertical-divider {
    height: 90%;
    width: 1px;
    display: block;
    background-color: $vg-gray-5;
  }

  .horizontal-divider {
    width: 100%;
    height: 1px;

    display: block;
    background-color: $vg-gray-5;
  }

  .plan-configurations {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    h1 {
      color: $vg-purple-main;
      font-weight: $vg-font-weight-medium;
    }

    .configuration-options {
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 2rem;
    }
  }

  .storage-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3rem;

    .storage-buttons {
      @include flex($direction: row, $justify: center, $align: center);
      justify-content: center;
      height: 2rem;
      border: 1.5px solid $vg-gray-6;
      border-radius: 5px;
      padding: 16px;
      width: 50%;

      button {
        font-size: 1.5rem;
        background-color: transparent;
        border: $border-gray-6;
        border-radius: 3px;
        background-color: $vg-purple-main;
        color: $vg-white;
        line-height: 0.5rem;
        outline: none;
        padding: 10px 8px;
        cursor: pointer;
        &:hover {
          background-color: $vg-light-navy;
        }
        &:disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }

      p {
        padding: 0 5rem;
        min-height: 0rem;
        text-align: center;
        font-size: 1.2rem;
        border-bottom: none;
        width: 100%;
        white-space: nowrap;
      }

      &.files-storage {
        button {
          background-color: $vg-baby-powder;

          &:hover {
            background-color: $vg-light-navy;
          }
        }
      }
    }
  }

  .usage-limit {
    @include flex($direction: column, $justify: center, $align: center);

    height: 100%;
    padding-bottom: 2rem;

    .usage-container {
      flex: 1;
      @include flex($direction: row, $justify: center, $align: center);
    }

    .usage-limit-info {
      font-size: 0.75rem;
      text-align: center;
      line-height: 2;
      color: $vg-gray-3;
      font-weight: 300;
      padding: 0 10%;
    }

    .usage-limit-description {
      @include flex($direction: row, $justify: center, $align: center);
      background-color: $vg-white;
      width: fit-content;
      padding: 0.4rem 1.6rem;
      border-radius: 4px;
      margin-top: 2rem;
      .vg-icon {
        width: 1rem;
      }

      p {
        font-size: $vg-font-size-tiny;
        color: $vg-gray-3;
        margin-left: 0.5rem;
      }
    }

    .vg-checkbox {
      margin-top: 2rem;
      color: $vg-gray-2;

      label {
        font-size: 0.8rem;
        font-weight: 300;
      }
    }
  }

  .plan-budget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    position: relative;
    h1 {
      color: $vg-purple-main;
      font-weight: $vg-font-weight-medium;
    }

    .payment-details {
      th {
        font-size: 0.9rem;
        padding: 5px 10px;
      }

      .selected-options {
        width: 100%;
        h1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.9rem;
          color: $vg-gray-2;
          padding: 2rem 0 0;

          .budget-sub-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              padding: 0;
            }
          }

          .budget-fee {
            display: flex;
            align-items: center;
            white-space: nowrap;

            span {
              color: black;
              font-weight: 500;
            }
            p {
              padding: 0;
              font-size: 0.7rem;
              padding-left: 0.25em;
            }
          }
        }
        .machine-usage {
          display: flex;
          justify-content: space-between;
          font-size: 0.8rem;
          color: $vg-gray-2;
          padding: 1rem 0 0 1.25rem;
          .usage-name {
            &.with-equal {
              position: relative;
              &::before {
                content: "=";
                left: -1rem;
                position: absolute;
                opacity: 0.5;
              }
            }
          }
          span {
            color: black;
            font-weight: 500;
          }
        }

        .usages-list-table {
          max-height: 35vh;
          overflow-y: auto;
          padding-right: 0.2rem;
        }
      }
    }

    .vg-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .save-plan {
    .vg-input {
      border-bottom-color: $vg-gray-5;
    }
    h1 {
      display: flex;
      align-items: center;
      color: black;
      font-size: 1rem;
    }

    .plan-name-input {
      position: relative;

      .v-tooltip {
        position: absolute;
        right: 0;
        top: 0.25rem;
      }
    }
  }

  .proceed-payment-button {
    display: flex;
    justify-content: flex-end;
  }
}
