@import "Assets/styles/Global/_variables.scss";

.event-schedule-container {
  @include flex($direction: row, $align: stretch, $justify: flex-start);
  align-self: center;
  animation: fade-in 1s;
  width: 80%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  h4 {
    color: $vg-aqua-dark;
    font-weight: $vg-font-weight-bold;
  }
  > div {
    @include flex($direction: column, $align: center, $justify: flex-start);
    width: 50%;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0.75rem;
    gap: 0.6rem;

    .day-button {
      padding: 0.3rem 0.7rem;
      background-color: $vg-gray-4;
      color: $white;
      border-radius: 16px;
      &.active {
        background-color: $vg-aqua-main;
        color: $white;
      }
    }

    @include for-not-mobile {
      &:first-of-type {
        padding-right: 2rem;
        border-right: 1px solid rgba(224, 224, 224, 0.6);
      }
    }

    &:last-of-type {
      padding-left: 4rem;
      > div {
        @include full-content-size;
        @include flex($direction: column, $align: center, $justify: space-around);
        color: $vg-gray-3;
        span {
          color: $vg-gray-2;
          white-space: nowrap;
        }
        > div {
          @include full-content-width;
        }
      }
    }
  }
  .horizontal-divider {
    width: 100%;
    height: 1px !important;
    background-color: $vg-aqua-dark;
    margin-bottom: 0.5rem;
  }
  .schedule-event {
    .react-datepicker__current-month {
      font-size: 0.75rem;
    }
    .react-datepicker__day {
      font-size: 0.7rem;
      line-height: 1.2rem;
    }
    .react-datepicker__day-name,
    .react-datepicker__time-name {
      line-height: 1.4rem;
    }
    .react-datepicker__month {
      margin-bottom: 0;
    }
    .react-datepicker__header {
      padding: 0 0 0.4rem 0;
    }
    .react-datepicker__navigation-icon {
      top: -8px;
    }
    .react-datepicker__month-container {
      padding: 0;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 1.4rem;
    }
  }

  .time-input-box {
    @include full-content-width;
    @include flex($direction: row, $justify: center, $align: center);
    border-radius: 3px;
    border: $border-gray-6;
    box-sizing: border-box;
    column-gap: 0.2rem;
    padding: 0.2rem 0.5rem;
    color: $vg-gray-3;
    .vg-icon {
      margin-right: auto;
    }
    .vg-input {
      width: 2rem;
      color: $vg-gray-3;
    }
    .vg-input textarea,
    .vg-input input {
      border-bottom: $border-gray-4;
      text-align: center;
      min-height: 1.5rem;
      margin-bottom: 0;
    }
    .input-container {
      @include flex($direction: row, $justify: center, $align: center);
      flex-grow: 1;
      margin: 0 auto;
      > div {
        &.vg-input {
          &:first-of-type {
            margin-left: -1rem;
          }
        }
      }
    }
  }
  .timezone {
    @include flex($direction: row, $align: center, $justify: flex-start);
    @include for-mobile {
      margin-left: -2rem;
    }
    .vg-dropdown {
      margin-left: 0.4rem;
    }
  }

  .schedule-info-header {
    display: flex;
    flex-direction: row !important;
    position: relative;
    justify-content: center !important;
    height: 1.5rem !important;
    gap: 0.25rem;
    white-space: nowrap;

    .schedule-info-tooltip {
      bottom: 1px;
      width: unset;
    }
  }
}
.stream-password-container {
  width: 50%;
  position: relative;

  .stream-password-button {
    position: absolute;
    right: 0;
    top: 25%;
    padding: 0;

    &:hover {
      box-shadow: none !important;
    }
  }
}

.availability-footer {
  font-size: $vg-font-size-tiny;
  color: $vg-gray-3;
  text-align: center;
  width: 70%;
  align-self: center;
}

.app-budget-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;
  border: solid 1px $vg-aqua-main;
  border-radius: 5px;
  h1 {
    color: $vg-aqua-main;
    font-weight: $vg-font-weight-bold;
    font-size: 0.7rem;
  }
  p {
    color: $vg-gray-1;
    font-size: 0.7rem;
    padding-right: 1rem;
    line-height: 1.6;
  }
  .app-budget-descriptions {
    margin-left: 1.5rem;
  }
  .app-budget-icons {
    display: flex;
    margin-right: 1.2rem;
    gap: 0.5rem;
  }
}

.app-budget-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 4rem;
  box-shadow: $medium-shadow;
  border-radius: 4px;
  width: 100%;

  .vg-icon {
    margin-left: 1rem;
  }

  p {
    text-align: left;
    margin-left: 0.5rem;
    font-size: 0.7rem;
    color: $vg-gray-1;
    font-weight: $vg-font-weight-light;
  }
}
