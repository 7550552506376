@import "Assets/styles/Global/_variables.scss";

.vg-link-button {
  padding: 0 0.8rem;
  width: 9rem;
  min-height: 2rem;
  margin: 0;
  box-sizing: border-box;
  border-radius: $vg-border-radius-small;
  color: $vg-white;
  background-color: $vg-purple-main;

  font-size: 0.8rem;
  font-weight: $vg-font-weight-medium;
  line-height: 2rem;

  cursor: pointer;
  text-align: center;
  outline: none;
  text-decoration: none;

  border: none;

  &.blue {
    background-color: $vg-blue-baby;
  }

  /* Size properties */
  &.big {
    width: $big-button-width;
  }

  &.block {
    @include full-content-width;
  }
}
