@import "Assets/styles/Global/_variables";

@import "Assets/styles/Global/_variables";

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  .upload-overlay-container {
    @include flex($direction: row);
  }
}

.upload-container {
  @include flex;
  position: relative;
  box-sizing: border-box;
  backdrop-filter: blur(50px);
  color: $vg-white;
  transition: background-color 0.5s;
  &.over {
    &::after {
      border-color: $vg-aqua-light;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    height: calc(100% - 1.5rem);
    width: calc(100% - 1.5rem);
    border: 2px dashed $vg-white;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .upload-info {
    width: 60%;
    max-width: 18rem;
    text-align: center;
    .vg-icon {
      height: 8rem;
      width: 8rem;
    }

    h1 {
      margin-bottom: 0.2rem;
      font-size: 1.2rem;
      font-weight: $vg-font-weight-medium;
      span {
        font-weight: $vg-font-weight-semi-bold;
      }
    }
    p {
      font-size: 0.8rem;
      font-weight: $vg-font-weight-light;
    }
  }
}

.workstation-upload-container {
  height: 100vh;
  width: calc(100vw - #{$dock-width});
  background-color: rgba($color: $vg-purple-main, $alpha: 0.5);
  &.over {
    background-color: rgba($color: $vg-purple-main, $alpha: 1);
  }
  &.full {
    width: 100vw;
  }
}

.dock-upload-container {
  width: $dock-width;
  height: 100vh;
  right: 0;
  background-color: rgba($color: $vg-purple-light, $alpha: 0.5);
  &.over {
    background-color: rgba($color: $vg-purple-light, $alpha: 1);
  }
}
