@import "Assets/styles/Global/_variables.scss";

.StripeElement {
  height: 1.5rem;
  margin: 0.4rem 0 1.5rem;
  max-width: 600px;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  border-radius: 0;
  font-family: "vagonRails";
  border-bottom: $border-black;
  @include for-mobile {
    max-width: inherit;
  }
}

.StripeElement::placeholder {
  color: #aab7c4;
}

.StripeElement--focus {
  box-shadow: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.CardField-input-wrapper {
  position: initial !important;
}

.payment-method-form {
  @include flex($direction: column, $align: flex-end, $justify: flex-start);
  height: 100%;
  width: 100%;
  min-width: 260px;
  max-width: 500px;

  @include for-mobile {
    align-content: flex-start;
  }
  &.divided {
    position: relative;
    overflow: hidden;
    .payment-section {
      position: absolute;
      height: 100%;
      top: 0;
      flex: 0 0 100%;
      transition: top 1s;
    }
    .billing-section {
      top: 100%;
    }
    .card-section {
      margin-bottom: 2rem;
      .card-input-label {
        color: $vg-black;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.4rem;
      }
    }
    &.billing-part {
      .card-section {
        top: -100%;
      }
      .billing-section {
        top: 0%;
      }
    }

    .country-input-line {
      @include flex($direction: column, $align: flex-start, $justify: flex-start);
      width: 100%;
    }
  }
  .payment-section {
    width: 100%;
  }
}

.country-input-line {
  @include flex($direction: row, $align: flex-start, $justify: flex-start);
  .vg-dropdown {
    margin-right: 1.5rem;
  }
}
