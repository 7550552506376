@import "Assets/styles/Global/_variables.scss";

.organization-member-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0 1rem;
  height: 7rem;

  .header {
    color: $vg-black;
    font-size: 1rem;
    font-weight: $vg-font-weight-medium;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }

  .description {
    color: $vg-gray-3;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-regular;
    line-height: 1.1rem;
    margin-bottom: 1rem;
  }

  .label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    font-weight: $vg-font-weight-medium;
    line-height: 1.1rem;
    padding: 0.3rem 0;
  }

  .information-item {
    color: $vg-black;
    font-size: 1rem;
    font-weight: $vg-font-weight-light;
    line-height: 1.5rem;
    .change-password-button {
      margin-top: 0.5rem;
    }
    .name-info {
      font-size: 0.9rem;
    }
  }

  .divider-container {
    margin: 2rem 0;
    margin-top: auto;
  }

  .footer {
    @include flex($direction: row, $justify: flex-start, $align: flex-start);
    margin-bottom: 2rem;

    .column {
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      gap: 0.6rem;
      flex: 1;

      p:nth-of-type(1) {
        color: $vg-black;
        font-size: 0.9rem;
        font-weight: $vg-font-weight-medium;
      }

      p {
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.75rem;
        font-weight: $vg-font-weight-medium;
      }
    }
  }

  .change-password-form {
    @include full-content-width();
    @include flex($justify: flex-start, $align: flex-start);
    position: relative;
    padding-top: 1.5rem;
    .top-right-close-icon {
      height: 1.5rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 1.5rem;
      cursor: pointer;
    }
    .password-input-wrapper {
      width: 40%;
      @include for-mobile {
        width: 100%;
      }
    }

    .button {
      align-self: flex-end;
    }
  }

  .change-password-button {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  .organization-member-profile-container {
    display: flex;
    width: 100%;

    .information-item {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      width: 100%;
      .name-info {
        margin-bottom: 0.5rem;
      }
    }
  }

  .additional-information-container {
    width: 100%;
    .time-zone-value-container {
      display: flex;
      justify-content: flex-start;
      gap: 0.5rem;
      .info-value {
        font-size: 0.9rem;
        font-weight: 300;
      }
    }
  }
}
