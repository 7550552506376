@import "Assets/styles/Global/_variables";

.team-dashboard-container {
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  height: 100%;
  position: relative;
  width: 100%;

  @include for-mobile {
    max-height: calc(95vh - 11rem);
  }

  .vg-loader {
    width: 100%;
  }

  .user-manager-actions-bar {
    @include flex($direction: row, $justify: space-between, $align: center);
    @include full-content-width();
    margin-bottom: 2rem;
    @include for-mobile {
      flex-direction: column;
      align-items: flex-start;
      height: 6rem;
      gap: 1rem;
    }

    .user-manager-left-actions {
      width: 25%;
      @include for-mobile {
        width: 100%;
      }

      @include flex($direction: row, $justify: flex-start, $align: center);
      .v-tooltip {
        cursor: pointer;
        margin-right: 1rem;
        .vg-tooltip {
          width: auto;
          white-space: nowrap;
        }
      }
      .left-action-button {
        @include flex;
        width: 4rem;
        height: 2.5rem;
        text-align: center;
        background-color: $vg-white;
        border: $border-gray-6;
        border-radius: 3px;
        .vg-icon {
          width: 1.8rem;
        }
        &:hover {
          background-color: $vg-gray-7;
        }
      }

      .vg-search-input-container {
        width: 100%;
      }
    }
    .user-manager-right-actions {
      @include flex($direction: row, $justify: flex-end, $align: center);
      @include full-content-height();
      gap: 1rem;
      @include for-mobile {
        justify-content: center;
        width: 100%;
      }
      .vertical-divider {
        width: 1px;
        height: 1rem;
        background-color: $vg-gray-4;
        margin: 0 0.5rem;
        opacity: 0.5;
      }

      .vg-icon {
        * {
          fill: $vg-gray-4;
        }
      }

      .div-button-container {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        &:hover {
          p {
            color: $vg-aqua-main;
            transition: color 0.1s ease-in-out;
          }

          .vg-icon {
            * {
              fill: $vg-aqua-main;
              transition: fill 0.1s ease-in-out;
            }
          }
        }

        .vg-icon {
          height: 1.25rem;
          width: 1.25rem;
        }
      }

      p {
        font-size: 0.8rem;
        color: $vg-gray-4;
      }
    }
  }

  .payment-failed-container {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: $vg-white;
    margin-bottom: 2rem;
    padding: 1rem;
    gap: 1rem;

    img {
      margin-left: 1rem;
      width: 3rem;
    }

    .information {
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      width: 70%;
      margin-left: 1rem;

      h1 {
        font-size: 0.9rem;
      }

      p {
        font-size: 0.8rem;
        color: $vg-gray-4;
      }
    }

    .vertical-divider {
      width: 1px;
      height: 100%;
      background-color: $vg-gray-4;
      margin: 0px 0.3rem;
    }

    .vg-text-button {
      font-size: 0.8rem;
      margin: 0 auto;
    }
  }

  .no-users-content {
    box-sizing: border-box;
    width: 100%;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    padding: 2rem;
    background-color: white;
    height: 100%;

    @include for-mobile {
      gap: 0.5rem;
    }

    .card {
      width: 27%;
      height: 100%;
      max-height: 12rem;
      margin: auto 1rem;
      padding: 5rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      transition: 0.3s background-color ease-in-out;
      justify-content: center;

      @include for-mobile {
        width: 50%;
        gap: unset;
        margin: 0rem;
        padding: 1rem 2rem;
      }

      &:hover {
        border-color: $vg-aqua-dark;
        cursor: pointer;
      }
      .vg-icon {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1rem;
        @include for-mobile {
          min-width: 2rem;
          min-height: 2rem;
        }
      }

      p {
        text-align: center;
        font-size: 0.8rem;
        font-weight: $vg-font-weight-regular;

        &:first-of-type {
          color: $vg-aqua-dark;
          font-size: $vg-font-size-medium;
          font-weight: $vg-font-weight-semi-bold;
        }
      }

      border: 1px solid $vg-gray-5;
      border-radius: 3px;
    }
  }
}

.assign-modal-container {
  .checkbox-cell {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 4rem;
}
