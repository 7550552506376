@import "Assets/styles/Global/_variables";

.profile-picture {
  display: flex;
  position: relative;
  .vg-loader {
    width: 5rem;
    height: 5rem;
  }

  .profile-image {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
    background-color: #47c2b533;
    border-radius: 100%;
    text-align: center;
    color: $vg-aqua-dark;
    font-weight: $vg-font-weight-semi-bold;
    @include flex($align: center, $justify: center);
  }
  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .profile-edit-button {
    position: absolute;
    top: 3.5rem;
    left: 3.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $vg-purple-main;
    border-radius: 50%;
    opacity: 0.5;
    &.shake {
      animation: appear 0.2s 1 linear, shake 0.5s;
    }
    &:hover {
      opacity: 0.75;
    }
  }
  .upload-error {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 2px;
    bottom: -0.3rem;
    color: #4f4f4f;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    font-size: 0.6rem;
    left: calc(100% + 0.5rem);
    min-height: 1.4rem;
    padding: 0.4rem;
    position: absolute;
    width: fit-content;
    white-space: nowrap;
    opacity: 1 !important;
  }
}
