@import "Assets/styles/Global/_variables.scss";

.billing-address-form {
  @include flex($direction: column, $align: flex-end, $justify: flex-start);
  height: 100%;
  width: 100%;
  min-width: 260px;
  max-width: 500px;
  margin-top: 20px;

  @include for-mobile {
    align-content: flex-start;
  }
  .address-input-line {
    width: 100%;
  }

  .tax-country-input-line {
    @include flex($direction: row, $align: flex-start, $justify: flex-start);
    .vg-dropdown {
      margin-left: 40px;
    }
  }
  .city-input-line {
    @include flex($direction: row, $align: flex-start, $justify: flex-start);
    .vg-input:nth-of-type(2) {
      margin-left: 40px;
    }
  }
}
