@import "Assets/styles/Global/_variables.scss";

.grouped-machine-types {
  @include flex($direction: row, $align: center, $justify: flex-start);
  gap: 1rem;
  margin-top: 1rem;
}

.machine-type-groups {
  @include flex($direction: row, $align: center, $justify: flex-start);
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;

  .descriptions {
    margin-left: 0.5rem;
  }

  .title {
    font-size: $vg-font-size-tiny;
    font-weight: $vg-font-weight-bold;
  }

  .description-text {
    color: $vg-gray-3;
    font-size: 0.65rem;

    span {
      span {
        font-weight: $vg-font-weight-bold;
      }
    }
  }
}