@import "Assets/styles/Global/_variables";

.teams-create-template-container {
  @include flex($direction: column, $justify: space-between, $align: flex-start);
  @include full-viewport-height;
  background-color: $vg-gray-7;

  .teams-create-template-content {
    width: 100%;
    min-height: calc(100% - 6.9rem);
    height: 100%;
    @include for-mobile {
      height: auto;
    }
  }

  .action-cards {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    padding: 2rem;
    .action-card-container {
      display: flex;
      justify-content: space-between;
      width: 40rem;
    }

    @include for-mobile {
      padding: unset;
      flex-direction: column;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: unset;
      height: unset;
      padding: 0 1.5rem 2rem;
    }

    .card {
      width: 15%;
      padding: 4rem 1rem;
      height: 12rem;
      min-width: 16rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      transition: 0.3s background-color ease-in-out;
      &:hover {
        background-color: $vg-gray-6;
        cursor: pointer;
      }
      .vg-icon {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1rem;
      }

      p {
        text-align: center;
        font-size: 0.85rem;
        font-weight: $vg-font-weight-light;
        line-height: 2;
        padding: 0 2rem;
        color: $vg-gray-2;

        &:first-of-type {
          color: $vg-aqua-dark;
          font-size: $vg-font-size-medium;
          font-weight: $vg-font-weight-semi-bold;
        }
      }

      border: 1px solid $vg-gray-5;
      border-radius: 3px;

      &.disabled {
        opacity: 1;

        & > * {
          opacity: 0.5;
        }

        p {
          color: $vg-gray-3;
        }
      }

      @include for-mobile {
        padding: 1rem 0rem;
      }
    }
  }
}
