@import "Assets/styles/Global/_variables";

.referral-container {
  @include flex($direction: column);
  position: relative;

  .referral-info {
    @include flex($direction: column);
    width: 100%;
    height: 14rem;
    background-image: url("../../../../Assets/images/referral/background.jpg");
    background-repeat: no-repeat, no-repeat;
    background-size: cover;
    color: $vg-white;
    text-align: center;

    img {
      height: 4rem;
      margin-top: 1rem;
    }

    h1 {
      font-size: 1rem;
      margin-top: 1rem;
      font-weight: $vg-font-weight-semi-bold;
    }

    p {
      text-align: center;
      max-width: 20rem;
      margin: 0.5rem 0;
      font-size: 0.95rem;
      font-weight: $vg-font-weight-light;
      white-space: break-spaces;
    }
  }

  .referral-send-email {
    top: 12.8rem;
    position: absolute;
  }

  .referral-details-container {
    @include flex($direction: column, $justify: center, $align: center);
    width: 100%;
    height: fit-content;
    background: $vg-white;
    box-shadow: $medium-shadow;
    border-radius: 3px;
    padding: 2rem 0;

    .referral-details {
      @include flex($direction: row, $justify: center, $align: center);
      flex-wrap: wrap;
      width: 100%;

      h2 {
        color: $vg-gray-2;
      }

      p {
        color: $vg-gray-3;
      }

      .details-section {
        width: 40%;
        max-width: 600px;
        min-height: 12rem;
        height: fit-content;
        min-width: 27rem;
        padding: 2rem 3rem 0;
        box-sizing: border-box;

        &:nth-of-type(1) {
          padding-left: 2rem;
        }

        &.wide {
          padding: 2rem 0 0;
        }

        @include for-mobile {
          width: 100%;
          padding: 3rem 0.7rem 0 1rem;
          min-width: unset;
          &:nth-of-type(1) {
            padding-left: 1rem;
          }
        }
      }

      .referral-link-section {
        width: 100%;
        max-width: 400px;
        height: 5rem;
        margin-left: auto;

        @include for-mobile {
          margin-right: auto;
        }

        h2 {
          font-weight: $vg-font-weight-medium;
        }

        .referral-link {
          @include flex($direction: row, $justify: space-between, $align: center);
          height: 2rem;
          margin: auto 0;
          font-size: 1rem;

          @include for-mobile {
            p {
              font-size: 0.9rem;
              white-space: nowrap;
            }
          }

          .vg-text-button {
            font-size: 0.9rem;
          }
        }
      }

      .social-media-section {
        @include flex($direction: row, $justify: space-between, $align: center);
        width: 100%;
        max-width: 400px;
        height: 4rem;
        margin-left: auto;

        @include for-mobile {
          margin-right: auto;
        }

        .social-media-button {
          text-align: center;

          .vg-text-button {
            color: $vg-gray-3;
          }

          .vg-icon {
            height: 1.5rem;
          }
        }
      }

      .divider {
        border-left: 2px solid $vg-gray-5;
        height: 10rem;
        margin: 0 1rem;

        @media (max-width: 1500px) {
          display: none;
        }
      }

      .details-section-row {
        @include flex($direction: row, $justify: flex-start, $align: flex-end);
        flex-wrap: wrap;

        &.centered {
          padding-right: 0;
          justify-content: center;
        }

        &:nth-of-type(1) {
          margin-bottom: 1.5rem;
        }

        &:not(.centered) {
          @include for-mobile {
            transform: translateX(25%);
          }
        }

        .referral-detail-item {
          width: 5rem;
          display: inline-block;
          text-align: start;

          &:nth-of-type(1) {
            margin-right: 4rem;
          }

          h2 {
            margin-bottom: 0.2rem;
          }
        }

        .referral-info-item {
          text-align: center;
          padding: 0 1rem;
          min-width: 7rem;
          max-width: 7rem;

          img {
            min-height: 4rem;
            max-height: 4rem;
          }

          h2 {
            white-space: nowrap;
            margin: 0.3rem 0;
            font-size: 0.8rem;
            color: $vg-gray-2;
            font-weight: $vg-font-weight-semi-bold;
          }

          p {
            font-size: 0.7rem;
            height: 6rem;
          }
        }

        .vg-text-button {
          font-size: 0.8rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.referral-limit-reminder {
  @include flex($direction: row, $align: center, $justify: flex-start);

  width: 30rem;
  max-width: 100%;
  height: 5rem;
  margin-top: 2rem;

  background: $vg-white;
  box-shadow: $light-shadow;
  border-radius: 5px;

  h2 {
    color: $vg-gray-2;
  }

  p {
    font-size: 0.8rem;
    color: $vg-gray-3;
    padding-right: 1rem;
  }

  img {
    padding: 1rem;
    height: 3rem;
  }
}
