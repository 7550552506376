@import "Assets/styles/Global/_variables";

.computer-storage-modal-content {
  @include flex($direction: column, $justify: center, $align: center);
  width: 100%;

  .storage-buttons {
    @include flex($direction: row, $justify: center, $align: center);
    justify-content: center;
    height: 2rem;
    margin-top: 4rem;
    border: 1.5px solid $vg-gray-6;
    border-radius: 5px;
    padding: 16px;
    width: 75%;

    button {
      font-size: 1.5rem;
      background-color: transparent;
      border: $border-gray-6;
      border-radius: 3px;
      background-color: $vg-baby-powder;
      color: $vg-white;
      line-height: 0.5rem;
      outline: none;
      padding: 10px 8px;
      cursor: pointer;
      &:hover {
        background-color: $vg-light-navy;
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }

    p {
      padding: 0 5rem;
      min-height: 0rem;
      text-align: center;
      font-size: 1.2rem;
      border-bottom: none;
      width: 100%;
      white-space: nowrap;
    }
  }

  .storage-price-info {
    box-shadow: $medium-shadow;
    border-radius: 3px;
    margin-top: 3rem;
    padding: 1rem;
    text-align: center;
    font-size: 0.75rem;
    color: $vg-gray-2;

    .insufficient-balance {
      @include flex($direction: row, $justify: center, $align: center);
      gap: 2rem;
      .vg-text-button {
        margin-left: auto;
      }
    }
  }

  .buttons-container {
    @include flex($direction: row, $justify: space-between, $align: center);
    margin-top: 3rem;
    width: 100%;

    &.single-button {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}
