@import "Assets/styles/Global/_variables.scss";

.page-loader-container {
  position: fixed;
  top: 0;
  overflow: hidden;
  width: inherit;
  &.inside-component {
    position: relative;
    width: 100%;
  }
  .loader-bar {
    position: relative;
    height: 3px;
    background-color: $vg-purple-main;
    &.transition {
      transition-timing-function: ease-in-out;
      transition-property: left, width;
    }
    &.light {
      background-color: $vg-purple-light;
    }
  }
}
