@import "Assets/styles/Global/_variables.scss";

.analytic-chart-table {
  max-height: 20rem;
  width: 100%;
  min-height: 20rem;
  padding-top: 0.5rem;
  background-color: $vg-white;

  @include for-mobile {
    overflow-x: auto;
    max-height: 15rem;
    min-height: 12rem;
    width: 105%;
  }
  .header-line {
    @include for-mobile {
      width: 20rem;
    }
  }
  .table-body {
    @include for-mobile {
      width: 20rem;
    }
  }

  .header-cell {
    margin-left: 0.2rem;
  }

  .table-cell {
    margin-left: 0.3rem;
  }

  .table-body .table-row:nth-child(n) {
    .usage-bar {
      background-color: #73d4c780;
    }
  }

  .table-body .table-row:nth-child(3n) {
    .usage-bar {
      background-color: #73d4c7;
    }
  }

  .usage-bar-container {
    height: 1.5rem;
    width: 100%;
    position: relative;

    .usage-bar {
      height: 1.5rem;
      border-radius: 0 6px 6px 0;
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      color: #333333;
      font-size: 0.7rem;

      span {
        padding-left: 8px;
        white-space: nowrap;
      }
    }
  }

  .table-cell {
    width: calc(50% - 1rem);
    margin-right: 0.2rem;

    &:not(:hover) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
