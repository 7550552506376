@import "Assets/styles/Global/_variables";

.app-list-modal {
  height: 30rem;
  .description {
    @include full-content-width;
    margin-top: 1rem;
  }
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100px;
    display: inline-block;
    margin-right: 0.5rem;
  }
  .header {
    font-weight: $vg-font-weight-regular !important;
  }
  .icon-buttons {
    @include flex($direction: row);
    .vg-icon:not(:last-of-type) {
      margin-right: 1rem;
    }
    padding-top: 0.2rem;
    .vg-icon {
      display: inline-block;
      height: 1rem;
      color: $vg-gray-4;
      * {
        fill: $vg-gray-4;
      }
    }
    .vg-tooltip {
      width: 5rem;
      color: $vg-gray-3;
    }
  }
  th,
  td {
    padding: 0.4rem;
    vertical-align: middle;
  }
  .scrollable-table-container {
    @include full-content-width;
    position: relative;
    height: 22rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
}
