@import "Assets/styles/Global/_variables.scss";

.div-button-container {
  &.clickable {
    cursor: pointer;
  }

  &shadow {
    &:hover {
      box-shadow: $light-shadow;
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.2;
  }
}
