@import "Assets/styles/Global/_variables.scss";

.error-component-container {
  @include flex($direction: row, $align: center, $justify: center);
  @include full-viewport-size;

  .nothing-img {
    height: 30rem;
    margin-right: 2rem;
    @include for-mobile {
      display: none;
    }
  }
  .error-info-container {
    max-width: 24rem;
  }

  .logo {
    width: 6rem;
  }

  .something-went-wrong {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: $vg-purple-main;
  }

  .vg-button {
    margin-top: 1rem;
  }
}
