@import "Assets/styles/Global/_variables.scss";

$error-padding: 0.4rem;

.stream-table {
  position: relative;
  margin-bottom: 2rem;
  h3 {
    margin-bottom: 0.5rem;
  }
  min-width: 54rem;

  @include for-mobile {
    min-width: unset;
    width: 100%;
  }

  &.stream-table {
    .header-line {
      @include for-mobile {
        width: 55rem;
      }
    }

    .table-body {
      @include for-mobile {
        width: 55rem;
      }
    }
  }

  .name-cell {
    display: flex;
    align-items: center;
    p {
      line-height: 1rem;
    }
    .name-cell-icons {
      padding-right: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.2rem;
      margin-top: -3px;
    }

    .app-status {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100px;
      flex-shrink: 0;
      &.animated {
        animation: pulse 1s infinite;
      }
    }
  }

  .boosting-badge {
    display: flex;
    align-items: center;
    padding: 0.1rem 0.2rem;
    border-radius: 6px;
    animation: textColorChange 2s infinite;

    p {
      line-height: 1rem;
      font-weight: 500;
      font-size: 0.75rem;
    }

    background-color: #f8f8f8;
    &.even {
      background-color: $vg-white;
    }
    span {
      font-weight: 300;
      font-size: 0.7rem;
    }
  }

  .lightning-status {
    width: 1.2rem;
    height: 1.3rem;

    &.animated {
      animation: pulse 1s infinite;
    }

    &.boosting {
      * {
        animation: colorChange 2s infinite;
      }
    }
  }

  .stream-budget-info {
    margin-top: -2px;
    width: 1.2rem;
    height: 1.3rem;
    margin-right: 3px;
  }

  .pixel-streaming-status {
    width: 1.2rem;
    height: 1.3rem;
    margin: 0 0.35rem;
  }

  .pixel-streaming-container-count {
    width: 1.2rem;
    height: 1.3rem;
  }

  .icon-buttons {
    animation: fade-in 1s;
    @include flex($direction: row);
    height: 1rem;
    margin-left: auto;
    padding-top: 0.2rem;
    .vg-icon {
      animation: fade-in 1s;
      display: inline-block;
      height: 1rem;
      width: 1.5rem;
    }
    .vg-tooltip {
      width: fit-content;
      color: $vg-gray-3;
      @include for-mobile {
        display: none;
      }
    }

    .vertical-divider {
      background-color: $vg-gray-4;
      width: 1px;
      height: 80%;
      margin: 0 0.4rem;
    }
  }

  .edit-stream-link {
    @include flex($direction: row, $align: center);
    min-width: 10rem;

    .stream-link-input {
      margin: 0;
      padding: 0;
      border: none;
      background-color: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      width: 16rem;
      outline: none;
      white-space: nowrap;
      &::placeholder {
        opacity: 0.3;
      }

      &.editing {
        border-bottom: 1px solid $vg-gray-4;
      }
    }

    .edit-link-icon {
      margin-left: 2rem;
      width: 1.5rem;
      height: 1.4rem;
      position: relative;
    }

    .error-tooltip {
      margin-left: 1rem;

      .link-error-icon {
        margin-left: -1rem;
      }
    }

    .error-wrapper {
      @include flex();
      background-color: $vg-white;
      border-radius: 2px;
      bottom: 0.8rem;
      color: $vg-gray-2;
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
      font-size: 0.6rem;
      min-height: 0.7rem;
      min-width: 7rem;
      padding: $error-padding;
      margin-left: 0.6rem;
      margin-top: -2px;

      width: 4vw;

      &::after {
        border-color: transparent $vg-white transparent transparent;
        border-style: solid;
        border-width: $error-padding;
        content: "";
        margin-top: -$error-padding;
        position: absolute;
        right: 100%; /* To the left of the tooltip */
        top: 50%;
      }
    }
  }

  .stream-link {
    white-space: nowrap;
    min-width: 10rem;
  }

  .update-url-button {
    &::after {
      font-family: $default-font-family;
      font-size: 14px;
      color: $vg-baby-powder;
      content: "PRO";
    }

    &.disabled {
      opacity: 0.2;
    }
  }

  .div-button-container {
    &.disabled {
      opacity: 0.5 !important;
    }
  }

  .vg-tooltip-hoverable {
    height: 1.2rem;
  }

  .capacity-col-header {
    @include flex($direction: row, $align: center, $justify: flex-start);

    svg {
      margin-bottom: 2px;
      margin-left: 4px;
    }
  }

  .app-name {
    display: flex;
    .stream-app-budget-info {
      margin-top: 2px;
      width: 1.2rem;
      height: 1.3rem;
      margin-left: 3px;
    }
  }

  .stream-table-container {
    @include for-mobile {
      overflow-x: auto;
      height: auto;
    }
  }

  .stream-table-action-button {
    &.disabled {
      opacity: 0.3;
      animation: none;
    }
  }
}

@keyframes colorChange {
  0% {
    fill: #828282;
  }
  50% {
    fill: #e0e0e0;
  }
  100% {
    fill: #828282;
  }
}

@keyframes textColorChange {
  0% {
    color: #828282;
  }
  50% {
    color: #e0e0e0;
  }
  100% {
    color: #828282;
  }
}
