@import "Assets/styles/Global/_variables.scss";

.vg-search-input-container {
  display: flex;
  border-radius: 5px;
  width: max-content;
  box-sizing: border-box;
  border: 1px solid $vg-gray-7;

  background-color: white;

  input {
    padding: 0.4rem 0.5rem;
    border-radius: 5px 0 0 5px;
    border: none;

    &:focus {
      outline: none;
    }
    width: 100%;
  }

  .vg-icon {
    border-radius: 5px;
    height: 2rem;
    width: 2rem;
    padding: 0.2rem 0.4rem;
    margin-left: auto;
  }

  .vg-icon path {
    stroke: $vg-gray-5;
  }

  .vg-icon {
    background-color: white;
  }
}
