@import "Assets/styles/Global/_variables.scss";

.vg-editable-cell-input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid $vg-gray-4;
  outline: none;
}

.vg-editable-cell-label {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;

  .vg-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: -0.2rem;
    display: none;
  }

  &:hover {
    .vg-icon {
      display: block;
    }
  }
}
