@import "Assets/styles/Global/_variables";

.member-team-info-container {
  @include flex($direction: column);
  padding: 4rem 150px;
  background: $vg-white;
  position: relative;

  @include for-mobile {
    padding: 150px 1rem 200px;
  }

  .left-corner {
    position: absolute;
    left: 0;
    top: 0;
    height: 200px;
  }

  h1 {
    font-size: 1.8rem;
  }

  .right-corner {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 150px;
  }

  .team-members-container {
    @include flex($direction: row);
    flex-wrap: wrap;
    margin-top: 2rem;

    .vg-button {
      margin: 1rem;
      border-radius: 4px;
    }
  }
}
