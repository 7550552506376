@import "Assets/styles/Global/_variables.scss";

.permission-information-modal {
  @include for-mobile {
    height: 30rem;
    overflow-x: auto;
  }
  .permission-information-modal-content {
    padding-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .permission {
      display: flex;
      align-items: flex-start;
      gap: 0.2rem;

      .icon-background {
        min-height: 2.6rem;
        min-width: 2.6rem;
        border-radius: 50%;
        background-color: $vg-gray-5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        .vg-icon {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
}
