@import "Assets/styles/Global/_variables";

.modal-result {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 1rem;
    font-size: $vg-font-size-medium;
    color: $vg-aqua-dark;
    font-weight: $vg-font-weight-semi-bold;
  }

  p {
    margin-top: 0.5rem 2rem;
    font-size: 0.85rem;
    color: $vg-gray-3;
    text-align: center;
    line-height: 2;
    margin-top: 0.5rem;
    white-space: break-spaces;
  }

  &.error {
    h1 {
      color: $vg-black;
    }
  }

  .vg-icon {
    margin: 3rem 0 2rem;
    width: 5rem;
    height: 5rem;
  }

  .vg-text-button {
    margin-top: 2rem;
    margin-left: auto;
  }

  .buttons-container {
    @include flex($direction: row, $justify: space-between, $align: center);
    margin-top: 3rem;
    width: 100%;

    &.single-button {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}
