@import "Assets/styles/Global/_variables";

.files-tab {
  height: fit-content;
  margin-bottom: 1rem;
  .tab-title-item {
    margin: 0.75rem 0;
  }
  ::-webkit-scrollbar {
    background-color: $vg-white;
  }
  .menu-item {
    margin: 0.5rem 0;
    .vg-text-button {
      padding: 0;
    }
  }
  .search-input {
    height: 2rem;
    width: 20rem;
    .vg-input {
      input {
        padding: 0 0 0 1rem;
        background-color: $vg-white;
        height: 1.5rem;
        line-height: 1.5rem;
      }
    }
    .vg-icon {
      padding: 0 0.6rem 0;
      width: 2rem;
      height: 2rem;
      * {
        fill: unset;
      }
    }
  }

  .vg-loader {
    height: calc(100vh - 18rem);
  }
  .file-manager-table {
    height: calc(100vh - 18rem);
    .table-wrapper .table-body .table-body-row {
      min-height: 2rem;
      justify-content: space-between;
      .table-body-column {
        flex: 0 1 0%;
        align-items: center;
        .cell-content {
          display: flex;
          min-width: 0;
          padding-left: 0;
          padding-right: 0.5rem;
          align-items: center;
          //height: 1rem;
          font-size: 0.7rem;
          font-weight: $vg-font-weight-light;
          color: $vg-gray-4;
          .vg-icon {
            height: 1rem;
            width: 1rem;
          }
          .vg-tooltip {
            max-width: fit-content;
          }
          .vg-tooltip-hoverable {
            height: 0.9rem;
            margin-left: 0.5rem;
            .vg-icon {
              height: 0.9rem;
              width: 0.9rem;
            }
          }
        }
        &:first-of-type {
          padding-left: 0.5rem;
          padding-right: 0;
          .cell-content {
            color: $vg-gray-3;
          }
        }
      }
    }
  }

  .empty-folder {
    @include flex;
    width: 100%;
    @include for-mobile {
      width: 60%;
    }

    img {
      display: block;
      height: 8rem;
      margin: 2rem 0 0.5rem;
    }

    h1 {
      font-weight: $vg-font-weight-light;
      margin-bottom: 0.5rem;
    }

    p {
      @include for-mobile {
        font-size: 0.7rem;
      }
      max-width: 15rem;
      font-size: $vg-font-size-tiny;
      color: $vg-gray-3;
      text-align: center;
      span {
        color: $vg-purple-light;
        font-weight: $vg-font-weight-medium;
      }
    }
  }
}
