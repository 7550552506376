@import "Assets/styles/Global/_variables.scss";

.app-list-card-container {
  width: 99%;

  .horizontal-app-card {
    width: 100%;
    height: 3rem;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.5s;

    &:hover {
      border: 1px solid #47c2b5;
    }
  }

  .image-container {
    box-sizing: border-box;
    height: 100%;
    width: 3rem;
    border-radius: 5px 0 0 5px;
    padding: 0.5rem;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .app-name {
    p {
      font-size: 0.85rem;
      font-weight: 400;
    }
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }

  .app-caret {
    margin-left: auto;
    margin-right: 1rem;
  }

  .selected {
    border: 1px solid #47c2b5;
  }

  .bundled-app-badge {
    border: 1px solid $vg-aqua-dark;
    color: $vg-aqua-dark;
    text-align: center;
    font-size: 0.7rem;
    border-radius: 4px;
    margin-left: 0.5rem;
    padding: 0.1rem 0.8em;
    line-height: 1.6;
  }
}

.app-list-card-container:nth-child(3n) {
  .image-container {
    background: linear-gradient(322.89deg, #053d5c 0%, #28787d 100%);
  }
}

.app-list-card-container:nth-child(3n + 1) {
  .image-container {
    background: linear-gradient(322.89deg, #05285c 0%, #43287d 100%);
  }
}

.app-list-card-container:nth-child(3n + 2) {
  .image-container {
    background: linear-gradient(322.89deg, #05285c 0%, #28547d 100%);
  }
}
