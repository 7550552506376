@import "Assets/styles/Global/_variables";

.add-new-payment-modal {
  @include full-content-size;
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  padding-top: 1rem;
  height: 15rem;
  @include for-vertical-mobile {
    justify-content: flex-start;
    .credit-card-section {
      display: none;
    }
  }
  .credit-card-section {
    height: 100%;
    width: 100%;
    padding-right: 2rem;
    box-sizing: border-box;

    .credit-card.big {
      width: 100%;
      height: 90%;
    }
    .stripe-info {
      @include flex($direction: row, $justify: flex-start, $align: center);
      margin-top: 0.8rem;
      .stripe-image {
        height: 25px;
      }
      .vg-icon {
        svg {
          height: 1.1rem;
          width: 1.1rem;
        }
      }
    }
  }
  .payment-method-form-section {
    @include flex($direction: column, $justify: space-between, $align: flex-start);
    height: 16rem;
    min-width: $big-button-width;
    max-width: $big-button-width;
    box-sizing: border-box;

    @include for-mobile {
      max-width: none;
      width: 100%;
    }

    .vg-radio-input {
      background-color: $white;
      padding-bottom: 0.5rem;
    }

    .payment-method-form-wrapper {
      @include full-content-size;
      form {
        height: 100%;
        width: 100%;
      }
      .vg-dropdown .content {
        max-height: 6rem;
      }
    }
  }
}
