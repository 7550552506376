@import "Assets/styles/Global/_variables.scss";

.payment-3d-secure {
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  position: fixed;
  align-self: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.65);
  height: 100%;

  #payment-frame {
    position: relative;
    top: 10%;
  }
}
