@import "Assets/styles/Global/_variables.scss";

.public-machines-error-container {
  @include full-viewport-size;
  @include flex($direction: column, $justify: center);
  position: relative;
  background-size: 100% 100%;
  background-image: url("../../../../Assets/images/appStreaming/dark-bg.jpg");

  .content-box {
    @include flex($direction: column, $align: center, $justify: space-around);
    gap: 1rem;
    padding: 2rem 3rem;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    min-width: 250px;

    p {
      font-size: $vg-font-size-small;
      text-align: center;
      white-space: pre-wrap;
      color: $vg-white;
    }
  }

  .error-img {
    width: 80px;
  }

  .logo-img {
    max-width: 100px;
  }

  @include for-mobile {
    min-width: auto;
    padding: 1rem;
    gap: 1rem;
    justify-content: center;

    .app-logo-container {
      display: none;
    }
  }

  @include for-small-computer {
    .app-logo-container {
      display: none;
    }
  }
}
