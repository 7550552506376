@import "Assets/styles/Global/_variables.scss";

.use-case-card {
  @include flex($direction: column, $align: stretch);
  flex-grow: 0;
  flex-shrink: 0;
  width: 15rem;
  position: relative;

  &:not(:hover) {
    img {
      opacity: 0.4;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .use-case-logo-container {
    @include full-content-width;
    background-size: cover;
    border-radius: 6px 6px 0 0;
    height: 10rem;
    position: relative;

    img {
      width: 100%;
      max-height: 1.5rem;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      font-size: 28px;
      font-weight: $vg-font-weight-semi-bold;
      color: $vg-dark-navy;
      margin: 0.8rem;
      white-space: break-spaces;
    }
  }

  .content {
    background-color: $vg-white;
    padding: 0.7rem 1rem 0.4rem;
    border-radius: 0 0 6px 6px;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;

    .content-box {
      > div {
        @include flex($direction: column, $align: flex-start, $justify: space-between);
        @include full-content-height;
        padding: 0.5rem 0;
        box-sizing: border-box;
      }
    }

    p {
      color: $vg-gray-2;
      font-size: $vg-font-size-tiny;
      line-height: 2.6;
      a {
        color: $vg-blue-baby;
      }
    }
  }
}
