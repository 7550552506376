@import "Assets/styles/Global/_variables";

.vg-login-content {
  @include full-content-size;
  &.app-streaming-login-content {
    p {
      span {
        color: $vg-purple-main;
      }
    }
  }
  form {
    @include full-content-size;
    @include flex($direction: column, $align: flex-start, $justify: space-between);

    .field-group {
      @include full-content-width;
    }
  }

  a {
    color: $vg-purple-main;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-medium;
    line-height: 0.7rem;
    text-decoration: none;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $vg-gray-5;
  }

  .hr-text {
    width: 100%;
    border: 0;
    position: relative;
    text-align: center;
    font-size: $vg-font-size-tiny;
    margin: 1.5rem 0;

    &:before {
      content: "";
      background: $vg-gray-5;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &::after {
      background-color: $vg-white;
      color: $vg-gray-3;
      content: attr(data-content);
      padding: 0 10px;
      position: relative;
    }
  }

  .verification-container {
    width: 100%;

    p {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-bottom: 1rem;
      font-size: $vg-font-size-tiny;
      font-weight: $vg-font-weight-medium;
      line-height: 1.4rem;

      &.bottom-text {
        margin-top: 6px;
        font-size: $vg-font-size-tiny;
        font-weight: $vg-font-weight-medium;
        white-space: nowrap;
        color: $vg-gray-3;
        > span {
          color: $vg-purple-main;
        }
      }
    }

    .verification-code-inputs {
      @include flex($direction: row, $align: space-around, $justify: space-between);
      gap: 0.3rem;
      width: 100%;

      .vg-input {
        width: 100%;
        font-size: $vg-font-size-huge;
        font-weight: $vg-font-weight-bold;
        text-align: center;
        border-width: 1.5px;
      }
    }
  }
}
