@import "Assets/styles/Global/_variables";

.vendor-admins-container {
  position: relative;
  .vendor-admin-list {
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
    width: 100%;
    max-height: 10.5rem;
    overflow-y: auto;
    padding-right: 1rem;

    &::-webkit-scrollbar {
      width: 6px;
      margin-left: 2rem;
    }

    @include for-mobile {
      width: auto;
      padding-right: unset;
    }
  }

  .vendor-admin {
    @include flex($direction: row, $justify: flex-start, $align: center);
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    box-sizing: border-box;

    h2 {
      span {
        color: $vg-gray-4;
      }
    }

    .vg-avatar {
      margin-right: 1rem;
    }
    .vg-icon {
      margin-left: auto;
    }
    &:hover,
    &.selected {
      background-color: $vg-gray-7;
    }

    &.invitation {
      opacity: 0.5;
    }

    p {
      text-transform: capitalize;
      color: $vg-gray-3;
    }
  }

  .vendor-admin-input {
    width: 100%;
    position: relative;
    .vg-input {
      .vg-icon.error {
        right: 2rem;
      }
      input {
        margin: 0;
      }
      &.disabled {
        border-bottom: 1px solid $vg-gray-4;
        background-color: transparent;
      }
    }
    .text-button-wrapper {
      @include flex($direction: row, $justify: flex-end, $align: center);
      gap: 0.4rem;
      position: absolute;
      bottom: 0rem;
      right: 0;
      height: 2rem;
    }

    .vg-text-button {
      // margin-bottom: 0.5rem;
    }

    .error-wrapper {
      bottom: 0;
    }
  }
}
