@import "Assets/styles/Global/_variables.scss";

.performance-options-table-container {
  max-height: 50vh;
  margin: auto;
  height: 100%;
  width: calc(100% - 1rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  font-size: $vg-font-size-tiny;
  padding-bottom: 1rem;
  overflow-y: scroll;
  padding-right: 1rem;

  .horizontal-divider {
    width: 100%;
    height: 1px;

    display: block;
    background-color: $vg-gray-6;
  }

  .gpu-accelerated-performances {
    height: max-content;
    width: 100%;
    @include flex($direction: column, $align: flex-start, $justify: space-between);
    .performance-header {
      color: $vg-blue-baby;
      font-weight: $vg-font-weight-semi-bold;
      margin: .5rem 0 .75rem 0;
    }

    .machine-type {
      @include flex($direction: row, $align: flex-start, $justify: flex-start);
      height: 100%;
      font-size: $vg-font-size-tiny;
      width: 100%;

      .row {
        @include flex($direction: row, $align: center, $justify: space-between);
        width: 100%;
        margin: 0.75rem 0 0.75rem;

        .cell {
          @include flex($direction: column, $align: flex-start, $justify: space-between);
          width: 2.5rem;
          white-space: nowrap;

          p {
            width: 100%;
            text-align: center;
          }
          p:last-of-type {
            color: $vg-gray-3;
          }
        }

        .cell:first-of-type {
          color: $vg-blue-baby;
          font-weight: $vg-font-weight-semi-bold;
        }
      }
    }
  }

  .performance-option-a10 {
    width: 100%;
    @include flex($direction: column, $align: flex-start, $justify: space-between);
    .performance-header {
      color: $vg-blue-baby;
    }

    .machine-type {
      font-size: $vg-font-size-tiny;

      @include flex($direction: row, $align: flex-start, $justify: flex-start);
      width: 100%;

      .row {
        @include flex($direction: row, $align: center, $justify: space-around);
        width: 100%;

        margin-bottom: 1rem;

        .cell {
          @include flex($direction: column, $align: flex-start, $justify: space-between);

          p {
            width: 100%;
            text-align: center;
          }
          p:last-of-type {
            color: $vg-gray-3;
          }
        }

        .cell:first-of-type {
          color: $vg-blue-baby;
        }
      }
    }
  }
}
