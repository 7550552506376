@import "Assets/styles/Global/_variables";

$bar-width: 4.5rem;
$bar-transition-time: 0.6s;

.vg-workstation-dock-container {
  @include full-viewport-height;
  @include flex($direction: row, $align: center, $justify: center);

  top: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  overscroll-behavior-y: none;

  .vg-workstation-dock-wrapper {
    transition: width $bar-transition-time cubic-bezier(0.4, 0, 0.2, 1);
    height: 100%;
    width: 0;
    &.open {
      width: $dock-width;
      @include for-mobile {
        width: 280px;
      }
    }
    &.half-open {
      width: 3.5rem;
    }
  }

  .dock {
    @include flex($direction: row, $align: flex-start, $justify: flex-start);
    height: 100%;
    width: $dock-width;
    z-index: 9;
    color: $vg-black;

    &.left {
      left: 4rem;
    }

    &.right {
      right: 0rem;
    }

    .bar {
      text-align: center;
      height: 100%;
      width: $bar-width;
      padding: 2rem 0;
      background: rgba(255, 255, 255, 0.8);

      .vg-icon {
        height: 2rem;
        width: auto;
      }

      .vagon-icon {
        margin-bottom: 2rem;
        height: 1.2rem;
        width: auto;
      }

      .bar-item {
        @include flex;
        height: 3rem;
        position: relative;
        margin-bottom: 1rem;
        @include for-rotated-mobile {
          margin-bottom: 0rem;
        }
        &:not(.selected) {
          .settings path {
            fill: $vg-gray-3;
          }
          .display path {
            stroke: $vg-gray-3;
          }
          .file-drag path {
            fill: $vg-gray-3;
          }
          .keyboard-mouse path {
            fill: $vg-gray-3;
          }
          .vr-headset path {
            fill: $vg-gray-3;
          }
        }
        &.selected {
          .file-drag path {
            fill: $vg-black;
          }
        }
        &.selected::after {
          content: " ";
          height: 100%;
          width: 2px;
          top: 0;
          right: -2px;
          position: absolute;
          background-color: $vg-purple-main;
        }
      }
    }

    .dock-header {
      @include flex($direction: column, $align: flex-start, $justify: space-between);
      @include full-content-width;
      margin-bottom: 0.5rem;
      .header-text {
        font-size: 1rem;
        font-weight: $vg-font-weight-light;
        margin: 0;
        padding-top: 0.5rem;
      }
      .header-content {
        color: $vg-gray-2;
        font-size: 1.2rem;
        font-weight: $vg-font-weight-regular;
      }
    }
    .dock-header,
    .menu-item,
    h1 {
      box-sizing: border-box;
      padding: 0 0.5rem;
    }

    .dock-content {
      @include flex($direction: column, $align: flex-start, $justify: flex-start);
      @include full-content-size;
      padding: 1rem 1rem;
      background-color: $vg-white;
      box-sizing: border-box;
      overflow-y: scroll;

      .dock-body {
        width: 100%;
      }

      .vg-text-button,
      .vg-dropdown,
      p.menu-item {
        width: 100%;
        text-align: start;
        font-size: 0.8rem;
        color: $vg-gray-2;
        font-weight: $vg-font-weight-light;
        @include for-mobile {
          font-size: 0.675rem;
        }
        .vg-icon {
          height: 1.5rem;
        }

        &.disabled {
          color: $vg-gray-4;
        }
      }

      .vg-switch,
      p.menu-item {
        width: 100%;
        text-align: start;
        font-size: 0.8rem;
        color: $vg-gray-2;
        font-weight: $vg-font-weight-light;
        @include for-mobile {
          font-size: 0.6rem;
        }
        .vg-icon {
          height: 1.5rem;
        }
      }

      .menu-item {
        &.new {
          p::after {
            content: "New";
            color: $vg-aqua-main;
            font-size: 0.8rem;
            font-weight: 600;
            padding: 0.2rem 0.6rem 0.1rem;
          }
        }
      }

      .menu-item {
        &.alpha {
          p::after {
            content: "Alpha";
            color: $vg-aqua-main;
            font-size: 0.8rem;
            font-weight: 600;
            padding: 0.2rem 0.6rem 0.1rem;
          }
        }
      }

      .vg-dropdown {
        color: $vg-gray-3;
      }

      .vg-text-button:hover:not(.disabled) {
        background-color: $vg-gray-6;
        color: $vg-black;
      }

      h1 {
        color: $vg-black;
        font-size: 1rem;
        font-weight: $vg-font-weight-regular;
        margin: 1rem 0;
      }
      @include for-mobile {
        .menu-item {
          .vg-dropdown {
            font-size: 0.6rem;
            .dropdown-item {
              font-size: 0.6rem;
            }
          }
        }
      }
      .menu-item,
      .tab-title-item {
        @include flex($direction: row, $justify: space-between);
        height: 2rem;
        width: 100%;
        @include for-mobile {
          width: 60%;
        }
        .vg-text-button {
          width: auto;
          padding-left: 1rem;

          &:not(.disabled):hover,
          &:not(.disabled):active {
            background-color: transparent;
            color: $vg-purple-main;
          }
        }
      }
      .tab-title-item .vg-text-button {
        padding: 0 0.5rem;
        color: $vg-gray-3;
        font-weight: $vg-font-weight-semi-bold;
      }

      .dock-footer {
        width: 100%;
        margin-top: auto;
      }
    }
  }
}

.workstation-file-manager-container {
  @include full-viewport-size();
  top: 0;
  overflow: hidden;
  position: absolute;

  .vg-dashboard-sub-screen-layout {
    margin-left: 100vw;
    width: 100vw;
    animation: slide-out 0.8s;
    background-color: rgba($vg-gray-7, 0.95);
    backdrop-filter: blur(10px);
  }

  &.open {
    .vg-dashboard-sub-screen-layout {
      margin-left: 0;
      animation: slide-in 0.1s;
    }
  }

  .invisible-overlay {
    left: 0;
    &.modal-overlay {
      .modal-container.small:not(.boarding-modal) {
        left: 0;
      }
    }
  }
}

.dock .bar {
  .hoverable-icon {
    position: relative;
    transition: opacity 1s ease-in-out;
    &:hover .hover-panel {
      opacity: 0.6;
    }
    &.hover .hover-panel {
      opacity: 0.6;
      transition-delay: 300ms;
      animation: hide 0s ease-in 4s forwards;
    }
  }
}

.hover-panel {
  background-color: $vg-white;
  color: $vg-gray-1;
  position: absolute;
  white-space: nowrap;
  padding: 0 10px;
  width: fit-content;
  height: 20px;
  right: 65px;
  top: 5px;
  font-size: 12px;
  border-radius: 3px;
  font-family: $default-font-family;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  pointer-events: none;
}
