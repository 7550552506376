@import "Assets/styles/Global/_variables.scss";

.vg-tooltip {
  background-color: $vg-white;
  border-radius: 2px;
  width: fit-content;
  max-width: 10rem;
  width: max-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: $vg-gray-2;
  position: absolute;
  white-space: break-spaces;
  font-size: 0.7rem;
  font-weight: $vg-font-weight-light;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  &:not(.reference-position) {
    bottom: calc(100% + 0.4rem);
    right: 0;
  }

  &.reference-position {
    display: block;
    transform: translate(-80%, 0);
  }

  &.right {
    left: calc(100% + 0.4rem);
    transform: translate(0, 100%);
    z-index: 999;
  }

  &.right-top {
    left: calc(100% - 1rem);
  }

  &.left-bottom {
    left: 0;
    bottom: -2.5rem;
  }

  // &::after {
  //   border-color: $vg-white transparent transparent transparent;
  //   border-style: solid;
  //   border-width: 5px;
  //   content: " ";
  //   position: absolute;
  //   right: 0.25rem;
  //   top: 100%;
  // }

  // &.left {
  //   left: -1rem;
  //   &::after {
  //     left: 1.5rem;
  //     right: unset;
  //   }
  // }

  // If sibling is a button, put the pointer triangle a bit to the left.
  button ~ & {
    bottom: calc(100% + 0.5rem);

    &::after {
      right: 1.35rem;
    }
  }
}

.vg-tooltip-hoverable {
  position: relative;
  display: flex;
}
