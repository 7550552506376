@import "Assets/styles/Global/_variables";

.upgrade-file-storage-modal-content {
  @include flex($direction: row, $justify: center);
  width: 100%;
  height: 10rem;
  margin-bottom: 2rem;
}

.upgrade-file-modal-action {
  position: relative;
  .payment-info-line {
    padding-top: 1rem;
    font-size: 0.8rem;
  }
}

.storage-option-card {
  @include flex($direction: column, $justify: space-between);

  background-color: $vg-white;
  border: $border-gray-6;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  height: 10rem;
  margin: 2rem 0.6rem 0;
  padding: 1rem 0 0;
  position: relative;
  width: 8rem;
  font-weight: $vg-font-weight-semi-bold;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &[disabled],
  &[disabled]:hover {
    cursor: default;
    opacity: 0.5;
  }

  &.mobile-horizontal {
    @include for-mobile {
      flex-direction: row;
      height: 5.6rem;
      padding: 0 0 0 1rem;

      .price {
        font-size: 0.9rem;
        height: 100%;
        width: 5rem;
        border-radius: 0 $vg-border-radius-small $vg-border-radius-small 0;
      }
    }
  }

  &:hover {
    opacity: 1;
  }

  &.selected {
    border: 1px solid $vg-blue-baby;
    box-shadow: $medium-shadow;
    box-sizing: border-box;
    opacity: 1;
  }

  &.not-selected:not(:hover) {
    opacity: 0.8;
  }

  .name {
    color: $vg-black;
    font-size: 0.8rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1rem;
    text-align: center;

    @include for-mobile {
      margin-bottom: -0.3rem;
      margin-top: 0.7rem;
    }
  }

  .type {
    @include flex;

    img {
      margin-top: 1rem;
      width: 4rem;
      height: 4rem;
    }
  }

  .price {
    @include flex;
    background-color: $vg-blue-baby;
    border-radius: 0 0 $vg-border-radius-small $vg-border-radius-small;
    color: $vg-white;
    font-size: 0.6rem;
    height: 1.3rem;
    width: 100%;
    text-align: center;
  }
}
