@import "Assets/styles/Global/_variables.scss";

.app-streaming-connecting-container {
  width: 100vw;
  height: 100vh;

  @include flex($direction: column, $justify: center);
  background-image: url("../../../Assets/images/appStreaming/white-bg.svg");
  background-size: cover;

  .content-box {
    @include flex($direction: column, $align: center, $justify: space-around);
    gap: 2rem;
    padding: 3rem 4rem;

    @include for-mobile {
      padding-left: 1rem;
      padding-right: 1rem;
      gap: 1rem;
    }
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    p {
      color: $vg-purple-light;
      font-size: $vg-font-size-medium;
    }
    img {
      width: 120px;
      @include for-mobile {
        width: 80px !important;
      }
    }
    .app-streaming-progress-bar,
    .first-step,
    .second-step,
    .third-step {
      border-radius: 2px;
      height: 4px;
      box-sizing: border-box;
    }
    .app-streaming-progress-bar {
      width: 300px;
      background-color: rgba(235, 241, 255, 1);
    }

    .progress {
      background-color: $vg-purple-light;
      border-radius: 2px;
      height: 4px;
      box-sizing: border-box;
      transition: width 1s;
    }
  }
  .play-button-container {
    @include flex($direction: column, $align: center, $justify: center);
    min-width: 300px;
    gap: 0.4rem;
    margin-top: -1rem;
    p {
      color: $vg-aqua-dark;
      font-size: $vg-font-size-small;
      font-weight: $vg-font-weight-medium;
      margin-top: 0.3rem;
    }
    p:nth-of-type(2) {
      color: $vg-aqua-dark;
      font-size: $vg-font-size-medium;
      font-weight: $vg-font-weight-semi-bold;
      margin: 1rem 0;
    }
    .output-settings-container {
      width: 20rem;
      display: flex;
      flex-direction: column;
      text-align: initial;
      justify-content: space-between;
      gap: 1.8rem;
      margin-top: 1rem;

      p {
        margin: 0 0 0.5rem 0;
        color: $vg-gray-1;
        font-size: 0.85rem;
      }
      .input-box-connecting {
        font-size: 0.8rem;
        user-select: none;
        span {
          padding-left: 0.7rem;
        }
      }
      .vg-dropdown {
        &.box {
          border: 1px solid $vg-gray-5;
          padding-bottom: 0;
        }

        .selected {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    .app-streaming-play-button {
      margin-top: 2rem;
    }
  }
  .remaining-time-info {
    color: $vg-gray-3;
    position: absolute;
    bottom: 1.5rem;
    font-size: 0.85rem;
    font-weight: 300;
    @include for-short-screen {
      display: none;
    }
  }
  &.dark {
    background-image: url("../../../Assets/images/appStreaming/bg_black.jpg");
    .content-box {
      background: rgba(0, 0, 0, 0.3);
    }
    p:not(.remaining-time-info) {
      color: $vg-white;
    }
    .progress {
      background-color: $vg-purple-main;
    }
    .vg-dropdown {
      color: $vg-white;
    }
  }
}
