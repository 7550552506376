@import "Assets/styles/Global/_variables.scss";

.management-selection {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .tooltip-children {
    width: 100%;
  }

  .enterprise {
    margin-top: 1rem;
    padding: 0.3rem 0.5rem;
    background-color: #e0e7ff;
    color: $vg-purple-main;
    width: max-content;
    border-radius: 16px;
  }

  .management-selection-card {
    position: relative;
    border: 1px solid $vg-gray-5;
    border-radius: 4px;
    flex: 1;
    padding: 1rem;
    &.selected {
      border: 1px solid $vg-aqua-main;
    }

    &:hover {
      border: 1px solid $vg-aqua-main;
      cursor: pointer;
    }

    &.not-allowed {
      pointer-events: none;
      * {
        opacity: 0.8;
      }

      &:hover {
        border: 1px solid $vg-gray-5;
      }

      button {
        pointer-events: auto;
      }
    }
  }

  .management-selection-header {
    &.automated {
      display: flex;
    }

    .enterprise-badge {
      background-color: $vg-aqua-main;
      padding: 1px 10px;
      height: 1.3rem;
      border-radius: 14px;
      font-size: 0.7rem;
      margin-left: auto;
      color: $white;
    }
    button {
      margin-top: 0.5rem;
    }

    .management-selection-header-title {
      display: flex;
      gap: 0.3rem;
      margin-bottom: 0.5rem;
    }
    h1 {
      position: relative;

      span {
        position: absolute;
        font-size: 0.7rem;
        font-weight: $vg-font-weight-medium;
        top: -0.3rem;
        color: $vg-aqua-main;
        margin-left: 0.2rem;
      }
    }
  }

  .management-selection-footer {
    display: flex;
    .vg-text-button {
      margin-left: auto;
      margin-top: auto;
      opacity: 1;
      .text-button {
        opacity: 1;
        font-weight: 700;
      }
      width: max-content;
      font-weight: 500;
    }
    .items {
      > div {
        display: flex;
        gap: 0.3rem;
        align-items: center;

        .vg-icon {
          margin-bottom: -0.4rem;
        }
      }

      p {
        font-size: 0.8rem;
        color: $vg-gray-1;
        margin-top: 0.5rem;
      }

      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
    }
  }
}
