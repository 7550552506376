@import "Assets/styles/Global/_variables.scss";

.app-list-cards-container {
  @include flex($direction: column, $align: flex-start, $justify: flex-start);
  height: 16rem;
  overflow-y: auto;

  width: 40rem;
  gap: 0.6rem;

  p {
    font-weight: $vg-font-weight-medium;
    font-size: $vg-font-size-medium;
  }

  ::-webkit-scrollbar {
    width: 0.4rem;
  }
}

.app-streaming-streams-container {
  animation: fade-in 0.5s;
  animation-fill-mode: forwards;
  box-sizing: border-box;

  .new-stream-button {
    padding: 0 0.4rem;
    width: 8rem;
    background-color: $vg-light-navy;

    .vg-icon {
      height: 0.8rem;
    }

    &.larger {
      width: 10rem;
    }
  }

  .app-list-pop-up-menu {
    &.initial {
      margin-top: -1rem;
    }

    &:not(.initial) {
      left: -4rem;
    }

    .app-menu-pop-up {
      min-width: 16rem;
      width: max-content;
    }
  }
}

.app-streaming-streams-container,
.header-button {
  @include full-content-width;
  position: relative;
}

.header-button {
  @include flex($direction: row, $justify: flex-end);
  margin-bottom: 1rem;

  .button-container {
    position: relative;
    button {
      &:disabled {
        opacity: 0.7;
        color: $vg-white;
      }
    }
  }
}

.start-stream-error-modal {
  height: 28rem;
  box-sizing: border-box;

  .modal-content {
    align-items: center !important;
  }

  h1 {
    font-size: $vg-font-size-large;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .stream-error-message-container {
    margin-top: 1rem;
    white-space: pre-wrap;
  }
}
