@import "Assets/styles/Global/_variables";

.vg-image-upload {
  @include flex;
  width: 100%;
  height: 100%;

  img {
    height: 80%;
    width: 100%;
    object-fit: contain;
  }

  &.cover {
    border-radius: $vg-border-radius-small;
    img {
      border-radius: $vg-border-radius-small;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
