@import "Assets/styles/Global/_variables";

.vendor-plans-table {
  position: relative;
  width: 100%;

  .header-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    padding: 2rem 0;
    margin-top: -2rem;
    line-height: 2.2rem;
    position: sticky;
    top: 0;
    background-color: $vg-gray-7;

    @include for-mobile {
      grid-template-columns: repeat(4, 1fr);
      width: 36rem;
      z-index: 9;
    }

    .header-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 0.8rem;
      line-height: 1rem;
      gap: 0.75rem;

      @include for-mobile {
        width: 8rem;
      }

      div:first-child {
        display: flex;
        align-items: center;
        width: 10rem;
        @include for-mobile {
          width: 6rem;
        }
      }

      .vg-icon,
      img {
        height: 2rem;
      }

      .plan-info {
        margin-left: 0.5rem;

        p:first-child {
          font-size: 0.9rem;
          font-weight: $vg-font-weight-semi-bold;
          text-align: left;
        }

        p:last-child {
          font-size: 0.7rem;
          text-align: left;
        }
      }

      .vg-button {
        @include for-mobile {
          width: 8rem;
        }
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin: 0;
    background-color: $vg-white;
    line-height: 2rem;

    @include for-mobile {
      grid-template-columns: repeat(4, 1fr);
      width: 35rem;
    }

    .feature {
      font-size: 0.8rem;
      font-weight: 400;
      color: $vg-gray-3;

      @include for-mobile {
        background-color: $vg-white;
        position: sticky;
        left: 0;
      }
      &.gray {
        background-color: $vg-gray-7;
      }
    }

    &.gray {
      background-color: $vg-gray-7;
    }

    .centered-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      p {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    .icon {
      width: 1rem;
      height: 1rem;
    }
  }
}
