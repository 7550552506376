@import "Assets/styles/Global/_variables";

.d-flex {
  display: flex;
}

.ai-center {
  align-items: stretch;
}

.announcement {
  @include flex($direction: row, $justify: flex-start, $align: center);
  -webkit-animation: appear 0.5s 1;
  animation: appear 0.5s 1;
  border-radius: 3px;
  position: relative;
  background-color: $vg-white;
  @include full-content-width;
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  .right-close-icon {
    position: absolute;
    right: 1.5rem;
    * {
      fill: $vg-gray-4;
    }
  }
  img {
    margin-right: 2rem;
    height: 2.25rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .announcement-content {
    width: 100%;
    font-weight: 400;
    padding-right: 1.5rem;
    h3 {
      font-size: 0.9rem;
      line-height: 1.25rem;
      color: $vg-gray-1;
    }
    p {
      letter-spacing: 0;
      text-align: left;
      line-height: 1.25rem;
      color: $vg-gray-3;
      font-size: 0.8rem;
      padding-right: 1rem;
      .inline-button {
        cursor: pointer;
        color: $vg-purple-main;
        display: inline-block;
      }
    }
  }
  .vertical-divider {
    height: 3rem;
    width: 1px;
    background-color: $vg-gray-5;
  }
}

.announcement-button-container {
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;

  .announcement-buttons {
    padding: 0 2rem;
    &:last-child {
      padding-left: 1rem;
    }
    @media (min-width: 1201px) {
      &:first-child {
        margin-left: 1rem;
      }
    }
  }
  #regular-button {
    padding: 0;
    width: 7rem;
    margin: 0 2rem;
  }

  @media (max-width: 1200px) {
    @include flex($direction: column);
    align-content: space-between;
    .announcement-buttons {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
    #regular-button {
      margin-bottom: 0.5rem;
    }
  }
}
.announcement-top-right-icon {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  height: 10px;
  width: 10px;
  padding: 0.15rem;
  background-color: $vg-aqua-light;
  border-radius: 100px;
  path {
    fill: $vg-white;
  }
}
