@import "Assets/styles/Global/_variables";

.keyboard-shortcuts-container {
  @include flex($direction: row, $justify: space-around);
  position: absolute;
  top: 0;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  -webkit-animation: slide-down 0.5s 1;
  animation: slide-down 0.5s 1;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  button {
    all: unset;
  }

  .vg-icon,
  .vg-text-button {
    box-sizing: content-box;
    height: 3rem;
    padding: 0.2rem 0.8rem;

    &:focus,
    &:active,
    &:hover {
      color: $vg-black;
    }
  }
}

@keyframes slide-down {
  0% {
    top: -3rem;
  }
  100% {
    top: 0;
  }
}
