@import "Assets/styles/Global/_variables.scss";

.vg-progress-bar-container {
  top: 0;
  overflow: hidden;
  width: inherit;

  .progress-bar {
    position: relative;
    height: 3px;
    background-color: $vg-purple-light;
  }
}
