@import "Assets/styles/Global/_variables";

.switch-computer-owner-modal {
  .confirmation-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    .keep-file-checkbox {
      display: flex;
      align-items: flex-end;
      margin-top: 1rem;
    }

    input[type="checkbox"] {
      width: 0.85rem;
      height: 0.85rem;
      margin-right: 4px;
      accent-color: $vg-purple-main;
    }
  }
}
