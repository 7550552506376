@import "Assets/styles/Global/_variables.scss";

.team-create-calculator-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .modal-action-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0rem 0rem;
    font-size: 0.8rem;

    .vg-button {
      width: 16rem;
      height: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem;
      margin-top: 2rem;
      p {
        display: flex;
        color: $vg-white;
        margin: 0rem 0rem;
      }
    }

    .vg-button:disabled {
      background-color: $vg-white;
      border: 1.5px solid $vg-gray-6;
    }

    .vg-text-button {
      font-size: 0.8rem;
      color: $vg-purple-main;
      &.disabled {
        color: $vg-gray-4;
        opacity: 1;
      }
    }
  }
  .description {
    opacity: 0.7;
  }
}

.calculator-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .hours-text-input p {
    margin: 0rem 0rem;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .hours-text-input {
    align-self: center;
    display: block;
    align-items: center;
    .vg-input {
      color: $vg-gray-2;
      width: 5rem;
      margin-bottom: 0.1rem;
      input {
        border-color: $vg-gray-2;
        font-size: 1rem;
      }
    }

    p {
      color: $vg-gray-2;
      opacity: 0.6;
    }
  }
}
