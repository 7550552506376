@import "Assets/styles/Global/_variables";
$button-size: 3.3rem;

.app-streaming-files-dock-wrapper {
  background: rgba(255, 255, 255, 1);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;

  width: 0;
  transition: width 1s;

  &.open {
    width: 18rem;
  }

  .app-streaming-files-dock {
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    gap: 1rem;
    padding: 1rem;
    height: 100%;

    .header {
      width: 16rem;
      padding: 0 4px;
      @include flex($direction: row, $justify: space-between, $align: baseline);
    }

    .search-input {
      height: 2rem;
      width: 16rem;
      .vg-input {
        input {
          padding: 0 0 0 1rem;
          background-color: $vg-white;
          height: 1.5rem;
          line-height: 1.5rem;
        }
      }
      .vg-icon {
        padding: 0 0.6rem 0;
        width: 2rem;
        height: 2rem;
        * {
          fill: unset;
        }
      }
    }

    .files-dock-button {
      @include flex;
      left: -2.6rem;
      top: 0;
      bottom: 0;
      position: absolute;
      margin: auto;

      padding: 0;
      width: $button-size;
      height: $button-size;
      border-radius: 100px;
      font-size: 1.25rem;
      outline: none;
      background: rgba(204, 204, 204, 0.4);
      cursor: pointer;

      &.open {
        top: auto;
        background: none;
        border: none;
        left: 0;
        .vg-icon {
          * {
            fill: $black;
          }
        }
      }

      .invisible {
        opacity: 0;
      }

      .vg-icon {
        position: absolute;

        height: 1.6rem;
        width: 1.6rem;
        margin-left: -8px;
        * {
          fill: $white;
        }
      }
    }
    .files-container {
      width: 16rem;
      margin-top: 1rem;
      height: 80%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      .search-input {
        height: 2rem;
        width: 16rem;
        .vg-input {
          input {
            padding: 0 0 0 1rem;
            background-color: $vg-white;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }
        .vg-icon {
          padding: 0 0.6rem 0;
          width: 2rem;
          height: 2rem;
          * {
            fill: unset;
          }
        }
      }
      .file {
        @include flex($direction: row, $align: center);
        width: 100%;

        gap: 0.5rem;
        padding: 0.5rem 0;

        p {
          text-overflow: ellipsis;
          max-width: 10rem;
          overflow: hidden;
          font-size: 0.7rem;
          white-space: nowrap;
          font-weight: $vg-font-weight-light;
        }

        &:hover {
          background: $vg-gray-6;
          border-radius: 4px;
        }

        .vg-icon {
          padding: 0 0.6rem 0;
          width: 1rem;
          height: 1rem;
        }

        .vg-progress-bar-container {
          background-color: $vg-gray-6;
          width: 1rem;
          margin: 0 0.5rem;
        }

        .actions {
          .vg-tooltip {
            right: 100%;
            top: -0.5rem;
            bottom: auto;
          }
          margin-left: auto;
          @include flex($direction: row, $align: center, $justify: center);

          .baby-blue {
            font-size: 0.65rem;
          }
        }
      }
    }

    .empty-folder {
      @include flex;
      width: 100%;
      @include for-mobile {
        width: 60%;
      }

      img {
        display: block;
        height: 8rem;
        margin: 2rem 0 2rem 0.5rem;
      }

      h1 {
        font-weight: $vg-font-weight-light;
        margin-bottom: 0.5rem;
      }

      p {
        @include for-mobile {
          font-size: 0.7rem;
        }
        max-width: 15rem;
        font-size: $vg-font-size-tiny;
        color: $vg-gray-3;
        text-align: center;

        span {
          color: $vg-purple-light;
          font-weight: $vg-font-weight-medium;
        }
      }
    }
  }
}
.drag-toast-notification {
  p {
    font-size: $vg-font-size-small;
    white-space: break-spaces;
    text-align: center;
    span {
      color: $vg-purple-main;
    }
  }
}
