@import "Assets/styles/Global/_variables";

.vg-table {
  @include full-content-width;
  td,
  th {
    padding: 0.4rem 0;
    padding-left: 1rem;
  }

  tbody tr:nth-child(odd) {
    background-color: $vg-gray-7;
  }

  th {
    text-align: left;
    font-weight: $vg-font-weight-semi-bold;
    color: $vg-gray-1;
    font-size: $vg-font-size-small;
  }
  tfoot td {
    color: $vg-gray-1;
    font-size: $vg-font-size-small;
  }
  td {
    font-weight: $vg-font-weight-regular;
    color: $vg-gray-3;
    font-size: 0.75rem;
    vertical-align: middle;
  }
  .left-aligned-table-row {
    @include flex($direction: row, $align: center, $justify: flex-start);
  }
}
.table-footer {
  @include full-content-width;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}
.vg-table-container {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.vg-table thead th {
  position: sticky;
  top: 0;
  background-color: $vg-white;
}

.vg-table tfoot {
  position: sticky;
  bottom: 0;
  background-color: $vg-white;
}
