@import "Assets/styles/Global/_variables.scss";

.machine-types-container {
  &:hover {
    .vg-machine-type-card {
      opacity: 0.7;
      transition: 0.2s;
    }
  }

  .row {
    @include flex($direction: row, $justify: center);
    margin: 0 auto 1rem;
    width: fit-content;
    transition: height 1s;
    overflow: hidden;
    height: 12.5rem;
    &.hide {
      height: 0;
    }
  }
}
