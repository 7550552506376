@import "Assets/styles/Global/_variables.scss";

.subscription-container {
  @include full-content-size;
  @include flex($direction: column, $justify: space-between, $align: center);

  .connection-content {
    @include full-content-size;
    @include flex($direction: column, $justify: space-between, $align: center);
    text-align: center;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;

    @include for-mobile {
      // TODO: Implement Mobile view for pricing
      overflow: hidden;
    }
    h3 {
      font-size: $vg-font-size-large;
      @include for-mobile {
        font-size: $vg-font-size-tiny;
      }
    }
    p {
      margin-bottom: 1rem;
      font-size: $vg-font-size-small;
      color: $vg-gray-3;
    }

    .pricing-section {
      @include full-content-size;
      min-height: 95%;
      margin-top: 5rem;
      overflow: auto;
      scroll-snap-align: center;
      .machine-types-container {
        @include flex($direction: column, $align: center);
        opacity: 1 !important;
        height: inherit;
        .vg-machine-type-card {
          opacity: 1 !important;
          cursor: default;
        }
      }
    }

    .plan-section {
      @include flex($direction: column, $justify: flex-start, $align: center);
      width: 95%;
      max-width: 1400px;
      height: 100%;
      min-height: 100%;
      margin-top: 1rem;
      overflow: auto;
      scroll-snap-align: center;

      @include for-mobile {
        min-height: unset;
      }

      .divider-container {
        margin: 3rem 0 2rem;
        width: 80%;
      }

      .add-balance-container {
        @include flex($direction: column, $justify: space-between, $align: center);
        width: 90%;
        h3 {
          margin-bottom: 0.4rem;
        }
      }

      .plan-container {
        @include flex($direction: row, $justify: center, $align: center);
        height: fit-content;
        width: 100%;
        min-height: 100px;
        margin-top: 2rem;

        .plan-info {
          @include flex($direction: row, $justify: flex-end, $align: center);
          height: 80%;
          width: 50%;
          max-width: 700px;

          .plan-pricing-wrapper {
            flex: 0 0 60%;
            overflow: hidden;
            transition: 1s;
            .plan-pricing {
              text-align: left;
              min-width: 15rem;
              font-size: 0.8rem;

              .price {
                font-size: $vg-font-size-large;
                color: $vg-black;
              }
            }
          }
          .plan-image {
            flex: 0 0 20%;
            margin-right: 2.5rem;
            height: 100%;
            width: auto;
          }
        }

        .divider {
          background-color: $vg-gray-5;
          min-height: 80%;
          min-width: 1px;
          @include for-mobile {
            display: none;
          }
        }

        .plan-details {
          @include flex($direction: row, $justify: flex-start, $align: center);
          width: 50%;
          max-width: 700px;
          height: 100%;
          overflow: hidden;

          .details-wrapper {
            @include flex($direction: row, $justify: center, $align: flex-end);
            height: fit-content;
            flex: 0 0 100%;
            box-sizing: border-box;
            transition: flex 1s;
            overflow: hidden;
            .details-list {
              min-width: 28rem;
              max-height: 10rem;
              padding-left: 2rem;
              box-sizing: border-box;
              color: $vg-gray-3;
              text-align: left;
              li {
                line-height: 2rem;
              }
              @include for-mobile {
                min-width: 20rem;
              }
            }
          }
          .promo-input {
            flex: 0 0 100%;
            max-width: 15rem;
            margin-left: 3rem;
            width: 200px;
            height: 6rem;
            text-align: left;

            h3 {
              margin-bottom: 1rem;
            }

            .error-wrapper {
              @include flex($direction: row, $justify: flex-start, $align: center);
              margin-top: 0.5rem;
              font-size: 0.8rem;
              color: $vg-purple-main;

              .vg-icon {
                height: 1.3rem;
              }
            }

            @include for-mobile {
              display: none;
            }
          }
        }
        &.promo-view {
          .promo-input {
            display: block;
          }
          .plan-info .plan-pricing-wrapper {
            flex-basis: 0;
          }
          .plan-details .details-wrapper {
            flex-basis: 0;
          }
        }
      }

      @include for-mobile {
        width: 90%;
        min-width: auto;
        .plan-container,
        .plan-container.promo-view {
          @include flex($direction: column, $justify: flex-start, $align: center);
          height: auto;
          min-width: auto;
          margin: 0;
          flex-shrink: 0;
          .plan-info {
            width: 95%;
            margin-right: 2rem;
            .plan-pricing-wrapper {
              flex-basis: 50%;
              .plan-pricing {
                font-size: 0.7rem;
                min-width: 0rem;
              }
            }
            .plan-image {
              width: 20%;
              height: auto;
            }
          }
          .plan-details {
            width: 95%;
            margin-top: 1rem;
            font-size: $vg-font-size-tiny;
            overflow: visible;
          }
          .divider {
            height: 1px;
            min-height: 1px;
            margin: 1rem 0;
            width: 90%;
          }
        }
        .divider-container {
          margin: 2rem 0;
        }
      }
    }
  }

  .scroll-page {
    @include flex($direction: column, $justify: center, $align: center);
    left: 50%;
    bottom: 2rem;
    min-width: 10rem;
    transform: translate(-50%, 0);
    color: $vg-gray-4;
    text-align: center;
    position: absolute;
    white-space: pre-wrap;
    @include for-mobile {
      bottom: 4.5rem;
      display: none;
    }
    @include for-short-screen {
      display: none;
    }
  }
}
