@import "Assets/styles/Global/_variables.scss";

.computer-creation-container {
  @include flex($direction: column, $justify: space-between, $align: flex-start);
  @include full-viewport-height;

  .new-computer-content {
    width: 100%;
    min-height: calc(100% - 6.9rem);
    height: 100%;
    @include for-mobile {
      min-height: 100%;
    }
  }
}
