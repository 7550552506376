@import "Assets/styles/Global/_variables.scss";

.add-balance-modal-content {
  @include flex($direction: row, $justify: space-between, $align: flex-start);
  width: 100%;
  .select-balance {
    margin-left: -0.5rem;
    margin-right: 1rem;
  }
  .payment-details {
    // TODO check mobile effects
    flex-shrink: 0;
    width: $big-button-width;
    margin-top: 0.5rem;
  }
  .credit-conversion {
    @include flex($direction: row, $justify: flex-start, $align: center);
    margin: 1rem 0 -1.5rem;
    .vg-icon {
      height: 1rem;
      width: 1.5rem;
    }
    p {
      font-size: 0.7rem;
      color: $vg-aqua-dark;
    }
  }
  @include for-mobile {
    flex-direction: column;
    .payment-details {
      min-width: $big-button-width;
      width: 100%;
      height: 10rem;
      padding: 0;
      margin-top: 2rem;
    }
  }
  .add-balance-form {
    &.small {
      .custom-balance {
        height: 3.5rem;
      }
    }
  }
}
