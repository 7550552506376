@import "Assets/styles/Global/_variables.scss";

.migrate-region-container {
  box-sizing: border-box;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;

  @include for-mobile {
    height: 30vh;
  }

  .world-map {
    width: 80%;
    @include for-mobile {
      width: 100%;
      margin: 0;
    }
  }

  .region-change-modal {
    position: relative;
    width: 100% !important;
    height: 12rem !important;
    bottom: 0;

    .modal-content {
      width: 100%;
    }
  }
}

.migrate-region-confirmation-modal {
  .error {
    padding: 1rem 0;
  }
}
