@import "Assets/styles/Global/_variables";

.file-manager-container {
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 80vh;
  max-height: calc(95vh - 10rem);
  min-width: 700px;

  @include for-mobile {
    max-height: calc(95vh - 11rem);
  }

  .vg-loader {
    width: 100%;
  }

  .file-manager-table {
    height: 0px;
  }

  .file-manager-actions-bar {
    @include flex($direction: row, $justify: space-between, $align: center);
    @include full-content-width();
    margin-left: 0.5rem;
    margin-bottom: 2rem;

    .search-input {
      margin-right: 1rem;
      height: 2.5rem;
      width: 20rem;
      @include for-mobile {
        margin-right: unset;
      }

      input {
        padding-left: 1rem;
        background-color: $vg-white;
        height: 2rem;
        line-height: 2rem;
      }
      .vg-icon {
        width: 2.5rem;
        * {
          fill: unset;
        }
      }
    }

    .file-manager-left-actions {
      @include flex($direction: row, $justify: flex-start, $align: center);

      @include for-mobile {
        gap: 0.4rem;
      }

      .vg-tooltip-hoverable {
        cursor: pointer;
        margin-right: 1rem;
        @include for-mobile {
          margin-right: unset;
        }
        .vg-tooltip {
          width: auto;
          white-space: nowrap;
        }
      }
      .left-action-button {
        @include flex;
        width: 4rem;
        height: 2.5rem;
        text-align: center;
        background-color: $vg-white;
        border: $border-gray-6;
        border-radius: 3px;
        .vg-icon {
          width: 1.8rem;
        }
        &:hover {
          background-color: $vg-gray-7;
        }
      }
    }
    .file-manager-right-actions {
      @include flex($direction: row, $justify: flex-end, $align: center);
      @include full-content-height();
      .vertical-divider {
        width: 1px;
        height: 1.4rem;
        background-color: $vg-gray-3;
        margin-right: 0.5rem;
      }
      .select-file {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1rem;
        .vg-icon {
          width: 1.5rem;
          height: 1.4rem;
        }
        p {
          margin-left: 7px;
          font-size: 0.6rem;
          color: $vg-blue-baby;
          user-select: none;
        }
      }
      .vg-tooltip-hoverable {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 1rem;
        .selected-file-actions {
          width: 1.5rem;
          height: 1.4rem;

          &.disabled {
            opacity: 0.3;
          }
        }
        .vg-tooltip {
          width: auto;
          white-space: nowrap;
        }
      }
    }
  }
  .breadcrumb {
    font-size: 1rem;
    font-weight: $vg-font-weight-semi-bold;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    .go-back-section {
      @include flex($direction: row, $justify: flex-start, $align: center);
      height: 1.5rem;
      cursor: pointer;
      user-select: none; /* standard syntax */
      -webkit-user-select: none; /* webkit (safari, chrome) browsers */
      -moz-user-select: none; /* mozilla browsers */
      -khtml-user-select: none; /* webkit (konqueror) browsers */
      -ms-user-select: none; /* IE10+ */
      .vg-icon {
        height: 1rem;
        margin-right: 0.5rem;
      }
      .current-path {
        @include flex($direction: row, $justify: center, $align: center);
        height: 1.5rem;
        padding-right: 0.5rem;
        cursor: default;
      }
    }
  }
  .empty-folder {
    @include flex;
    width: 100%;

    img,
    video {
      margin: 2rem 0 1rem;
    }

    video {
      height: 15rem;
    }

    img {
      height: 10rem;
    }

    h1 {
      font-weight: $vg-font-weight-light;
      margin-bottom: 0.5rem;
    }

    p {
      max-width: 20rem;
      font-size: $vg-font-size-tiny;
      color: $vg-gray-3;
      text-align: center;
      span {
        color: $vg-purple-light;
        font-weight: $vg-font-weight-medium;
      }
    }
  }
}

.create-folder-modal-content {
  @include full-content-size();
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  margin-top: 1.5rem;
  .vg-input .input-wrapper {
    margin-bottom: 0.5rem;
    input,
    input::placeholder {
      font-size: 0.9rem;
    }
  }
}

.file-rename-modal-content {
  width: 100%;
  margin-top: 1rem;
  .vg-input .input-wrapper {
    margin-bottom: 0.5rem;
    input {
      font-size: 0.9rem;
    }
  }
}

.breadcrumb-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}
