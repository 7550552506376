@import "Assets/styles/Global/_variables.scss";

.objects-modal {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  padding: 2rem 3rem;

  .objects-modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .table-body {
      overflow-y: auto;
      white-space: nowrap;
      max-height: 50vh;
    }

    .checkbox {
      width: 0.85rem;
      height: 0.85rem;
      accent-color: $vg-purple-main;
    }

    .table-row {
      height: 2.4rem;
    }

    .vg-search-input-container {
      margin-top: 1rem;
      width: 50%;
    }

    .buttons-container {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;

      &.single-button {
        justify-content: flex-end;
        margin-top: 0;
      }
    }

    .empty-content-container {
      width: 80%;

      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .result-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h1 {
      font-size: $vg-font-size-large;
      color: $vg-aqua-dark;
    }

    p {
      font-size: $vg-font-size-small;
      color: $vg-gray-1;
      text-align: center;
    }

    .vg-icon {
      margin-top: 1rem;
      width: 5rem;
      height: 5rem;
    }

    .vg-text-button {
      margin-top: 2rem;
      margin-left: auto;
    }
  }
}
