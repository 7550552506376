@import "Assets/styles/Global/_variables.scss";

.share-stream-modal {
  h1 {
    font-size: 1.1rem;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin: 0.5rem 0 1.5rem;
    color: $vg-black;
  }
  h3 {
    color: $vg-gray-2;
    font-weight: $vg-font-weight-semi-bold;
  }
  > p {
    color: $vg-gray-1;
  }
}
