@import "Assets/styles/Global/_variables.scss";

.usage-limit-form {
  .hour-line {
    @include flex($direction: row, $justify: space-between, $align: center);

    .default-hour {
      .text-info {
        position: relative;
        position: absolute;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        white-space: break-spaces;
        top: -20px;
        margin-left: -1.3rem;
        p {
          color: $vg-purple-main;
          font-size: 0.6rem;
          margin: 0rem;
          padding: 3px 12px;
        }
      }
    }

    .hour {
      white-space: pre-wrap;
    }
  }

  .select-hour {
    @include flex($direction: row, $justify: center, $align: center);
    flex-wrap: wrap;

    &:hover,
    &.selected {
      button {
        opacity: 0.5;
      }
    }

    .vg-button,
    p {
      font-weight: 500;
      font-size: $vg-font-size-large;
      color: $vg-gray-2;
    }

    span {
      font-weight: 300;
      font-size: $vg-font-size-small;
      color: $vg-gray-2;
    }

    .vg-button {
      @include for-mobile {
        width: 10rem;
      }
      width: 6rem;
      height: 3.5rem;
      padding: 0;
      margin: 0.5rem;

      border-color: $vg-gray-6;
      border-radius: 5px;
      &.selected,
      &.selected p,
      &.selected span {
        background-color: $vg-purple-main;
        color: $white;
        opacity: 1;
      }
      &:not(.selected):hover {
        box-shadow: $light-shadow;
        opacity: 1;
      }
    }
    .custom-hour-button {
      width: 6rem;
    }

    @include for-mobile {
      min-width: auto;

      margin: 0 0 2rem;
      .vg-button {
        margin: 0.3rem;
        font-size: 1rem;
      }
      .custom-hour-button {
        width: 7rem;
      }
    }
  }

  .custom-hour {
    @include flex($direction: row, $align: flex-end, $justify: center);
    font-size: $vg-font-size-large;
    font-weight: $vg-font-weight-light;
    color: $vg-gray-2;
    margin-top: 2rem;

    &.selected .vg-input {
      border-color: $vg-purple-main;
    }

    p {
      display: inline-block;
      font-size: 1rem;
      padding-left: 0.5em;
    }

    .vg-input {
      width: 5rem;
      max-width: 5rem;
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      border-color: $vg-gray-6;
      text-align: center;

      .input-wrapper {
        margin: 0;
        .vg-input {
          font-size: $vg-font-size-large;
        }
      }
    }
  }
}
