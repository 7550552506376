@import "Assets/styles/Global/_variables.scss";

.app-streaming-payment-error-modal {
  box-sizing: border-box;

  .modal-content {
    align-items: center !important;
  }

  h1 {
    font-size: $vg-font-size-large;
    font-weight: $vg-font-weight-semi-bold;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .stream-error-message-container {
    margin-top: 1rem;
    white-space: pre-wrap;
  }

  .error-message-container {
    @include flex($direction: column, $align: center, $justify: space-around);
    text-align: center;
    img {
      height: 10rem;
    }
    p {
      margin: 1rem 2rem;
    }
    .vg-button {
      margin-top: 1rem;
    }
  }
}
