@import "Assets/styles/Global/_variables";

.network-usage-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 12rem;
  width: calc(100% - 400px);
  background-color: $vg-white;
  border-radius: 3px;
  height: fit-content;

  @include for-mobile {
    width: auto;
  }

  .circular-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.6rem;
    width: 7rem;
    min-width: 7rem;
    margin: 1.5rem 2rem 1.5rem 4rem;
    @include for-mobile {
      margin: 1.5rem 0rem 1.5rem 1rem;
    }

    .circular-progress-bar-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $vg-gray-1;

      p {
        color: $vg-gray-3;
        font-size: 0.7rem;
      }
    }

    .total-usage {
      color: $vg-gray-3;
      font-size: 0.75rem;
    }

    .circular-progress-bar-alt-info {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: center;
      gap: 0.2rem;
      width: max-content;
    }
  }

  .vertical-divider {
    width: 1px;
    margin: 1.5rem 2rem 1.5rem 2rem;
    display: block;
    background-color: $vg-gray-5;
    @include for-mobile {
      margin: 1.5rem 1.5rem 1.5rem 1.5rem;
    }
  }

  .network-usage-details {
    margin: auto 2rem;
    margin-left: 0;
    @include for-mobile {
      margin: 0 1rem 0 0;
    }

    .network-usage-description {
      margin-top: 1rem;
    }

    .network-usage-description-header {
      display: flex;
      gap: 0.2rem;
      align-items: center;
      margin-bottom: 0.25rem;
    }

    h1 {
      color: $vg-gray-1;
      font-weight: $vg-font-weight-semi-bold;
      font-size: 0.9rem;
    }

    h2 {
      color: $vg-gray-2;
      font-weight: $vg-font-weight-semi-bold;
      font-size: 0.8rem;
    }

    p {
      color: $vg-gray-3;
      font-size: 0.7rem;
    }

    .progress-bar-info {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 1rem;

      .progress-bar-info-rectangle {
        border: solid 5px $vg-aqua-light;
        background-color: $vg-aqua-light;
        border-radius: 4px;
        width: 8px;
        height: 8px;
      }

      p {
        color: $vg-gray-3;
        margin-left: 0.5rem;
      }
    }
  }
}
