@import "Assets/styles/Global/_variables.scss";

.vg-button {
  @include flex($direction: row, $justify: space-around);

  background-color: $vg-purple-main;
  border: 0;
  border-radius: $vg-border-radius-small;
  color: $vg-white;
  font-size: 0.8rem;
  font-weight: $vg-font-weight-medium;
  line-height: 1rem;
  min-height: 2rem;
  outline: 0;
  padding: 0 0.8rem;
  width: 10rem;
  gap: 0.5rem;

  /* Pseudo selectors */
  &.clickable {
    cursor: pointer;
    &:hover:not(:disabled) {
      box-shadow: $hover-shadow;
    }
  }

  &.blue {
    background-color: $vg-purple-light;
  }

  &.aqua {
    background-color: $vg-aqua-main;

    &.clickable:hover:not(:disabled) {
      box-shadow: none;
      background-color: $vg-aqua-dark;
    }
  }

  &.aqua-dark {
    background-color: $vg-aqua-dark;
  }

  &.purple-light {
    background-color: $vg-purple-light;
  }

  &.dark-navy {
    background-color: $vg-dark-navy;
  }
  &.light-navy {
    background-color: $vg-light-navy;
  }

  &.outline {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid $vg-purple-main;
    color: $vg-purple-main;

    &:disabled {
      border: none;
    }
  }

  &.aqua-dark-outline {
    box-sizing: border-box;
    background-color: white;

    border: 1px solid $vg-aqua-dark;
    color: $vg-aqua-dark;

    &:disabled {
      border: none;
    }
  }

  &.gray-outline {
    box-sizing: border-box;
    background-color: white;

    border: 1px solid $vg-gray-3;
    color: $vg-gray-3;

    &:disabled {
      border: none;
    }
  }

  &.gray-4-outline {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid $vg-gray-4;
    color: $vg-gray-4;

    &:hover:not(:disabled) {
      box-shadow: none;
      color: $vg-gray-3;
      border: 1px solid $vg-gray-3;
    }

    &:hover:not(.clickable) {
      background-color: white;
      color: $vg-gray-4;
      border: 1px solid $vg-gray-4;
    }

    &:disabled {
      background-color: white;
      color: $vg-gray-4;
      border: 1px solid $vg-gray-4;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &:disabled {
    background-color: $vg-gray-4;
    color: $vg-gray-3;
    cursor: default;

    .vg-icon * {
      fill: $vg-gray-3;
    }

    .vg-icon.play {
      * {
        stroke: $vg-gray-3;
      }
    }

    .vg-icon.plus {
      * {
        stroke: $vg-gray-3;
      }
    }
  }

  .vg-icon {
    &.play {
      * {
        stroke: $vg-white;
      }
    }
  }

  /* Size properties */
  &.big {
    width: $big-button-width;
  }
  &.medium {
    width: $medium-button-width;
  }

  &.block {
    @include full-content-width;
  }

  &.text-contained {
    width: fit-content;
  }

  /* Theme properties */
  &.basic {
    border: $border-purple-main;
    color: $vg-purple-main;
  }

  &.basicBabyPowder {
    background-color: $vg-white;
    border: $border-baby-powder;
    color: $vg-baby-powder;
  }

  &.white {
    background-color: $vg-white;
    color: $vg-purple-main;

    &.basic {
      border: $border-white;
      color: $vg-white;
    }

    .vg-icon {
      &:not(.play:disabled) * {
        fill: $vg-purple-main;
      }

      &.play {
        * {
          stroke: $vg-purple-main;
        }
      }
    }
  }

  &.gray {
    background-color: $vg-gray-4;
    color: $vg-gray-3;

    &.basic {
      border: $border-gray-4;
      color: $vg-gray-3;
    }

    .vg-icon {
      &:not(.play:disabled) * {
        fill: $vg-gray-3;
      }

      &.play {
        * {
          stroke: $vg-gray-3;
        }
      }
    }
  }

  &.basic {
    background-color: transparent;
    border: $border-purple-main;
    color: $vg-purple-main;
  }

  /* Icon inside button */
  .vg-icon {
    width: 0.75rem;

    &.play {
      width: 0.7rem;
    }
  }

  /* Icon Animation */
  &.breath {
    animation: purple-breath 4s infinite;
    color: $vg-white;
    .vg-icon,
    .vg-icon:disabled {
      * {
        fill: $vg-white;
      }
    }
  }
}

@keyframes purple-breath {
  0% {
    background-color: $vg-purple-open;
  }
  50% {
    background-color: $vg-purple-main;
  }
  100% {
    background-color: $vg-purple-open;
  }
}

.vg-button-error {
  @include flex($direction: row, $justify: flex-start, $align: center);
  color: $vg-purple-main;
  font-size: 0.6rem;
  line-height: 0.5rem;
  margin-left: -0.1rem;

  .vg-icon {
    height: 1.5rem;
    padding-right: 0.2rem;
    width: 1.5rem;
  }
}
