@import "Assets/styles/Global/_variables.scss";

.date-picker-custom-input-container {
  @include flex($direction: row, $justify: flex-end);
}
.react-datepicker-wrapper {
  display: block;
  width: 16.4rem;
  margin-left: auto;
}
.date-picker-custom-input {
  width: 14rem;
  height: 2rem;
  border: $border-gray-5;
  border-radius: 2px;
  line-height: 2rem;
  color: $vg-gray-4;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  background-color: $vg-white;
}

.date-picker-custom-icon-button {
  background-color: $vg-baby-powder;
  border-radius: 2px;
  height: 2rem;
  width: 2rem;
  border: $border-baby-powder;
  margin-left: -4px;
  @include flex($direction: row, $align: center, $justify: center);
}
.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
  z-index: 2;
}

.react-datepicker__header {
  border-bottom: none;
  background-color: $vg-white;
  .react-datepicker__current-month {
    color: $vg-gray-2;
    margin: 0.6rem 0;
  }
}
.react-datepicker {
  font-family: "vagonRails";
  border: none;
}

.react-datepicker__day-name {
  text-transform: uppercase;
  color: $vg-gray-4;
}

.react-datepicker__navigation {
  top: 1rem;
}
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 6px;
  width: 6px;
}

.react-datepicker__month {
  margin-top: 0;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected):not(.react-datepicker__day--range-end) {
  background-color: $vg-gray-6;
}
.react-datepicker__day--selected,
.react-datepicker__day--range-end {
  background-color: white;
  color: $vg-purple-main;
  border-bottom: 2px solid $vg-purple-main;
}
.react-datepicker__day {
  margin: 0.2rem;
  border-radius: 0 !important;
  color: $vg-gray-3;
  font-size: $vg-font-size-tiny;
  &:hover {
    background-color: $vg-gray-6;
  }
}
.react-datepicker__day--keyboard-selected {
  background-color: white;
  border-radius: 0 !important;
  &:not(.react-datepicker__day--range-end) {
    border: none;
  }
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: $vg-gray-6;
}

.react-datepicker__month-container {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.react-datepicker__day-name {
  font-size: 0.7rem;
}

.react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  color: $vg-gray-4;
}
