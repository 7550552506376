@import "Assets/styles/Global/_variables";

.create-computers-modal {
  .modal-container {
    width: 32rem;
  }

  .create-computers-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    margin-top: 3rem;

    p {
      font-size: $vg-font-size-small;
      color: $vg-gray-1;
      text-align: center;
      margin-bottom: 1rem;
    }

    .vg-member-number-input {
      @include flex($direction: row, $justify: flex-start, $align: center);
      justify-content: center;
      height: 2rem;
      padding: 0rem 1rem 1rem;

      button {
        font-size: 1.5rem;
        background-color: transparent;
        border: $border-gray-6;
        border-radius: 3px;
        color: $vg-purple-main;
        line-height: 0.5rem;
        outline: none;
        padding: 10px 8px;
        cursor: pointer;

        &:hover {
          background-color: $vg-gray-5;
        }
      }

      .input-line {
        font-size: 1.5rem;
        line-height: 1rem;
        color: $vg-black;
        margin: 0 1rem;

        padding: 0;
        height: 1.5rem;
        width: 2rem;
        text-align: center;
      }

      input {
        min-height: 0rem;
        text-align: center;
        font-size: 1.2rem;
        border-bottom: none;
      }
    }

    .modal-action-wrapper {
      align-self: center;
      margin-top: -2rem;
      height: initial;
    }

    .increase-computer-number-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .action-buttons {
      }
    }
  }
}
