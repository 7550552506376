@import "Assets/styles/Global/_variables.scss";

.organization-settings-container {
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  height: 100%;

  .header {
    color: $vg-black;
    font-size: 1rem;
    font-weight: $vg-font-weight-medium;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }

  .description {
    color: $vg-gray-3;
    font-size: 0.7rem;
    font-weight: $vg-font-weight-regular;
    line-height: 1.1rem;
    margin-bottom: 1rem;
  }

  .label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    font-weight: $vg-font-weight-medium;
    line-height: 1.1rem;
    padding: 0.3rem 0;
  }

  .information-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $vg-black;
    font-size: 1rem;
    font-weight: $vg-font-weight-light;
    line-height: 1.5rem;
    margin-left: 2rem;
    @include for-mobile {
      line-height: 1rem;
    }
    .name-info {
      font-size: 0.9rem;
    }
  }

  .divider-container {
    margin: 2rem 0;
    margin-top: auto;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-bottom: 2rem;

    @include for-mobile {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: unset;
    }

    .column {
      @include flex($direction: column, $justify: flex-start, $align: flex-start);
      gap: 0.6rem;
      flex: 1;

      @include for-mobile {
        gap: unset;
        .footer-header {
          font-size: 0.8rem !important;
          margin-bottom: 0.5rem;
        }
        .footer-description {
          font-size: 0.7rem;
          margin-bottom: 1rem;
        }
      }

      p:nth-of-type(1) {
        color: $vg-black;
        font-size: 0.9rem;
        font-weight: $vg-font-weight-medium;
      }

      p {
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.75rem;
        font-weight: $vg-font-weight-medium;
        white-space: break-spaces;
      }
    }

    .subscription-button {
      display: flex;
      gap: 0.2rem;

      .vg-icon {
        height: 1.1rem;
        width: 1.1rem;
      }
    }
  }

  .profile-information-container {
    @include for-mobile {
      flex-direction: column;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 7rem;

    .profile-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-radius: 50%;
    }
    .information-item {
      justify-content: unset;
      .name-info {
        margin-bottom: 0.5rem;
      }
    }

    p {
      @include for-mobile {
        font-size: $vg-font-size-tiny;
      }
    }

    .additional-information-container {
      width: 100%;
      .time-zone-value-container {
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
        .info-value {
          font-size: 0.9rem;
          font-weight: 300;
        }
      }
    }
  }

  .sub-header {
    color: $vg-black;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    line-height: 1.25rem;
    margin-bottom: 0.3rem;
  }

  .teams-api-section {
    margin-top: auto;
    margin-bottom: auto;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .new-badge {
      background-color: $vg-aqua-light;
      padding: 2px 8px;
      line-height: 1rem;
      border-radius: 14px;
      font-size: $vg-font-size-tiny;
      font-weight: 400;
      margin-left: 0.6rem;
      color: $white;
    }

    .show-link-line {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.3rem;
      margin-bottom: 1rem;
    }

    span {
      font-size: 0.8rem;
      font-weight: $vg-font-weight-light;
    }

    .text-button {
      font-size: 0.8rem;
      font-weight: $vg-font-weight-medium;
    }

    .documentation {
      margin-top: 1rem;
      color: rgba(0, 0, 0, 0.6);
      a {
        font-size: 0.8rem;
        font-weight: $vg-font-weight-medium;
      }
    }
  }
}
