@import "Assets/styles/Global/_variables";

$button-size: 3.3rem;
.dock-button-container {
  @include flex;
  position: absolute;
  left: -3.3rem;
  z-index: 99;
  padding: 0;
  top: 0;
  opacity: 0.2;
  transition: opacity 1s 1s, left 0.5s 0.5s, transform 1s;

  &.shortcuts:not(.open) {
    .shortcut {
      opacity: 1;
      z-index: 1;
      &.top {
        left: -1.5rem;
        top: -2.6rem;
      }
      &.mid {
        left: -3.5rem;
      }
      &.bottom {
        left: -1.5rem;
        top: 3.5rem;
      }
    }
    .blur {
      width: 4rem;
      height: 10rem;
      left: -3rem;
      border-radius: 100px 0 0 100px;
    }
  }

  &.shortcuts,
  &.open {
    opacity: 1;
    transition: opacity 1s 0.1s, left 0.5s 0.5s;
  }

  &.open {
    top: 50%;
    left: -1.7rem;
    transition: left 0.5s 0s;
  }

  .shortcut {
    @include flex;
    opacity: 0;
    border-radius: 100px;
    width: 2.2rem;
    height: 2.2rem;
    background: linear-gradient(155.38deg, #5e73ff -17.14%, #4f109b 122.26%);
    position: absolute;
    top: 0.6rem;
    left: 0.5rem;
    transition: 0.3s cubic-bezier(0.23, 0.79, 0.8, 0.3), opacity 0.6s cubic-bezier(0.47, -0.02, 0, 1.54);
    z-index: -1;
    cursor: pointer;
    .vg-icon {
      height: 1.5rem;
    }
  }

  .invisible {
    opacity: 0;
    transform: rotate(-360deg);
  }

  .vg-icon {
    position: absolute;
    height: 2rem;
    width: auto;
    * {
      fill: $white;
    }
  }

  .blur {
    border-radius: 100px 0 0 100px;
    position: absolute;
    left: -3rem;
    width: 0;
  }

  svg {
    width: 0;
  }

  &::selection {
    cursor: move;
  }
}

.dock-button {
  @include flex;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0;
  width: $button-size;
  height: $button-size;
  border-radius: 100px;
  border: 0;
  font-size: 1.25rem;
  outline: none;
  background: linear-gradient(155.38deg, #5e73ff -17.14%, #4f109b 122.26%);

  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
