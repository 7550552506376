@import "Assets/styles/Global/_variables.scss";

.basics-modal {
  width: 100%;
  height: 28rem;

  .modal-content {
    > h1 {
      font-size: $vg-font-size-medium;
      font-weight: $vg-font-weight-semi-bold;
      line-height: 1.25rem;
      margin-bottom: 0.5rem;
      color: $vg-black;
    }
    h3 {
      color: $vg-gray-2;
      font-weight: $vg-font-weight-semi-bold;
    }
    > p {
      font-size: $vg-font-size-tiny;
      color: $vg-gray-3;
    }
    .modal-action-wrapper {
      width: 100% !important;
    }
    .description {
      width: 100%;
    }
  }

  .modal-footer {
    @include flex($direction: row, $align: center, $justify: space-between);
    @include full-content-width;
    position: absolute;
    bottom: 0;
    &.single-button {
      justify-content: flex-end;
    }

    &.multiple-button {
      justify-content: space-between;
    }
  }

  .modal-action-wrapper {
    align-items: flex-end;
  }

  .capacity-type-footer {
    margin-top: 1rem;
  }
}

.region-step {
  width: 100%;
  height: 13rem;
  .world-map {
    height: 16rem;
    margin-top: 1rem;
  }
}

.region-pricing-info {
  position: absolute;
  bottom: 2rem;
  width: 90%;
  margin: 0 auto;
  color: $vg-gray-3;
  font-size: 0.7rem;
  border: $border-gray-6;
  border-radius: 6px;
  padding: 0.8rem 2rem;
  @include for-mobile {
    padding: 0.5rem 0;
    width: auto;
  }
  opacity: 0;
  transition: opacity 1s;
  background-color: $vg-white;
  &.visible {
    opacity: 1;
  }
  @include flex($direction: row, $align: center, $justify: space-between);
  > div {
    text-align: center;
    width: 40%;
    line-height: 1.4;
    white-space: pre-wrap;
    span {
      span {
        font-weight: $vg-font-weight-bold;
      }
      p {
        line-height: 1rem;
      }
    }
  }
}

.text-button-container {
  justify-content: flex-end !important;
  align-items: center !important;
}
.advanced-configuration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $vg-aqua-main;
  font-size: 0.7rem;
  gap: 0.3rem;
  cursor: pointer;

  .vg-icon {
    height: 0.5rem;
    width: 0.5rem;
  }
}

.animation-container {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.5s;
  overflow: hidden;
  width: 100%;
  &.open {
    height: auto;
    grid-template-rows: 1fr;
    margin-bottom: 2rem;
  }

  .animation-content {
    display: flex;
    flex-direction: column;
    min-height: 0;
    .capacity-table {
      &.basics {
        margin-bottom: 2rem;
      }
    }
  }
}

.render-streaming-option-container {
  p {
    white-space: break-spaces;
  }
}
