@import "Assets/styles/Global/_variables.scss";

.usage-bar-wrapper {
  width: 100%;
  position: relative;
  height: 15px;
  padding: 1rem 0;
  margin-top: 1rem;

  @include for-mobile {
    width: 90%;
    padding: 0 1rem;
    display: inline-block;
  }

  .usage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    p {
      font-size: 0.8rem;
      font-weight: $vg-font-weight-regular;
      color: $vg-gray-3;
    }

    span {
      font-size: 0.8rem;
      font-weight: $vg-font-weight-regular;
      color: $vg-gray-2;
    }
  }

  .usage-bar {
    background-color: $vg-gray-5;
    width: 100%;
    height: 1.3rem;
    border-radius: 5px;
    display: flex;

    .tooltip-children {
      width: 100%;
    }

    .first-usage {
      background-color: $vg-aqua-main;
      transition: width 1s;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &:hover {
        opacity: 0.9;
      }
    }

    .second-usage {
      background-color: $vg-baby-powder;
      transition: width 1s;

      &:hover {
        opacity: 0.9;
      }
    }

    .right-border {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .left-border {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}
