@import "Assets/styles/Global/_variables.scss";

.teams-permissions-container {
  @include flex($direction: column, $justify: flex-start, $align: center);
  height: 100%;
  border-radius: 7px;
  position: relative;

  .v-tooltip {
    line-height: 1.2rem;
  }

  .top-bar {
    @include flex($direction: row, $justify: space-between, $align: center);
    @include full-content-width();
    margin-bottom: 2rem;
    @include for-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 6rem;
    }

    .left-actions {
      width: 25%;
      @include flex($direction: row, $justify: flex-start, $align: center);
      @include for-mobile {
        width: 100%;
      }

      .vg-search-input-container {
        width: 100%;
      }
    }

    .right-actions {
      width: 30%;
      @include flex($direction: row, $justify: flex-end, $align: center);
      gap: 1.2rem;
      @include for-mobile {
        width: 100%;
      }

      .vg-button {
        width: 60%;
      }

      .info-button-container {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        margin-left: auto;

        p {
          font-size: 0.8rem;
          color: $vg-gray-4;
        }

        &:hover,
        &.highlighted {
          p {
            color: $vg-aqua-main;
            transition: color 0.1s ease-in-out;
          }

          .vg-icon {
            * {
              fill: $vg-aqua-main;
              transition: fill 0.1s ease-in-out;
            }
          }
        }

        .vg-icon {
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }

  .permissions-table {
    padding: 1rem;
    background-color: $vg-white;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 16rem);
    @include for-mobile {
      overflow-x: auto;
      padding: unset;
    }

    .button-header-cell {
      @include flex($direction: row, $justify: flex-end, $align: center);
      .vg-icon {
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .header-line {
      @include for-mobile {
        width: 45rem;
      }
    }

    .table-body {
      overflow-y: auto;
      border-radius: 5px;
      position: relative;
      @include for-mobile {
        width: 45rem;
      }

      &::-webkit-scrollbar {
        width: 0.3rem;
      }

      .table-row {
        height: 2.4rem;
      }
    }

    .checkbox-cell {
      @include flex($direction: row, $justify: center, $align: center);
      width: 50%;

      &.low-opacity {
        opacity: 0.3;
      }

      .vg-checkbox input[type="checkbox"] {
        accent-color: $vg-blue-baby;
      }

      .vg-checkbox input[type="checkbox"] {
        &:hover {
          // todo
        }
      }
    }

    .button-cell {
      @include flex($direction: row, $justify: flex-end);
      width: 100%;
      margin-left: 0.8rem;
      padding-top: 0.2rem;
      gap: 0.8rem;
      .vg-icon {
        display: inline-block;
        height: 1.15rem;
        width: 1.15rem;
        min-width: 1.15rem;
      }
    }
  }
}
