@import "Assets/styles/Global/_variables";

.vg-status-badge {
  border-radius: 15px;
  display: flex;
  align-items: center;
  height: 1.4rem;
  padding: 1px 12px;
  width: max-content;
  white-space: nowrap;

  background-color: $vg-white;

  &.gray {
    background-color: $vg-gray-7;
  }

  .status-circle {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 100px;
    display: inline-block;

    &.ready {
      background-color: $vg-aqua-light;
    }

    &.ready_to_assign {
      background-color: $vg-aqua-light;
    }

    &.running {
      background-color: $vg-aqua-light;
    }

    &.computer_assigned {
      background-color: $vg-light-orange;
    }

    &.idle {
      background-color: $vg-baby-powder;
    }

    &.off {
      background-color: $vg-gray-4;
    }

    &.computer-required {
      background-color: $vg-silk-red;
    }

    &.turning_off {
      background-color: $vg-silk-red;
    }

    &.personal-computer {
      background-color: $vg-gray-5;
    }

    &.computer-assigned {
      background-color: $vg-light-orange;
    }

    &.resizing_disk {
      background-color: $vg-light-orange;
    }

    &.modifying_network {
      background-color: $vg-light-orange;
    }

    &.building {
      background-color: $vg-light-orange;
    }

    &.idle-computer {
      background-color: $vg-baby-powder;
    }

    &.turning_on {
      background-color: $vg-light-orange;
    }

    &.installing {
      background-color: $vg-light-orange;
    }

    &.warming_up {
      background-color: $vg-light-orange;
    }

    &.pending {
      background-color: $vg-light-orange;
    }

    &.pending-invitation {
      background-color: $vg-gray-5;
    }

    &.send-invitation {
      background-color: #a694f3;
    }

    &.payment-required {
      background-color: $vg-gray-5;
    }

    &.stopped {
      background-color: $vg-silk-red;
    }

    &.available {
      background-color: $vg-green-3;
    }
  }
}
