@import "Assets/styles/Global/_variables.scss";

.vg-icon {
  height: 2rem;
  outline: none;
  position: relative;
  width: 2rem;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &.large {
    height: 2.5rem;
    width: 2.5rem;
  }

  &.medium {
    height: 1.2rem;
    width: 1.2rem;
  }

  &.small {
    height: 1.5rem;
    width: 1.5rem;
  }

  &.smaller {
    height: 1rem;
    width: 1rem;
  }

  &.smallest {
    height: 0.8rem;
    width: 0.8rem;
  }

  &.tiny {
    height: 0.7rem;
    width: 0.7rem;
  }

  &.purple-main {
    * {
      fill: $vg-purple-main;
    }
  }

  &.purple-main-stroke {
    * {
      stroke: $vg-purple-main;
    }
  }

  &.black {
    * {
      fill: $vg-black;
    }
  }

  &.black-stroke {
    * {
      stroke: $vg-black;
    }
  }

  &.white {
    * {
      fill: $vg-white;
      stroke: $vg-white;
    }
  }

  &.white-stroke {
    * {
      stroke: $vg-white;
    }
  }

  &.yellow {
    * {
      fill: $vg-yellow;
    }
  }

  &.green-3 {
    * {
      fill: $vg-green-3;
    }
  }

  &.white-fill {
    * {
      fill: $vg-white;
    }
  }

  &.gray-5 {
    * {
      fill: $vg-gray-5;
    }
  }

  &.gray-5-stroke {
    * {
      stroke: $vg-gray-5;
    }
  }

  &.gray-4 {
    * {
      fill: $vg-gray-4;
    }
  }

  &.gray-4-stroke {
    * {
      stroke: $vg-gray-4;
    }
  }

  &.gray-4-fill {
    * {
      fill: $vg-gray-4;
    }
  }

  &.gray-3 {
    * {
      fill: $vg-gray-3;
    }
  }

  &.gray-3-stroke {
    * {
      stroke: $vg-gray-3;
    }
  }

  &.gray-2 {
    * {
      fill: $vg-gray-2;
    }
  }

  &.gray-2-stroke {
    * {
      stroke: $vg-gray-2;
    }
  }

  &.red {
    * {
      fill: $vg-silk-red;
    }
  }

  &.baby-blue {
    * {
      fill: $vg-blue-baby;
    }
  }

  &.baby-blue-stroke {
    * {
      stroke: $vg-blue-baby;
    }
  }

  &.baby-powder {
    * {
      fill: $vg-baby-powder;
    }
  }
  &.baby-powder-stroke {
    * {
      stroke: $vg-baby-powder;
    }
  }

  &.aqua-dark {
    * {
      fill: $vg-aqua-dark;
    }
  }

  &.aqua-light {
    * {
      fill: $vg-aqua-light;
    }
  }

  &.aqua-dark-stroke {
    * {
      stroke: $vg-aqua-dark;
    }
  }

  &.aqua-main {
    * {
      fill: $vg-aqua-main;
    }
  }

  &.aqua-main-stroke {
    * {
      stroke: $vg-aqua-main;
    }
  }

  &.clickable,
  &[role="button"]:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.loader {
    @keyframes loader-ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    animation: loader-ring 1.2s linear infinite;
  }
}
