@import "Assets/styles/Global/_variables.scss";

.screen-shot-modal {
  .screenshot-container {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 1rem 0;
  }

  .vg-icon {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
